import { Directive, OnDestroy, Output, EventEmitter, Input, HostListener, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
    selector: '[afterValueChanged]'
})
export class AfterValueChangedDirective implements OnInit,OnDestroy {
    @Output()
    public afterValueChanged: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    public valueChangedDelay = 300;

    private subject: Subject<any> = new Subject<any>();
    private subscription: Subscription;

    constructor() {
    }
    ngOnInit(): void {
        this.subscription = this.subject
            .pipe(debounceTime(this.valueChangedDelay))
            .subscribe((value: any) => this.afterValueChanged.next(value));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    @HostListener('input', ['$event'])
    public onValueChange(value: any): void {
        this.subject.next(value);
    }
}
