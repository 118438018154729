import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Mode } from 'src/app/core/models/modes/mode.model';
import { NetworkService } from 'src/app/core/services/network.service';
import { ApiModesService } from 'src/app/modules/modes/services/http/api-modes.service';
import { ModesService } from 'src/app/modules/modes/services/modes.service';
import { ApartmentService } from '../../services/apartment.service';
import { Network } from '@ionic-native/network/ngx';
import { map } from 'rxjs/operators';
import { DemoService } from 'src/app/core/services/demo.service';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { ApiDaliProjectService } from 'src/app/modules/project/services/http/api-dali-project.service';


@Component({
  selector: 'app-statuses',
  templateUrl: './statuses.component.html',
  styleUrls: ['./statuses.component.scss'],
  providers: [Network]
})
export class StatusesComponent implements OnInit, OnDestroy {

  modesSubscription: Subscription;
  apartmentIdSubscription: Subscription;
  modes: Mode[];
  apartmentId: string;

  heatingActive: boolean;
  coolingActive: boolean;
  apartmentArmed: boolean;

  usingNetworkSwitcher$: Observable<boolean>;
  networkType$: Observable<'public' | 'local' | 'demo' | 'switching'>;

  networkStatus: Observable<string>;

  constructor(
    private apiModesService: ApiModesService,
    private modesService: ModesService,
    private networkService: NetworkService,
    private apartmentService: ApartmentService,
    private network: Network,
    private demoService: DemoService,
    private apiProjectService: ApiProjectService,
    private apiDaliProjectService: ApiDaliProjectService
    ) {}

  ngOnInit(): void {
    this.networkStatus = this.network.onChange()?.pipe(map(() =>  this.network.type));
    this.getModes();
    this.apartmentIdSubscription = this.apartmentService.getApartmentId().subscribe((aptId: string) => {
      this.apartmentId = aptId;
      if (this.modes) {
        this.updateStatuses();
      }
    });
    this.usingNetworkSwitcher$ = this.networkService.getUsingNetworkSwitcher();
    this.networkType$ =  this.networkService.getNetworkType();


    this.network.onConnect().subscribe( () => {
      this.apiProjectService.getProject().subscribe();
      this.apiDaliProjectService.getProject().subscribe();
    })
  }

  getModes() {
    this.modes = this.modesService.getModes();
    this.apiModesService.getModes().subscribe();
    this.modesSubscription = this.modesService.modesChanged.subscribe(() => {
      this.modes = this.modesService.getModes();
      if (this.demoService.isDemo()) {
        this.demoService.setModesForDemo(this.modes);
        this.modes = this.demoService.getModesForDemo();
      }
      this.updateStatuses();
    });
  }

  updateStatuses() {
    let armDisarmMode: Mode;
    this.modes?.forEach((mode: Mode) => {
      if (Mode.isCooling(mode) && (mode.apartmentId === this.apartmentId)){
        this.coolingActive = mode.isActive;
      } else if (Mode.isHeating(mode) && (mode.apartmentId === this.apartmentId)){
        this.heatingActive = mode.isActive;
      } else if (Mode.isArm(mode) && (mode.apartmentId === this.apartmentId)){
        armDisarmMode = mode;
      }
    });
    this.apartmentArmed = (armDisarmMode ? armDisarmMode.isActive : false);
   }

  ngOnDestroy(): void {
    if (this.modesSubscription) {
      this.modesSubscription.unsubscribe();
    }
    if (this.apartmentIdSubscription) {
      this.apartmentIdSubscription.unsubscribe();
    }
  }

}
