import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Scene } from 'src/app/core/models/scenes/scene.model';
import { SceneGroup } from 'src/app/core/models/scenes/scene-group.model';

@Injectable({
  providedIn: 'root'
})
export class ScenesService {

  private scenes: Scene[];
  scenesChanged = new Subject<Scene[]>();

  private sceneGroups: SceneGroup[];
  sceneGroupsChanged = new Subject<SceneGroup[]>();
  duplicateScene: string;


  constructor() { }

  
  getScenes(){
    return this.scenes?.slice();
  }

  setScenes(scenes: Scene[]) {
    this.scenes = scenes.slice();
    this.scenesChanged.next(this.scenes);
  }

  updateScene(scene: Scene) {
    const index = this.scenes.findIndex( element => element._id === scene._id);
    this.scenes.splice(index, 1, scene);
    this.scenesChanged.next(this.scenes);
}

  getSceneGroups(){
    return this.sceneGroups?.slice();
  }

  setSceneGroups(sceneGroups: SceneGroup[]) {
    this.sceneGroups = sceneGroups.slice();
    this.sceneGroupsChanged.next(this.sceneGroups);
  }

  activateOrDeactivateScene(id: string) {
    const index = this.scenes.findIndex( element => element._id === id);
    if (!this.scenes[index].isActive) {
        this.scenes[index].isActive = true;
    } else {
        this.scenes[index].isActive = false;
    }
    this.scenesChanged.next(this.scenes);
  }

  getSceneById(id: string) {
    const scene = this.scenes.find( element => {
      if (element._id === id) {return true;}
    })
    return scene;
  }

  saveDuplicateSceneId(id: string) {
    this.duplicateScene = id;
  }

  getDuplicateSceneId() {
    return this.duplicateScene;
  }

  removeDuplicateId() {
    this.duplicateScene = undefined;
  }
}
