<div class="full-screen" *ngIf="(apartmentId$ | async ) as apartmentId">
 <div class="home-header">{{ 'welcome_home' | translate }}</div>
 <ng-container *ngIf="getAllScenes() | sceneAptFilter: apartmentId | publicScenesFilter: signedInUser | sortByPosition as scenes">
  <div class="margin-top20" *ngIf="scenes.length > 0; else noScenes">
    <h3 class="home-subheader"><span routerLink="/scenes">{{ 'scenes' | translate }}</span></h3>
    <div class="flex-row-center">
      <div *ngFor=" let scene of scenes;
          let i = index">
        <div *ngIf="i < 4" class="flex-column-center margin-right10">
          <div *ngIf="!loadingElement(scene._id) && !loadingElement(scene.id)" class="circle-with-icon"  [ngClass] = "showIsSceneActive(scene.isActive) ? 'i-scenes-orange' : 'i-scenes-sm-white'"
            (click)="onSceneClick(scene)" 
          ></div>
          <div *ngIf="loadingElement(scene._id) || loadingElement(scene.id)" class="lds-spinner-md margin-bottom4"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          <div class="name-text">{{ scene.name }}</div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #noScenes>
    <div class="margin-top20">
      <h3 class="home-subheader"><span routerLink="/scenes">{{ 'no_scenes' | translate }}</span></h3>
    </div>
  </ng-template>
</ng-container>

<div [ngClass]="{'active-devices-and-modes-container': isTabletLandscape()}">
  <div *ngIf="getActiveDevices(apartmentId) as activeDevices" class="margin-top20">
    <h3 class="home-subheader">
      <span>
        <span *ngIf="activeDevices.length > 0" routerLink="/active-devices">{{ 'active_devices' | translate }}</span>
        <span *ngIf="activeDevices.length === 0">{{ 'no_active_devices' | translate }}</span>
      </span>
    </h3>
    <div class="flex-row-center">
      <div *ngFor=" let device of activeDevices | slice:0:getEnd(activeDevices.length); let i = index"
        class="flex-column-center">
        <div *ngIf="isDeviceLoading(device); else deviceNotLoading" class="lds-spinner-md margin-bottom4"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        <ng-template #deviceNotLoading>
        <div class="circle-with-icon"
          [ngClass]="{'i-lightbulb-orange': deviceIsLight(device) || deviceIsDaliLight(device),
          'i-hvac-orange': deviceIsHvac(device),
          'i-light-rgbw-orange': deviceIsDaliRgbLight(device)}"
            (click)="onClickDevice(device)"
          ></div>
        </ng-template>
        <div class="name-text">{{ getDeviceName (device) }}</div>
        <div class="name-text font-size-small">{{ getRoomForDevice(device)?.name }}</div>

      </div>
      <div *ngIf="activeDevices.length > 5"
        class="flex-column-center width70">
        <div class="circle-with-icon flex-center"
              routerLink="/active-devices"
          >
          <div class="circle-text">+{{activeDevices.length - 4}}</div>
        </div>

      </div>

    </div>
  </div>

  <div class="margin-top20">
    <h3 class="home-subheader"  ><span routerLink="/modes">{{ 'modes' | translate }}</span></h3>
    <div class="flex-row-center">
      <div *ngIf="heatOrCoolingModeNotActive(apartmentId)" class="margin-right10">
        <div class="lds-spinner-md"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>
      <div *ngFor="let mode of (modes | modeAptFilter: apartmentId ) ;
       let i = index">
        <div
          *ngIf="heatingOrCooling(mode) && mode?.isActive"
          class="flex-column-center margin-right10"
        >
          <div *ngIf="!loadingHeatingOrCoolingElement(mode)"
            [ngClass]="[modeIsHeating(mode)? 'i-mode-heating':'i-mode-cooling']"
            class="circle-with-icon"
            (click)="onModeClick(mode)"
          ></div>
          <div *ngIf="loadingHeatingOrCoolingElement(mode)" class="lds-spinner-md margin-bottom4"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          <div class="name-text">{{ mode.name | genComTranslate }}</div>
        </div>

        <div
          *ngIf="!heatingOrCooling(mode)"
          class="flex-column-center margin-right10"
        >
          <div *ngIf="!loadingElement(mode._id)"
            [ngClass]="returnModeTypeAndStatus(mode)"
            class="circle-with-icon"
            (click)="onModeClick(mode)"
          ></div>
          <div *ngIf="loadingElement(mode._id)" class="lds-spinner-md margin-bottom4"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          <div class="name-text">{{ mode.name | genComTranslate }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
