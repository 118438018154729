import { Pipe, PipeTransform } from '@angular/core';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'reverseListIfBlinds'
})
export class ReverseListIfBlindsPipe implements PipeTransform {
  transform(properties: Property[]): Property[] {
    // BLINDS ORDER IS REVERSED ON DRIVER
    // SO IT IS REVERSED ON FRONTEND TO MATCH
    if (!properties) {
      return [];
    }
    const everyPropIsBlind = properties.every((prop: Property) => {
      return Property.isBlinds(prop);
    });
    if (!everyPropIsBlind) {
      return properties;
    }

    return properties.slice().reverse();
  }

}
