import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { GenComTranslatePipe } from '../pipes/gen-com-translate.pipe';

@Injectable({
  providedIn: 'root'
})
export class ApartmentService {
  private apartmentId = new ReplaySubject<string>(1);

  constructor(
    private router: Router,
    private alertController: AlertController,
    private pipe: GenComTranslatePipe
    ) {}

  selectApartment(id: string) {
    const url = this.router.routerState.snapshot.url;
    if (url.split('/')[1] === 'rooms' && url.split('/')[2]) {
      this.router.navigate(['/rooms']);
      this.setApartmentId(id);
    } else if (url.split('/')[1] === 'scenes' && url.split('/')[2]) {
      this.apartmentId.pipe(take(1)).subscribe((aptId) => {
        if (aptId !== id) {
          this.promptSetApartmentId(id);
        }
      });
    } else if (url.split('/')[1] === 'automation' && url.split('/')[2]) {
      this.apartmentId.pipe(take(1)).subscribe((aptId) => {
        if (aptId !== id) {
          this.promptSetApartmentId(id);
        }
      });
    }else {
      this.setApartmentId(id);
    }
  }

  setApartmentId(id: string) {
    this.apartmentId.next(id);
  }

  getApartmentId() {
    return this.apartmentId.pipe(distinctUntilChanged());
  }

  async promptSetApartmentId(id: string) {
    const alert = await this.alertController.create({
      header: this.pipe.transform('Discarding changes'),
      buttons: [ {
        text: this.pipe.transform('Cancel'),
        role: 'cancel',
      },
        {
          text: this.pipe.transform('Discard'),
          handler: () => {
            this.setApartmentId(id);
            this.router.navigate(['/home']);
          }
        }
      ],
    });
    await alert.present();
   }

}
