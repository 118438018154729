<div class="flex-row">
  <div class="content-form-group">
    <label class="width-100px">{{ 'From' | translate }}:</label>
    <div class="flex-row">
      <div class="date-time-container">
      <input
        id="inputFrom"
        type="text"
        [owlDateTimeTrigger]="dtFrom"
        [owlDateTime]="dtFrom"
        (dateTimeInput)="dateTimeInput()"
        [(ngModel)]="selectedDateTime"
        [selectMode]="'rangeFrom'"
        placeholder="{{'date_and_time' | translate}}">
      <span
        class="delete-btn delete-icon"
        *ngIf="selectedDateTime[0]!==undefined  && selectedDateTime[0]!==null && showDeleteBtn"
        (click)=onClearDateTime(0) >
      </span>
      </div>
      <i class="i-calendar-sm margin-top5"  [owlDateTimeTrigger]="dtFrom"></i>
    </div>

    <owl-date-time #dtFrom (afterPickerClosed)="onCloseFrom()"></owl-date-time>
  </div>

  <div class="content-form-group ">

    <label class="width-100px" >{{ 'To' | translate }}:</label>
    <div class="flex-row">
      <div class="date-time-container">
        <input
        id="inputTo"
        type="text"
        [owlDateTimeTrigger]="dtTo"
        [owlDateTime]="dtTo"
        (dateTimeInput)="dateTimeInput()"
        [(ngModel)]="selectedDateTime"
        [selectMode]="'rangeTo'"
        placeholder="{{'date_and_time' | translate}}">
      <span
        class="delete-btn delete-icon"
        *ngIf="selectedDateTime[1]!==undefined && selectedDateTime[1]!==null && showDeleteBtn"
        (click)=onClearDateTime(1) >
      </span>
      </div>
      <i class="i-calendar-sm margin-top5"   [owlDateTimeTrigger]="dtTo"></i>
    </div>

    <owl-date-time #dtTo (afterPickerClosed)="onCloseTo()" ></owl-date-time>
  </div>
</div>
