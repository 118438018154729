import { Pipe, PipeTransform } from '@angular/core';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { Property } from 'src/app/core/models/project/property.model';
import { Room } from 'src/app/core/models/project/room.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { EquipmentPropertyTypesService } from '../services/equipment-property-types.service';

@Pipe({
  name: 'roomActuatorDeviceFilter'
})
export class RoomActuatorDeviceFilterPipe implements PipeTransform {

  constructor(private projectService: ProjectService,
              private equipmentPropertyTypesService: EquipmentPropertyTypesService ) {}

  transform(rooms: Room[]): Room[] {
    if (!rooms) {
      return [];
      }
    return rooms.filter((room: Room) => {
      let actuatorAvailable = false;
      const devicesInRoom = this.getAllDevicesInRoom(room.id);
      devicesInRoom.forEach( (device: Device) => {
        device.equipmentProperties.forEach((prop: Property) => {
          if (this.equipmentPropertyTypesService.getEquipmentPropertyTypeForStatus(prop.type) && prop.isActuatorProperty) {
            actuatorAvailable = true;
          }
        })
      })
      return actuatorAvailable;
    });
  }


  getAllDevicesInRoom(id: string) {
    const allDevicesInRoom = [];
    allDevicesInRoom.push(...this.projectService.getLightsForRoom(id));
    allDevicesInRoom.push(...this.projectService.getHvacForRoom(id));
    allDevicesInRoom.push(...this.projectService.getBlindsForRoom(id));
    allDevicesInRoom.push(...this.projectService.getGeneralEquipmentForRoom(id));
    allDevicesInRoom.push(...this.projectService.getSafetyEquipmentForRoom(id));
    allDevicesInRoom.push(...this.projectService.getSecurityEquipmentForRoom(id));

    return allDevicesInRoom;
  }

}