<div class="center-slider">
  <ion-item lines="none">
    <ion-range
      #rangeValue
      min="{{ options.floor }}"
      max="{{ options.ceil }}"
      step="1"
      value="{{ settingValue$ | async }}"
      snaps
      [color]="getSliderColor()"
      (ionChange)="onInputChanged(rangeValue.value)"
    >
      <ion-icon
        slot="start"
        size="large"
        [style.color]="'#6ba0c4'"
        name="remove-circle"
        (click)="minusClicked()"
        class="clickable margin-right20 margin-left20"
      ></ion-icon>
      <ion-icon
        slot="end"
        size="large"
        [style.color]="'#6ba0c4'"
        name="add-circle"
        (click)="plusClicked()"
        class="clickable margin-right20 margin-left20"
      ></ion-icon>
    </ion-range>
  </ion-item>
  <div class="num-label" [ngClass]="heatingActive ? 'red-text': ''">
    {{ settingValue$ | async }} {{ unitOfMeasurement }}
  </div>
</div>
