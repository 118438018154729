import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Action } from 'src/app/core/models/automation/action.model';
import { Condition } from 'src/app/core/models/automation/condition.model';
import { Light } from 'src/app/core/models/project/devices/light.model';
import { DaliSceneElement } from 'src/app/core/models/scenes/dali-scene-element.model';
import { DemoService } from 'src/app/core/services/demo.service';
import { DaliProjectService } from 'src/app/modules/project/services/dali-project.service';
import { ApiDaliProjectService } from 'src/app/modules/project/services/http/api-dali-project.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { ToastService } from 'src/app/shared/services/toast.service';


@Component({
  selector: 'app-dimmable-dali-light',
  templateUrl: './dimmable-dali-light.component.html',
  styleUrls: ['./dimmable-dali-light.component.scss']
})
export class DimmableDaliLightComponent implements OnInit, OnDestroy {
  @Input() mode: 'live' | 'scene' | 'editScene' | 'action' | 'condition';
  @Input() daliLight: Light;
  @Input() daliSceneElement: DaliSceneElement;
  @Input() set daliCondition(condition: Condition) {
    if (condition) {
      this.edit = true;
      this.condition = condition
      this.deviceCondition$.emit(condition);
    }
  }

  @Input() set daliAction(action: Action) {
    if (action) {
      this.edit = true;
      this.action = action;
      this.deviceAction$.emit(this.action);
    }
  }

  @Output() deviceAction$: EventEmitter<Action> = new EventEmitter();
  @Output() deviceCondition$: EventEmitter<Condition> = new EventEmitter();
  action: Action = new Action();
  condition: Condition = new Condition();

  sliderValues = new BehaviorSubject(null);
  sliderSubscription: Subscription;
  inputDelay = 2000;
  lightIsDimmable = false;
  edit: boolean = false;

  @Output() newLightDimmValue: EventEmitter<number> = new EventEmitter();

  // whiteValue: number;

  loadingSubscription: Subscription;
  loadingElements = [];

  sliderOptions = {
    floor : 0,
    ceil : 100
  };

  constructor(
    private apiDaliProjectService: ApiDaliProjectService,
    private loadingService: LoadingService,
    private demoService: DemoService,
    private daliProjectService: DaliProjectService,
    private toastService: ToastService) { }

  ngOnInit(): void {
    if (Light.isDimmableDaliLight(this.daliLight)) {
      this.lightIsDimmable = true;
    }
   /*  this.loadingSub();
    // this.whiteValue = this.daliLight.equipmentProperties[0].value;
    this.sliderSubscription = this.sliderValues.pipe(
      debounceTime(this.inputDelay),
      distinctUntilChanged(),
    ).subscribe((newSliderValue) => {
      if (newSliderValue !== null ) {
        this.apiDaliProjectService.activateDaliLight(this.daliLight.id, newSliderValue).subscribe();
      }
  }); */
    if (this.mode === 'live') {
      this.loadingSub();
      this.sliderSubscription = this.sliderValues.pipe(
        debounceTime(this.inputDelay),
        distinctUntilChanged(),
      ).subscribe((newSliderValue) => {
        if (newSliderValue !== null ) {
          this.apiDaliProjectService.activateDaliLight(this.daliLight.id, newSliderValue).subscribe({});
        }
      });
  }
    if (this.mode === 'editScene') {
      this.daliLight = JSON.parse(JSON.stringify(this.daliLight));
      this.daliLight.equipmentProperties[0].value = this.daliSceneElement.value;
    }

    if (this.mode === 'action'  && !this.edit) {
      this.action.id = 'ca0014';
      this.action.name = 'Activate DALI light';
      this.action.description = '';
      this.action.command = 'DaliActions.ActivateLight';
      this.action.parameters = [`${this.daliLight.id}`,`${this.daliLight.equipmentProperties[0].value}`];
      this.action.builtIn = true;
      this.deviceAction$.emit(this.action);
    } else if (this.mode === 'condition' && !this.edit) {
      this.condition.automationConditionType = 'simple';
      this.condition.type = 4; // equal or greather then
      this.condition.parameter1 = `ValRef:${this.daliLight.id}`;
      this.condition.parameter2 = 0;
      this.condition.parameter3 = 0; // is ignored in on off (binary) component
      this.condition.editable = false;
      this.condition.parameter1Editable = false;
      this.condition.parameter2Editable = false;
      this.condition.parameter3Editable = false;
    }

    if(this.mode === 'action' && this.edit) {
      this.daliLight.equipmentProperties[0].value = this.action.parameters[1];
    }
}

  minusClicked() {
    if (this.demoService.isDemo()) {
      if (this.daliLight.equipmentProperties[0].value > 0) {
        this.daliProjectService.changePropertyValueForDemo(this.daliLight.id, (+this.daliLight.equipmentProperties[0].value - 1).toString());
      }
    } else {
      if (this.mode === 'live' || this.mode === 'editScene') {
        if (this.daliLight.equipmentProperties[0].value > 0) {
          this.daliLight.equipmentProperties[0].value--;
          this.sliderValues.next( this.daliLight.equipmentProperties[0].value);
        }
      } else if (this.mode === 'action') {
        this.daliLight.equipmentProperties[0].value--;
        this.action.parameters[1] = this.daliLight.equipmentProperties[0].value;
        this.deviceAction$.emit(this.action);
      }
    }
  }

  plusClicked() {
    if (this.demoService.isDemo()) {
      if (this.daliLight.equipmentProperties[0].value < 100) {
        this.daliProjectService.changePropertyValueForDemo(this.daliLight.id, (+this.daliLight.equipmentProperties[0].value + 1).toString());
      }
    } else {
      if (this.mode === 'live' || this.mode === 'editScene') {
        if (this.daliLight.equipmentProperties[0].value < 100) {
          this.daliLight.equipmentProperties[0].value++;
          this.sliderValues.next( this.daliLight.equipmentProperties[0].value);
        }
      } else if (this.mode === 'action') {
        this.daliLight.equipmentProperties[0].value++;
        this.action.parameters[1] = this.daliLight.equipmentProperties[0].value;
        this.deviceAction$.emit(this.action);
      }
    }
  }

  onInputChanged(rangeValue) {
    if (this.demoService.isDemo()) {
      this.daliLight.equipmentProperties[0].value = rangeValue;
    } else {
      if (this.mode === 'live' || this.mode === 'editScene') {
        if (Number(rangeValue) !== Number(this.daliLight.equipmentProperties[0].value)) {
          this.daliLight.equipmentProperties[0].value = rangeValue;
          this.sliderValues.next( this.daliLight.equipmentProperties[0].value);
        }
      } else if (this.mode === 'action') {
        this.daliLight.equipmentProperties[0].value = rangeValue;
        this.action.parameters[1] = this.daliLight.equipmentProperties[0].value;
        this.deviceAction$.emit(this.action);
      }
    }
  }

  getDaliLightValue(): number {
    return Number(this.daliLight.equipmentProperties[0].value);
  }

  toggleDaliLight() {
    if (this.demoService.isDemo()) {
      if (this.getDaliLightValue() === 0 ) {
        this.daliLight.equipmentProperties[0].value = '100';
      } else {
        this.daliLight.equipmentProperties[0].value = '0';
      }
    } else {
      if (this.mode === 'live') {
        if (this.getDaliLightValue() === 0 ) {
          this.apiDaliProjectService.activateDaliLight(this.daliLight.id, 100).subscribe();
        } else {
          this.apiDaliProjectService.activateDaliLight(this.daliLight.id, 0).subscribe();
        }
      }
    }
  }

  handleBinaryChange(event) {
    if (this.mode === 'action') {
      /* this.action.parameters[1] = event.target.value;
      this.deviceAction$.emit(this.action); */
      const newAction: Action = JSON.parse(JSON.stringify(this.action));
      this.action.parameters[1] = Number(event.target.value);
      newAction.parameters[1] = event.target.value;
      this.deviceAction$.emit(newAction);
    } else if (this.mode === 'condition') {
      /* this.condition.parameter2 = event.target.value;
      this.deviceCondition$.emit(this.condition); */
      const newCondition: Condition = JSON.parse(JSON.stringify(this.condition));
      this.condition.parameter2 = Number(event.target.value);
      newCondition.parameter2 = event.target.value;
      this.deviceCondition$.emit(newCondition);
    }
  }

  loadingSub() {
    this.loadingElements = this.loadingService.getLoadingElements();
    this.loadingSubscription = this.loadingService.loadingChanged.subscribe( response => {
      this.loadingElements = this.loadingService.getLoadingElements();
    });
  }

  loadingElement(id: string) {
    return this.loadingElements.includes(id);
  }

  onApply() {
    this.newLightDimmValue.emit(this.daliLight.equipmentProperties[0].value);
  }


  ngOnDestroy() {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
    setTimeout(() => {
      if (this.sliderSubscription) {
        this.sliderSubscription.unsubscribe();
      }
    }, this.inputDelay);


  }

}
