import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EquipmentPropertyTypesService } from './shared/services/equipment-property-types.service';
// import { Plugins } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { TranslateService } from '@ngx-translate/core';
import { UserSettingsService } from './core/services/user-settings.service';
import { SleepService } from './shared/services/sleep.service';
import { Platform } from '@ionic/angular';
import { IonContent } from '@ionic/angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FastSceneAndAutoCreate } from './shared/services/fastSceneAndAutoCreate.service';


// const { SplashScreen } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  sleepMode$ = this.sleepService.getSleepMode();
  title = 'ABAS';
  showHeader = false;
  showFooter = false;
  showSidebar = false;
  @ViewChild(IonContent) content: IonContent;
  scrollSubscription: Subscription;
  scrollActive = false;
  deviceSubscription: Subscription;
  deviceSelectActive = false;


  constructor(
    private equipmentPropertyTypesService: EquipmentPropertyTypesService,
    private router: Router,
    private translate: TranslateService,
    private userSettingsService: UserSettingsService,
    private sleepService: SleepService,
    private platform: Platform,
    private fastSceneAndAutoCreate: FastSceneAndAutoCreate
    ) {
      this.translate.setDefaultLang(this.userSettingsService.getLanguage());
      SplashScreen.hide();
      this.equipmentPropertyTypesService.loadEquipmentPropertyTypes();
      router.events.subscribe(
        (event) => {
          if (event instanceof NavigationStart) {
          }
          if (event instanceof NavigationEnd) {

            if (event.urlAfterRedirects.includes('login') || event.urlAfterRedirects.includes('landing')) {
              this.showHeader = false;
              this.showFooter = false;
              this.showSidebar = false;
            } else if (event.urlAfterRedirects === '/home') {
              this.showHeader = true;
              this.showFooter = true;
              this.showSidebar = true;
            } else {
              this.showHeader = true;
              this.showFooter = true;
              this.showSidebar = true;
            }
          }
        }
      );
  }


  ngOnInit() {
    this.scrollSubscription = this.userSettingsService.scrollwatch$.subscribe( () => {
      this.scrollActive = true;
    });

    this.deviceSubscription = this.fastSceneAndAutoCreate.scrollwatch$.subscribe( () => {
      this.deviceSelectActive = true;
    })

    if (this.platform.is('tablet')) {
        this.sleepService.activateSleepTimer();
      }
  }

  onClickInApp() {
      if (this.platform.is('tablet')) {
        this.sleepService.activateSleepTimer();
      }
  }

  ngAfterViewChecked() {
    if (this.scrollActive) {
      this.content.scrollToPoint(0, +sessionStorage.getItem('scrollTop'), 0);
      this.scrollActive = false;
    }
    if (this.deviceSelectActive) {
      this.content.scrollToPoint(0, +sessionStorage.getItem('sceneAndAutoScroll'), 0);
      this.deviceSelectActive = false;
    }
  }

  onScroll(event: CustomEvent) {
    if (!this.fastSceneAndAutoCreate.saveScrollNow$.getValue()) {
      sessionStorage.setItem('sceneAndAutoScroll', event.detail.scrollTop);
    }
    if (this.router.url === '/rooms') {
      sessionStorage.setItem('scrollTop', event.detail.scrollTop);
    }
  }

  ngOnDestroy(): void {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
    if (this.deviceSubscription) {
      this.deviceSubscription.unsubscribe();
    }
  }

}
