import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { Property } from 'src/app/core/models/project/property.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { PinService } from 'src/app/shared/services/pin.service';
import { Mode } from 'src/app/core/models/modes/mode.model';
import { ModesService } from 'src/app/modules/modes/services/modes.service';
import { ApiModesService } from 'src/app/modules/modes/services/http/api-modes.service';
import { ApartmentService } from 'src/app/shared/services/apartment.service';
import { DemoService } from 'src/app/core/services/demo.service';

@Component({
  selector: 'app-equipment-number-live',
  templateUrl: './equipment-number-live.component.html',
  styleUrls: ['./equipment-number-live.component.scss']
})
export class EquipmentNumberLiveComponent implements OnInit, OnDestroy {
  @Input() parentDevice: Device;
  @Input() equipmentProperty: Property;
  @Input() unitOfMeasurement;

  numberSubscription: Subscription;
  numberChanged$ = new Subject<number>();

  targetNumber: number;
  tempTargetNumber: number;

  sliderValues = new BehaviorSubject(0);
  sliderButtonClicked = false;

  apartmentIdSubscription: Subscription;
  modesSubscription: Subscription;
  modes: Mode[];
  apartmentId: string;
  heatingActive: boolean;
  coolingActive: boolean;

  sliderLineClicked = false;


  // SLIDER SETTINGS
  options = {
    floor: 0,
    ceil: 50,
  };

  constructor(private apiProjectService: ApiProjectService,
              private pinService: PinService,
              private loadingService: LoadingService,
              private apiModesService: ApiModesService,
              private modesService: ModesService,
              private apartmentService: ApartmentService,
              private demoService: DemoService) { }

  ngOnInit(): void {
    this.initLiveMode();
    if (Property.isRoomTemperatureSet(this.equipmentProperty)) {
      this.options.floor = this.parentDevice.roomMin;
      this.options.ceil = this.parentDevice.roomMax;
      if (this.parentDevice.roomMin === this.parentDevice.roomMax) {
        this.options.floor = 0
        this.options.ceil = 30
      }
    }

    if (Property.isBathroomTemperatureSet(this.equipmentProperty)) {
      this.options.floor = this.parentDevice.bathroomMin;
      this.options.ceil = this.parentDevice.bathroomMax;
      if (this.parentDevice.bathroomMin === this.parentDevice.bathroomMax) {
        this.options.floor = 0
        this.options.ceil = 30
      }
    }

    this.getModes();
    this.apartmentIdSubscription = this.apartmentService.getApartmentId().subscribe((aptId: string) => {
      this.apartmentId = aptId;
      if (this.modes) {
        this.updateStatuses();
      }
    });
  }

  getModes() {
    this.modes = this.modesService.getModes();
    this.apiModesService.getModes().subscribe();
    this.modesSubscription = this.modesService.modesChanged.subscribe(() => {
      this.modes = this.modesService.getModes();
      if (this.demoService.isDemo()) {
        this.demoService.setModesForDemo(this.modes);
        this.modes = this.demoService.getModesForDemo();
      }
      this.updateStatuses();
    });
  }


  initLiveMode() {
    this.targetNumber = this.equipmentProperty.value;
    this.tempTargetNumber = this.equipmentProperty.value;
    this.numberSubscription = this.numberChanged$.subscribe(newNumber => {
      if (Number(newNumber) !== Number(this.equipmentProperty.value)) {
        this.loadingService.addToLoading(this.parentDevice.id+this.equipmentProperty.id.toString());
        if (this.pinService.pinIsSet() && this.equipmentProperty.security.requirePinToActivate) {
            this.apiProjectService.changeProperty(this.parentDevice.id, this.equipmentProperty.type, newNumber.toString(), this.pinService.getPin()).subscribe(() => {
              this.equipmentProperty.value = newNumber;
            });
        } else {
            this.apiProjectService.changeProperty(this.parentDevice.id, this.equipmentProperty.type, newNumber.toString()).subscribe(() => {
              this.equipmentProperty.value = newNumber;
            });
        }
      }
    });
  }

  sliderClicked() {
    this.sliderLineClicked = true;
  }

  onInputChanged(inputNumber) {
    if (!this.sliderButtonClicked && this.sliderLineClicked) {
      this.sliderLineClicked = false;
      this.sliderValues.next(inputNumber);
      this.targetNumber = inputNumber;
      setTimeout(() => {
        if (this.sliderValues.getValue() === inputNumber) {
          this.targetNumber = inputNumber;
          this.numberChanged$.next(inputNumber);
        }
      }, 2000);
    }
    this.sliderButtonClicked = false;
  }


  plusClicked(inputNumber?: number) {
    if (Number(inputNumber) + 1 <= this.options.ceil) {
      this.sliderButtonClicked = true;
      this.targetNumber = Number(inputNumber) + 1
      this.sliderValues.next(this.targetNumber);
      this.equipmentProperty.value = this.targetNumber;
    }
    setTimeout(() => {
      if (this.sliderValues.getValue() === Number(inputNumber) + 1 ) {
        if (inputNumber >= this.options.ceil) {
          this.targetNumber = this.options.ceil;
          this.numberChanged$.next(this.options.ceil);
          this.sliderValues.next(this.options.ceil);
        } else {
          this.numberChanged$.next(this.targetNumber);
        }
      }
    }, 2000);
    }

  minusClicked(inputNumber?: number) {
    if (Number(inputNumber) - 1 >= this.options.floor) {
      this.sliderButtonClicked = true;
      this.targetNumber = Number(inputNumber) - 1
      this.sliderValues.next(this.targetNumber);
      this.equipmentProperty.value = this.targetNumber;
    }
    setTimeout(() => {
      if (this.sliderValues.getValue() === Number(inputNumber) - 1) {
        if (inputNumber <= this.options.floor) {
          this.targetNumber = this.options.floor;
          this.numberChanged$.next(this.options.floor);
          this.sliderValues.next(this.options.floor);
        } else {
          this.numberChanged$.next(this.targetNumber);
        }
      }
    }, 2000);
  }

  updateStatuses() {
    this.modes?.forEach((mode: Mode) => {
      if (Mode.isCooling(mode) && (mode.apartmentId === this.apartmentId)){
        this.coolingActive = mode.isActive;
      } else if (Mode.isHeating(mode) && (mode.apartmentId === this.apartmentId)){
        this.heatingActive = mode.isActive;
      }
    });
  }

  getSliderColor() {
    if (this.coolingActive) {
      return 'primary';
    } else if(this.heatingActive) {
      return 'danger';
    }
  }


  ngOnDestroy() {
    if (this.numberSubscription) {
      this.numberSubscription.unsubscribe();
    }
    if (this.modesSubscription) {
      this.modesSubscription.unsubscribe();
    }
    if (this.apartmentIdSubscription) {
      this.apartmentIdSubscription.unsubscribe();
    }
  }

}
