import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { Property } from 'src/app/core/models/project/property.model';
import { PropertyType } from 'src/app/core/models/project/property-type.model';
import { Action } from 'src/app/core/models/automation/action.model';
import { Observable, Subject, Subscription } from 'rxjs';

import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { AdminSettingsService } from 'src/app/modules/admin-settings/admin-settings.service';

import { Condition } from 'src/app/core/models/automation/condition.model';
import { ComponentMode } from 'src/app/core/models/equipment-property-component-to-load.model';
import { PinService } from 'src/app/shared/services/pin.service';
import { ModalController } from '@ionic/angular';
import { ModalPinComponent } from '../../modal-pin/modal-pin.component';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { DemoService } from 'src/app/core/services/demo.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';

@Component({
  selector: 'app-equipment-binary',
  templateUrl: './equipment-binary.component.html',
  styleUrls: ['./equipment-binary.component.scss']
})
export class EquipmentBinaryComponent implements OnInit, OnDestroy {
  @Input() parentDevice: Device;
  @Input() equipmentProperty: Property;
  @Input() mode: ComponentMode;
  @Input() equipmentPropertyType: PropertyType;
  @Input() actionInput: Action;
  @Input() conditionInput: Condition;
  @Input() data;
  action: Action = new Action();
  actionChanged$ = new Subject<Action>();
  conditionChanged$ = new Subject<Condition>();



  condition: Condition;

  timer = 0;
  lightInterval;
  requestSent = false;

  // equimentState$: Observable<string>;

  settingValue$: Observable<any>;

  loadingSubscription: Subscription;
  loadingElements = [];
  buttonClicked = false;


  constructor(
    private apiProjectService: ApiProjectService,
    private adminSettingsService: AdminSettingsService,
    private pinService: PinService,
    private modalController: ModalController,
    private loadingService: LoadingService,
    private location: Location,
    private demoService: DemoService,
    private projectService: ProjectService ) { }

  ngOnInit(): void {
    if (this.mode === 'action') {
      this.initActionMode();
    } else if (this.mode === 'live') {
     this.initLiveMode();
     this.loadingSub();
    }    else if (this.mode === 'condition') {
      if (this.conditionInput) {
        this.condition = JSON.parse(JSON.stringify(this.conditionInput));
      } else {
      this.initConditionMode();
      }
     } else if (this.mode === 'settings') {
      this.settingValue$ = this.adminSettingsService.getSetting(`${this.parentDevice.id}/${this.equipmentProperty.type}`);
      // this.equimentState$ = this.mqttProjectService.getEquipmentPropertyValuesMqttStream(this.equipmentProperty.inComm.address);
     }
  }

  loadingSub() {
    this.loadingElements = this.loadingService.getLoadingElements();
    this.loadingSubscription = this.loadingService.loadingChanged.subscribe( response => {
      this.loadingElements = this.loadingService.getLoadingElements();
    });
  }

  loadingElement(id: string) {
    if (this.loadingElements.includes(id)) {
      return true;
    } else {
      return false;
    }
  }

  initConditionMode() {
    this.condition = new Condition();
    this.condition.automationConditionType = 'simple';
    this.condition.type = 0; // on off component always generates equals condition type (0)
    this.condition.parameter1 = `ValRef:${this.parentDevice.id}/${this.equipmentProperty.type}`;
    // this.condition.parameter2 = 0;
    this.condition.parameter3 = 0; // is ignored in on off (binary) component
    this.condition.editable = false;
    this.condition.parameter1Editable = false;
    this.condition.parameter2Editable = false;
    this.condition.parameter3Editable = false;
  }

  handleConditionChange(event) {
    /* this.condition.parameter2 = event.target.value;
    this.conditionChanged$.next(this.condition); */
    const newCondition: Condition = JSON.parse(JSON.stringify(this.condition))
    this.condition.parameter2 = Number(event.target.value);
    newCondition.parameter2 = event.target.value;
    this.conditionChanged$.next(newCondition);
  }


  initActionMode() {
    if (!this.actionInput) {
      this.action = this.createNewAction();
    } else {
      this.action = Object.assign(new Action(), this.actionInput);
    }
  }

  initLiveMode(){
    if (Property.isDimmableLight(this.equipmentProperty) && this.equipmentProperty.security.requirePinToActivate) {
      this.openPinModalOnPageLoad();
    }
  }

  createNewAction(): Action {
    const newAction = new Action();
    newAction.id = `tempActionId1`; // = this.automationThenId + '|' + this.selectedAction.id; aa0001|ca0001",
    newAction.name = `tempActionName1`;
    newAction.command = 'EquipmentActions.Activate';
    newAction.description = 'Action for activating equipment property';
    newAction.parameters = [`${this.parentDevice.id}/${this.equipmentProperty.type}`, undefined];
    newAction.builtIn = false;
    return newAction;
  }

  handleActionChange(event) {
    /* this.action.parameters[1] = event.target.value;
    this.actionChanged$.next(this.action); */
    const newAction = JSON.parse(JSON.stringify(this.action))
    this.action.parameters[1] = Number(event.target.value);
    newAction.parameters[1] = event.target.value;
    this.actionChanged$.next(newAction);
  }

  binaryDemoModeOnChangeSetup(newValue: number) {
    if (Property.isBathroomControlFloorHeating(this.equipmentProperty)) {
      const bathroomFloorHeatingStatus = this.parentDevice.equipmentProperties.find( element => {
        return Property.isBathroomFloorHeating(element);
      })
      setTimeout( () => {
        bathroomFloorHeatingStatus.value = newValue;
      }, 2000)
    } else if (Property.isBathroomControlRadiator(this.equipmentProperty)) {
      const bathroomRadiatorStatus = this.parentDevice.equipmentProperties.find( element => {
        return Property.isBathroomRadiator(element);
      })
      setTimeout( () => {
        bathroomRadiatorStatus.value = newValue;
      }, 2000)
    }
    this.projectService.changePropertyValueForDemo(this.parentDevice.id, this.equipmentProperty.type, newValue)
  }


  powerButtonClicked(event: Event) {
    event.preventDefault();
    if (this.demoService.isDemo()) {
      let newValue: number;
      if (+this.equipmentProperty.value === 1) {
        newValue = 0;
      }  else {
        newValue = 1;
      }
      this.loadingService.addToLoading(this.parentDevice.id+this.equipmentProperty.id.toString());
      this.binaryDemoModeOnChangeSetup(newValue);
      this.loadingService.removeFromLoading(this.parentDevice.id+this.equipmentProperty.id.toString())
    } else {
      if (Property.isDimmableLight(this.equipmentProperty)) {
        this.lightInterval = setInterval( () => {
          if (this.timer >= 2) {
            const state = '1';
            if (!this.requestSent) {
              if (this.pinService.pinIsSet()) {
                this.apiProjectService.changeProperty(this.parentDevice.id, this.equipmentProperty.type, state, this.pinService.getPin()).subscribe( () => {
                });
                this.requestSent = true;
              } else {
                this.apiProjectService.changeProperty(this.parentDevice.id, this.equipmentProperty.type, state).subscribe( () => {
                });
                this.requestSent = true;
              }
            }
          }
          this.timer++;
        }, 100);
      } else {
        let newValue: '1' | '0';
        if (+this.equipmentProperty.value === 1) {
          newValue = '0';
        }  else {
          newValue = '1';
        }
        this.loadingService.addToLoading(this.parentDevice.id+this.equipmentProperty.id.toString());
        if (this.pinService.pinIsSet()) {
          this.apiProjectService.changeProperty(this.parentDevice.id, this.equipmentProperty.type, newValue.toString(), this.pinService.getPin()).subscribe( () => {
          });
        }else {
          this.apiProjectService.changeProperty(this.parentDevice.id, this.equipmentProperty.type, newValue.toString()).subscribe( () => {
          });
        }
      }
    }
  }

stopSendingValue(event: Event) {
  this.buttonClicked = false;
  if (this.equipmentProperty.security.requirePinToActivate && this.pinService.pinIsSet()) {
    if (Property.isDimmableLight(this.equipmentProperty) && this.timer >= 2) {
      const state = '0';
      this.apiProjectService.changeProperty(this.parentDevice.id, this.equipmentProperty.type, state, this.pinService.getPin()).subscribe();
      clearInterval(this.lightInterval);
      this.requestSent = false;
    }

    if (Property.isDimmableLight(this.equipmentProperty) && this.timer < 2) {
      const state = '2';
      this.apiProjectService.changeProperty(this.parentDevice.id, this.equipmentProperty.type, state, this.pinService.getPin()).subscribe();
      clearInterval(this.lightInterval);
    }
    this.timer = 0;
  } else if (!this.equipmentProperty.security.requirePinToActivate) {
    if (Property.isDimmableLight(this.equipmentProperty) && this.timer >= 2) {
      const state = '0';
      this.apiProjectService.changeProperty(this.parentDevice.id, this.equipmentProperty.type, state).subscribe();
      clearInterval(this.lightInterval);
      this.requestSent = false;
    }

    if (Property.isDimmableLight(this.equipmentProperty) && this.timer < 2) {
      const state = '2';
      this.apiProjectService.changeProperty(this.parentDevice.id, this.equipmentProperty.type, state).subscribe();
      clearInterval(this.lightInterval);
    }
    this.timer = 0;
  }
}

toggleSettings() {
  this.adminSettingsService.toggleProperty(`${this.parentDevice.id}/${this.equipmentProperty.type}`);
}

propertyClicked(event: Event) {
  this.buttonClicked = true;
  if (this.equipmentProperty.security.requirePinToActivate && !this.pinService.pinIsSet()) {
    this.openPinModal(event);
  } else {
    this.powerButtonClicked(event);
  }
}

async openPinModal(event: Event) {
  const modal = await this.modalController.create({
    component: ModalPinComponent,
    cssClass: 'pin-modal-container',
    backdropDismiss: true,
    showBackdrop: true
  });
  modal.onDidDismiss()
  .then((resp) => {
    if (resp?.data?.choice === true) {
      this.pinService.setPin(resp?.data?.pin).subscribe( response => {
        if (response === true) {
          this.powerButtonClicked(event);
          this.stopSendingValue(event);
        }
      });
    }
});
  return await modal.present();
}

async openPinModalOnPageLoad() {
  const modal = await this.modalController.create({
    component: ModalPinComponent,
    cssClass: 'pin-modal-container',
    backdropDismiss: true,
    showBackdrop: true
  });
  modal.onDidDismiss()
  .then((resp) => {
    if (resp?.data?.choice === true) {
      this.pinService.setPin(resp?.data?.pin).subscribe();
    } else {
      this.location.back();
    }
});
  return await modal.present();
}


  ngOnDestroy() {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
  }

}
