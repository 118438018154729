import { Pipe, PipeTransform } from '@angular/core';
import { EquipmentPropertyTypesService } from '../services/equipment-property-types.service';

@Pipe({
  name: 'adminPropsFilter'
})
export class AdminPropsFilterPipe implements PipeTransform {

  constructor(private equipmentPropertyTypesService: EquipmentPropertyTypesService) {}

  transform(devices: any[]): any[] {
    if (!devices || devices == undefined || devices.length == 0) {
      return [];
    }
      return devices.filter( (device: any) => {
          return device.equipmentProperties.some( prop => {
            if (this.equipmentPropertyTypesService.getEquipmentPropertyTypeForStatus(prop.type) || this.equipmentPropertyTypesService.getEquipmentPropertyType(prop.type)) {
              return true;
            }
          });
      });

  }
}

