<div class="content-header flex-row-center-vertical">
    <div class="i-back-arrow" (click)="goBack()"></div>
    <h1 class="middle">{{ 'active_devices' | translate }}</h1>
  </div>

 <div class="flex-row space-around">
  <div (click)="selectFloor(null)"
    class="tab-in-row"
    [ngClass]="{ 'tab-selected': !selectedFloor }">
    {{ 'all' | translate }}
 </div>
  <div
    *ngFor="let floor of getFloorsWithActiveDevices(project?.listOfFloors)"
    (click)="selectFloor(floor)"
    class="tab-in-row"
    [ngClass]="{ 'tab-selected': selectedFloor?.id === floor?.id }"
  >
  <span>
    {{ floor.name }}
  </span>
</div>
</div>
<div class="margin-top10">
 <ng-container *ngFor="let room of project?.listOfRooms
 | filterRoomsByFloor: selectedFloor
 | aptRoomFilter: (selectedApartmentId$ | async)"
>
  <app-single-room
    [activeRoomId]="room.id"
    [roomViewType]="'activeDevices'"
    class="no-side-padding"
  ></app-single-room>
</ng-container>
</div>

