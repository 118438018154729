  <!-- <p>Select the devices that you want included in the scene. Choose at least one.</p> -->
    <div *ngFor="let room of chosenRooms">
      <div class="card-title content-header">{{room.name}}</div>
      <div class="items-grid-container">
        <div class="single-grid-item-container" *ngFor="let device of getLightsForRoom(room.id) | deviceFilter: mode | filterDeviceByVisibleToUser: USE_DEVICE_VISIBLE_TO_USER: user | adminPropsFilter"  (click)="onDeviceClicked(device)">
          <div class="grid-item-icon" [ngClass]="deviceInAutomatization(device) ? 'i-lightbulb-orange' : 'i-lightbulb-gray'"></div>
          <div class="grid-item-icon-text">{{device.name}}</div>
        </div>
  
        <div class="single-grid-item-container" *ngFor="let device of getBlindsForRoom(room.id) | deviceFilter: mode | filterDeviceByVisibleToUser: USE_DEVICE_VISIBLE_TO_USER: user | adminPropsFilter" (click)="onDeviceClicked(device)" >
          <div class="grid-item-icon" [ngClass]="deviceInAutomatization(device) ? 'i-blinds-orange' : 'i-blinds-gray'"></div>
          <div class="grid-item-icon-text">{{device.name}}</div>
        </div>
  
        <div class="single-grid-item-container" *ngFor="let device of getHvacForRoom(room.id) | deviceFilter: mode | filterDeviceByVisibleToUser: USE_DEVICE_VISIBLE_TO_USER: user | adminPropsFilter" (click)="onDeviceClicked(device)" >
          <div class="grid-item-icon" [ngClass]="deviceInAutomatization(device) ? 'i-hvac-orange' : 'i-hvac-gray'"></div>
          <div class="grid-item-icon-text">{{device.name}}</div>
        </div>
        <div class="single-grid-item-container" *ngFor="let device of getSecurityEquipmentForRoom(room.id) | deviceFilter: mode | filterDeviceByVisibleToUser: USE_DEVICE_VISIBLE_TO_USER: user | adminPropsFilter" (click)="onDeviceClicked(device)" >
          <div class="grid-item-icon" [ngClass]="deviceInAutomatization(device) ? 'i-security-equipment-orange' : 'i-security-equipment-gray'"></div>
          <div class="grid-item-icon-text">{{device.name}}</div>
        </div>
        <div class="single-grid-item-container" *ngFor="let device of getSafetyEquipmentForRoom(room.id) | deviceFilter: mode | filterDeviceByVisibleToUser: USE_DEVICE_VISIBLE_TO_USER: user | adminPropsFilter" (click)="onDeviceClicked(device)" >
          <div class="grid-item-icon" [ngClass]="deviceInAutomatization(device) ? 'i-safety-equipment-orange' : 'i-safety-equipment-gray'"></div>
          <div class="grid-item-icon-text">{{device.name}}</div>
        </div>
        <div class="single-grid-item-container" *ngFor="let device of getGeneralEquipmentForRoom(room.id) | deviceFilter: mode | filterDeviceByVisibleToUser: USE_DEVICE_VISIBLE_TO_USER: user | adminPropsFilter" (click)="onDeviceClicked(device)" >
          <div class="grid-item-icon" [ngClass]="deviceInAutomatization(device) ? 'i-general-equipment-orange' : 'i-general-equipment-gray'"></div>
          <div class="grid-item-icon-text">{{device.name}}</div>
        </div>
      </div>
  
      <div *ngIf="useDali && checkIfUserSeeDali()">
        <div class="card-title">DALI</div>
        <div class="items-grid-container">
          <div class="single-grid-item-container" *ngFor="let device of getDaliLightsForRoom(room.id) | deviceFilter: mode | filterDeviceByVisibleToUser: USE_DEVICE_VISIBLE_TO_USER: user"  (click)="onDeviceClicked(device)">
            <div class="grid-item-icon" [ngClass]="deviceInDaliAutomatization(device) ? 'i-lightbulb-orange' : 'i-lightbulb-gray'"></div>
            <div class="grid-item-icon-text">{{device?.name}}</div>
          </div>
          <div class="single-grid-item-container" *ngFor="let device of getDaliRgbLightsForRoom(room.id) | deviceFilter: mode" (click)="onDeviceClicked(device)">
            <div class="grid-item-icon" [ngClass]="deviceInDaliAutomatization(device) ? 'i-lightbulb-orange' : 'i-lightbulb-gray'"></div>
            <div class="grid-item-icon-text">{{device?.name}}</div>
          </div>
          <div class="single-grid-item-container" *ngFor="let device of getDaliLightsGroupForRoom(room.id) | deviceFilter: mode"  (click)="onDeviceClicked(device)">
            <div class="grid-item-icon" [ngClass]="deviceInDaliAutomatization(device) ? 'i-lightbulb-orange' : 'i-lightbulb-gray'"></div>
            <div class="grid-item-icon-text">{{device?.name}}</div>
          </div>
          <div class="single-grid-item-container" *ngFor="let device of getDaliBlindsForRoom(room.id) | deviceFilter: mode | filterDeviceByVisibleToUser: USE_DEVICE_VISIBLE_TO_USER: user"  (click)="onDeviceClicked(device)">
            <div class="grid-item-icon" [ngClass]="deviceInDaliAutomatization(device) ? 'i-blinds-orange' : 'i-blinds-gray'"></div>
            <div class="grid-item-icon-text">{{device?.name}}</div>
          </div>
        </div>
      </div>
      <div *ngIf="checkFilters(room.id)" class="flex-row-center width100">
        {{'No Devices: Filters in use' | translate}}
      </div>
      <div *ngIf="!canAnyDeviceBeSelected(room.id)" class="flex-row-center width100">
        {{'No selectable devices' | translate}}
      </div>
    </div>

