import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

import { AlarmAlertsService } from 'src/app/modules/alarms/services/alarm-alerts.service';
import { User } from 'src/app/core/models/user/user.model';
import { DemoService } from './demo.service';
import { MqttProjectService } from '../app-load/mqtt-project.service';
// import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    user: User;
    userChanged = new Subject<User>();

    // alarmsInitialized = false;
    // alarmsService: AlarmAlertsService;
    // alarmAlertComponentRef: any;

    constructor(
        private demoService: DemoService,
        private alarmAlertService: AlarmAlertsService,
        private mqttProjectService: MqttProjectService
        // private apiProjectService: ApiProjectService
    ) { }

    setUser(user: User) {
        this.user = new User();
        Object.assign(this.user, user);
        this.userChanged.next(user);
        if (!this.mqttProjectService.alarmsInitialized && !this.demoService.isDemo() && (User.isAdmin(user) || User.isOwner(user))) {
            // this.alarmsService = this.injector.get(AlarmAlertsService) as AlarmAlertsService;
            this.mqttProjectService.initAlarmsService();
        }
    }

    getUser(): User {
        return this.user;
    }

    unsetUser() {
      this.user = undefined;
    }


    // appendAlarmAlertsToBody() {
    //     // 1. Create a component reference from the component
    //     this.alarmAlertComponentRef = this.componentFactoryResolver
    //         .resolveComponentFactory(AlarmAlertsComponent)
    //         .create(this.injector);

    //     // 2. Attach component to the appRef so that it's inside the ng component tree
    //     this.appRef.attachView(this.alarmAlertComponentRef.hostView);

    //     // 3. Get DOM element from component
    //     const domElem = (this.alarmAlertComponentRef.hostView as EmbeddedViewRef<any>)
    //         .rootNodes[0] as HTMLElement;

    //     // 4. Append DOM element to the body
    //     document.body.getElementsByTagName('app-root')[0].appendChild(domElem);
    // }

    // removeAlarmAlertsFromBody() {
    //     if (this.alarmsInitialized) {
    //         this.appRef.detachView(this.alarmAlertComponentRef.hostView);
    //         this.alarmAlertComponentRef.destroy();
    //         this.alarmsInitialized = false;
    //     }
    // }
}
