<div class="flex-column-center-full width100 height80vh">
  <i class="i-network-error"></i>
  <div class="margin-top10">{{ "Network connectivity problems" | translate }}</div>
  <ion-button
    size="default"
    class="square-secondary margin-top50"
    color="ocean-blue"
    [disabled]="loading"
    (click)="retryConnection()"
    >{{ "retry" | translate }}</ion-button
  >
</div>
