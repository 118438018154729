import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ProjectService } from '../project.service';
import { Project } from 'src/app/core/models/project/project.model';
import { API_BASE_URL } from 'src/environments/environment';
import { map, tap, mergeMap  } from 'rxjs/operators';
import { RoomsGroup } from 'src/app/core/models/project/groups/rooms-group';
import { ApartmentsGroup } from 'src/app/core/models/project/groups/apartments-group';
import { BuildingsGroup } from 'src/app/core/models/project/groups/buildings-group';
import { BlindsGroup } from 'src/app/core/models/project/groups/blinds-group';
import { GeneralEquipmentsGroup } from 'src/app/core/models/project/groups/general-equipments-group';
import { HvacsGroup } from 'src/app/core/models/project/groups/hvacs-group';
import { LightsGroup } from 'src/app/core/models/project/groups/lights-group';
import { Graphs } from 'src/app/core/models/graph.model';
import { NetworkInterface } from '@ionic-native/network-interface/ngx';
import { from } from 'rxjs';
import { Platform } from '@ionic/angular';
import { DemoService } from 'src/app/core/services/demo.service';
import { AsyncSubject } from 'rxjs/internal/AsyncSubject';
import { AccessControl } from 'src/app/core/models/project/access-control.model';
import { Room } from 'src/app/core/models/project/room.model';


@Injectable({
    providedIn: 'root'
})
export class ApiProjectService {

    projectReady$ =  new AsyncSubject<boolean>();


    constructor(private http: HttpClient,
                private projectService: ProjectService,
                private networkInterface: NetworkInterface,
                private platform: Platform,
                private demoService: DemoService
                ) { }

    isProjectLoaded() {
        return this.projectReady$.asObservable();
        }

    getProject() {
        if (this.demoService.isDemo()) {
                return this.demoService.getDemoProject().pipe(
                    tap( demoProject => {
                        if (this.projectService.getProject() === undefined) {
                            this.projectService.setProject(demoProject);
                        } else {
                            this.projectService.setProject(this.projectService.getProject());
                        }
                        this.projectReady$.next(true);
                        this.projectReady$.complete();
                    })
                );

        } else { 
            return this.http.get<Project>(API_BASE_URL + '/project').pipe(
                tap(project => {
                  this.projectService.setProject(project);
                  this.projectReady$.next(true);
                  this.projectReady$.complete();
                })
            );
    }
        }

    setProject(body: Project) {
        return this.http.put<Project>(API_BASE_URL + '/project', body).pipe(
            tap(() => {
                    this.projectService.setProject(body);
                })
        );
    }

    setRoomsGroup(body: RoomsGroup) {
        return this.http.post<RoomsGroup>(API_BASE_URL + '/project/groups/room', body).pipe(
            map(() => {
                this.projectService.setRoomsGroup(body);
                return;
            })
        );
    }

    updateRoomsGroup(body: RoomsGroup) {
        return this.http.put<RoomsGroup>(API_BASE_URL + '/project/groups/room', body).pipe(
            map(() => {
                return;
            })
        );
    }

    deleteRoomsGroup(id: string) {
        const params = new HttpParams().set('groupId', id);
        return this.http.delete<RoomsGroup>(API_BASE_URL + '/project/groups/room', { params }).pipe(
            map(() => {
                this.projectService.deleteRoomsGroup(id);
                return;
            })
        );
    }

    setApartmentsGroup(body: ApartmentsGroup) {
        return this.http.post<ApartmentsGroup>(API_BASE_URL + '/project/groups/apartment', body).pipe(
            map(() => {
                this.projectService.setApartmentsGroup(body);
                return;
            })
        );
    }

    updateApartmentsGroup(body: ApartmentsGroup) {
        return this.http.put<ApartmentsGroup>(API_BASE_URL + '/project/groups/apartment', body).pipe(
            map(() => {
                return;
            })
        );
    }

    deleteApartmentsGroup(id: string) {
        const params = new HttpParams().set('groupId', id);
        return this.http.delete<RoomsGroup>(API_BASE_URL + '/project/groups/apartment', { params }).pipe(
            map(() => {
                this.projectService.deleteApartmentsGroup(id);
                return;
            })
        );
    }

    setBuildingsGroup(body: BuildingsGroup) {
        return this.http.post<BuildingsGroup>(API_BASE_URL + '/project/groups/building', body).pipe(
            map(() => {
                this.projectService.setBuildingsGroup(body);
                return;
            })
        );
    }

    updateBuildingsGroup(body: BuildingsGroup) {
        return this.http.put<BuildingsGroup>(API_BASE_URL + '/project/groups/building', body).pipe(
            map(() => {
                return;
            })
        );
    }

    deleteBuildingsGroup(id: string) {
        const params = new HttpParams().set('groupId', id);
        return this.http.delete<BuildingsGroup>(API_BASE_URL + '/project/groups/building', { params }).pipe(
            map(() => {
                this.projectService.deleteBuildingsGroup(id);
                return;
            })
        );
    }


    setBlindsGroup(body: BlindsGroup) {
        return this.http.post<BlindsGroup>(API_BASE_URL + '/project/groups/blind', body).pipe(
            map(() => {
                this.projectService.setBlindsGroup(body);
                return;
            })
        );
    }

    updateBlindsGroup(body: BlindsGroup) {
        return this.http.put<BlindsGroup>(API_BASE_URL + '/project/groups/blind', body).pipe(
            map(() => {
                return;
            })
        );
    }

    deleteBlindsGroup(id: string) {
        const params = new HttpParams().set('groupId', id);
        return this.http.delete<RoomsGroup>(API_BASE_URL + '/project/groups/blind', { params }).pipe(
            map(() => {
                this.projectService.deleteBlindsGroup(id);
                return;
            })
        );
    }

    setGeneralEquipmentsGroup(body: GeneralEquipmentsGroup) {
        return this.http.post<GeneralEquipmentsGroup>(API_BASE_URL + '/project/groups/general', body).pipe(
            map(() => {
                this.projectService.setGeneralEquipmentsGroup(body);
                return;
            })
        );
    }

    updateGeneralEquipmentsGroup(body: GeneralEquipmentsGroup) {
        return this.http.put<GeneralEquipmentsGroup>(API_BASE_URL + '/project/groups/general', body).pipe(
            map(() => {
                return;
            })
        );
    }

    deleteGeneralEquipmentsGroup(id: string) {
        const params = new HttpParams().set('groupId', id);
        return this.http.delete<RoomsGroup>(API_BASE_URL + '/project/groups/general', { params }).pipe(
            map(() => {
                this.projectService.deleteGeneralEquipmentsGroup(id);
                return;
            })
        );
    }

    setHvacsGroup(body: HvacsGroup) {
        return this.http.post<HvacsGroup>(API_BASE_URL + '/project/groups/hvac', body).pipe(
            map(() => {
                this.projectService.setHvacsGroup(body);
                return;
            })
        );
    }

    updateHvacsGroup(body: HvacsGroup) {
        return this.http.put<HvacsGroup>(API_BASE_URL + '/project/groups/hvac', body).pipe(
            map(() => {
                return;
            })
        );
    }

    deleteHvacsGroup(id: string) {
        const params = new HttpParams().set('groupId', id);
        return this.http.delete<RoomsGroup>(API_BASE_URL + '/project/groups/hvac', { params }).pipe(
            map(() => {
                this.projectService.deleteHvacsGroup(id);
                return;
            })
        );
    }

    setLightsGroup(body: LightsGroup) {
        return this.http.post<LightsGroup>(API_BASE_URL + '/project/groups/light', body).pipe(
            map(() => {
                this.projectService.setLightsGroup(body);
                return;
            })
        );
    }

    updateLightsGroup(body: LightsGroup) {
        return this.http.put<LightsGroup>(API_BASE_URL + '/project/groups/light', body).pipe(
            map(() => {
                return;
            })
        );
    }

    deleteLightsGroup(id: string) {
        const params = new HttpParams().set('groupId', id);
        return this.http.delete<RoomsGroup>(API_BASE_URL + '/project/groups/light', { params }).pipe(
            map(() => {
                this.projectService.deleteLightsGroup(id);
                return;
            })
        );
    }

    async getIpAddress() {
        if (this.platform.is('mobile')) {
            let address: { ip: string; subnet: string };
            try {
              address = await this.networkInterface.getWiFiIPAddress();
            } catch {
              try {
                address = await this.networkInterface.getCarrierIPAddress();
              } catch (err) {
              }
            }
            return address.ip;
        } else {
            return '';
            }
      }

    changeProperty(id: string, type: number, value: string, pin?: string) {
          return from(this.getIpAddress()).pipe(
            mergeMap((ipAddress) => {
              const headersVariable: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json', OriginIp: ipAddress });
              const params = new HttpParams().set('value', value);
              let body = '';
              if (pin !== undefined) {
                body = `${pin}`;
              }
              return this.http.post(
                `${API_BASE_URL}/equipment/${id}/${type}`,
                `"${body}"`,
                { params, responseType: 'text', headers: headersVariable }
              );
            })
          );
      }

    getGraph(body: Graphs) {
        return this.http.post<any>(API_BASE_URL + '/valuelogs', body).pipe(
            map( response => {
                return response;
            })
        );
    }


    getEquipmentPropertyValue(id: string, type: number) {
      return this.http.get(`${API_BASE_URL}/equipment/${id}/${type}`);
    }

    roomToAccessControl(body: Room) {
        return this.http.post<Room>(API_BASE_URL + '/project/accesscontrols', body).pipe(
            map( response => {
                return response
            })
        )
    }

    updateAccessControl(body: AccessControl) {
        return this.http.put<AccessControl>(API_BASE_URL + '/project/accesscontrols', body).pipe(
            map( response => {
                return response
            })
        )
    }

    deleteAccessControl(id: string) {
        return this.http.delete(API_BASE_URL + '/project/accesscontrols/' + id ).pipe(
            map( response => {
                return response
            })
        )
    }

    getAllControllers() {
        return this.http.get(API_BASE_URL + '/project/controllers').pipe(
            map( response => {
                return response
            })
        )
    }
}
