import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Light } from 'src/app/core/models/project/devices/light.model';
import { Room } from 'src/app/core/models/project/room.model';
import { DaliProjectService } from 'src/app/modules/project/services/dali-project.service';
import { ApiDaliProjectService } from 'src/app/modules/project/services/http/api-dali-project.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { RoomViewService } from 'src/app/modules/rooms/services/room-view.service';

@Component({
  selector: 'app-dali-device',
  templateUrl: './dali-device.component.html',
  styleUrls: ['./dali-device.component.scss']
})
export class DaliDeviceComponent implements OnInit, OnDestroy {

  room: Room;
  activeRoomId: string;
  daliLightId: string;
  daliLight;
  daliProjectSubscription: Subscription;
  backButtonSubscription: Subscription;

  constructor(
    private router: Router,
    private roomViewService: RoomViewService,
    private route: ActivatedRoute,
    private daliProjectService: DaliProjectService,
    private apiDaliProjectService: ApiDaliProjectService,
    private projectService: ProjectService,
    public platform: Platform
    ) { }

  ngOnInit(): void {
    this.activeRoomId = this.route.snapshot.paramMap.get('id');
    this.daliLightId = this.route.snapshot.paramMap.get('daliId');
    this.getDaliLight();
    this.daliProjectService.daliProjectChanged.subscribe(() => {
      this.getDaliLight();
    });

    this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(10, () => {
      this.goBack()
    });

    this.room = this.projectService.getRoom(this.activeRoomId);
  }

  getDaliLight() {
    const light = this.daliProjectService.getDaliLight(this.daliLightId)
    || this.daliProjectService.getDaliRgbLight(this.daliLightId)
    || this.daliProjectService.getDaliLightsGroup(this.daliLightId)
    || this.daliProjectService.getDaliBlinds(this.daliLightId);
    this.daliLight = Object.assign({}, light);
  }

  daliLightIsDimmable(daliLight) {
    return Light.isDimmableDaliLight(daliLight);
  }

  daliLightIsRgb(daliLight) {
    return (daliLight?.id?.split('-')[0] === 'DRGB');
  }

  isDaliGroup(daliLight) {
    return (daliLight?.id?.split('-')[0] === 'DG');
  }

  isDaliBlind(daliLight) {
    return (daliLight?.id?.split('-')[0] === 'DBL');
  }


  goBack() {
    this.roomViewService.getDeviceRouteOrigin().pipe(take(1)).subscribe((deviceRouteOrigin) => {
      if (deviceRouteOrigin === 'rooms') {
          this.router.navigate([`/rooms/${this.room.id}`]);
      } else if (deviceRouteOrigin === 'home'){
        this.router.navigate([`/home`]);
      } else if (deviceRouteOrigin === 'activeDevices'){
        this.router.navigate([`/active-devices`]);
      } else {
        this.router.navigate([`/rooms`]);
      }
    });
  }

  ngOnDestroy() {
    if (this.daliProjectSubscription) {
      this.daliProjectSubscription.unsubscribe();
    }
    if (this.backButtonSubscription) {
      this.backButtonSubscription.unsubscribe();
    }
  }

}
