import { Device } from './device.model';

export class Light extends Device{
        listOfLightGroups: string[];


    public static isDimmableDaliLight(daliLight: Light) {
      if (!daliLight?.equipmentProperties){
        return false;
      } else if (daliLight?.equipmentProperties[0]?.type >= 26000 && daliLight?.equipmentProperties[0]?.type <= 29999) {
        return true;
      } else {
        return false;
      }

    }

    public static isNormalDaliLight(daliLight: Light) {
      if (!daliLight?.equipmentProperties){
        return false;
      } else if (daliLight?.equipmentProperties[0]?.type >= 25000 && daliLight?.equipmentProperties[0]?.type <= 25999) {
        return true;
      } else {
        return false;
      }

    }

}
