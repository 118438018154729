import { Pipe, PipeTransform } from '@angular/core';
import { Room } from 'src/app/core/models/project/room.model';

@Pipe({
  name: 'aptRoomFilter'
})
export class AptRoomFilterPipe implements PipeTransform {

  transform(rooms: Room[], apartmentId: string): Room[] {
    if (!rooms) {
      return [];
      }
    if (!apartmentId) {
      return rooms;
      }
    return rooms.filter((room: Room) => {
      return room.apartmentId === apartmentId;
    } );
  }

}
