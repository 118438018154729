import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AutomationService } from '../automation.service';
import { map, tap } from 'rxjs/operators';
import { API_BASE_URL } from 'src/environments/environment';
import { Automation } from 'src/app/core/models/automation/automation.model';
import { Action } from 'src/app/core/models/automation/action.model';
import { DemoService } from 'src/app/core/services/demo.service';


@Injectable({
    providedIn: 'root'
})
export class ApiAutomationService {

    constructor(
        private http: HttpClient,
        private automationService: AutomationService,
        private demoService: DemoService
    ) { }


    getAutomations() {
        if (this.demoService.isDemo()) {
            return this.demoService.getDemoAutomations().pipe(
                tap( demoAutomations => {
                    this.automationService.setAutomations(demoAutomations);
                })
            );
        } else {
            return this.http.get<Automation[]>(API_BASE_URL + '/automations').pipe(
                map(automations => {
                    this.automationService.setAutomations(automations);
                    return automations;
                })
            );
        }
    }

    startAutomation(id: string) {
        const params = new HttpParams().set('id', id);
        return this.http.get<boolean>(API_BASE_URL + '/automations/start', { params }).pipe(
            map(automation => {
                return automation;
            })
        );
    }

    stopAutomation(id: string) {
        const params = new HttpParams().set('id', id);
        return this.http.get<boolean>(API_BASE_URL + '/automations/stop', { params }).pipe(
            map(automation => {
                return automation;
            })
        );
    }

    isRunningAutomation(id: string) {
        const params = new HttpParams().set('id', id);
        return this.http.get<boolean>(API_BASE_URL + '/automations/running', { params }).pipe(
            map(automation => {
                return automation;
            })
        );
    }

    getCustomActions() {
        return this.http.get<Action[]>(API_BASE_URL + '/actions/custom').pipe(
            map(actions => {
                this.automationService.setActions(actions);
                return actions;
            })
        );
    }

    getNextAutomationId() {
        return this.http.get(API_BASE_URL + '/automations/nextautomationid', { responseType: 'text' }).pipe(
            map(id => {
                return id;
            })
        );
    }

    saveAutomation(body: Automation) {
        return this.http.post<any>(API_BASE_URL + '/automations', body).pipe(
            map(automation => {
                this.automationService.addAutomation(automation);
                return automation;
            })
        );
    }

    updateAutomation(body: Automation) {
        return this.http.put<any>(API_BASE_URL + '/automations', body).pipe(
            map(automation => {
                this.automationService.updateAutomation(automation);
                return automation;
            })
        );
    }

    deleteAutomation(id: string) {
        const params = new HttpParams().set('id', id);
        return this.http.delete<any>(API_BASE_URL + '/automations', { params }).pipe(
            map(automation => {
                this.automationService.deleteAutomation(id);
                return automation;
            })
        );
    }

    getNextAutomationOccurrence(id: string) {
        const params = new HttpParams().set('id', id);
        return this.http.get<any>(API_BASE_URL + '/automations/nextoccurrence', { params }).pipe(
            map(time => {
                return time;
            })
        );
    }
}
