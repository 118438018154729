import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SLEEP_TIMEOUT } from 'src/environments/environment';
import { ScreenBrightness } from '@capacitor-community/screen-brightness';


@Injectable({
  providedIn: 'root'
})
export class SleepService {

  private sleepMode$ = new BehaviorSubject<boolean>(false);
  private sleepTimeout: NodeJS.Timeout = null;

  constructor() { }

  public async activateSleepTimer() {
      // try {
      //   const currentBrightness: GetBrightnessReturnValue = await ScreenBrightness.getBrightness();
      //   console.log(currentBrightness.brightness);
      // } catch {
      //   console.log('Error reading brightness');
      // }
      this.clearSleepTimer();
      if (SLEEP_TIMEOUT > 0) {
      this.sleepTimeout = setTimeout(() => {
        this.turnOnSleepMode();
      }, SLEEP_TIMEOUT);
    }
  }


  private clearSleepTimer() {
    if (this.sleepMode$.getValue()) {
      this.turnOffSleepMode();
    }
    if (this.sleepTimeout != null) {
      clearTimeout(this.sleepTimeout);
      this.sleepTimeout = null;
    }
  }



  private async turnOnSleepMode() {
    try {
      await ScreenBrightness.setBrightness({brightness: 0});
      // console.log("brightness set to 0")
    } catch {
      console.log('Error setting brightness');
    }
    this.sleepMode$.next(true);
  }

  private async turnOffSleepMode() {
    try {
      await ScreenBrightness.setBrightness({brightness: -1});
      // console.log("brightness set to -1")
    } catch {
      console.log('Error setting brightness');
    }
    this.sleepMode$.next(false);
  }

  public getSleepMode() {
    return this.sleepMode$.asObservable();
  }

}

