import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LoadingService  {

  elementsThatAreLoading: Array<string> = [];
  loadingChanged = new Subject<boolean>();

  constructor() { }


  addToLoading(id: string) {
    setTimeout( () => {
      this.removeIfNoResponse(id);
    }, 10000);
    if (!this.elementsThatAreLoading.includes(id)) {
      this.elementsThatAreLoading.push(id);
    }
    this.loadingChanged.next(true);
  }

  removeIfNoResponse(id: string) {
    if (this.elementsThatAreLoading.includes(id)) {
      this.elementsThatAreLoading = this.elementsThatAreLoading.filter( element => element !== id);
      this.loadingChanged.next(true);
    }
  }

  removeFromLoading(id: string) {
    setTimeout( () => {
      this.elementsThatAreLoading = this.elementsThatAreLoading.filter( element => element !== id);
      this.loadingChanged.next(false);
    }, 500);
  }

  getLoadingElements() {
    const elements = [];
    elements.push(...this.elementsThatAreLoading);
    return elements;
  }
}
