import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { Project } from 'src/app/core/models/project/project.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { RoomsGroup } from 'src/app/core/models/project/groups/rooms-group';
import { BuildingsGroup } from 'src/app/core/models/project/groups/buildings-group';
import { Subject } from 'rxjs';
import { ApartmentsGroup } from 'src/app/core/models/project/groups/apartments-group';
import { BlindsGroup } from 'src/app/core/models/project/groups/blinds-group';
import { GeneralEquipmentsGroup } from 'src/app/core/models/project/groups/general-equipments-group';
import { HvacsGroup } from 'src/app/core/models/project/groups/hvacs-group';
import { LightsGroup } from 'src/app/core/models/project/groups/lights-group';
import { ModalController } from '@ionic/angular';
import { Floor } from 'src/app/core/models/project/floor.model';

@Component({
  selector: 'app-modal-project',
  templateUrl: './modal-project.component.html',
  styleUrls: ['./modal-project.component.scss']
})
export class ModalProjectComponent implements OnInit, OnDestroy {
  @Input() type: string;
  @Input() modalContent: any;
  @Input() index: number;
  content: any;

  showId: Array<string> = [];

  name: string;
  equipmentNames: Array<string> = [];
  project: Project;

  groupId: string;
  groupName: string;
  floorName: string;
  floorForApartment: string;
  destroyed$: Subject<any> = new Subject<any>();

  commaCounter = 1;

  showDevices = false;
  showGroup: boolean;

  constructor(private projectService: ProjectService,
              private apiProjectService: ApiProjectService,
              private modalController: ModalController) { }

  ngOnInit() {
    this.showDevices = false;
    if (this.modalContent) {
      this.content = JSON.parse(JSON.stringify(this.modalContent));
    }
    //console.log(this.content);
    this.name = this.content?.name;
    this.project = JSON.parse(JSON.stringify(this.projectService.getProject()));
    //console.log(this.project);
    this.floorForApartment = this.project.listOfApartments[0].id;
  }

  createNewGroup() {
    if (this.groupName) {
      switch (this.type) {
        case 'listOfBuildingGroups': {
          const body: BuildingsGroup = new BuildingsGroup();
          body.id = (this.project.listOfBuildingGroups.length + 1).toString();
          body.name = this.groupName;
          body.listOfBuildings = [];
          this.apiProjectService.setBuildingsGroup(body).subscribe(() => {
            this.project = JSON.parse(JSON.stringify(this.projectService.getProject()));
          });
          break;
        }

        case 'listOfApartmentGroups': {
          const body: ApartmentsGroup = new ApartmentsGroup();
          body.id = (this.project.listOfApartmentGroups.length + 1).toString();
          body.name = this.groupName;
          body.listOfApartments = [];
          this.apiProjectService.setApartmentsGroup(body).subscribe(() => {
            this.project = JSON.parse(JSON.stringify(this.projectService.getProject()));
          });
          break;
        }

        case 'listOfRoomGroups': {
          const body: RoomsGroup = new RoomsGroup();
          body.id = (this.project.listOfRoomGroups.length + 1).toString();
          body.name = this.groupName;
          body.listOfRooms = [];
          this.apiProjectService.setRoomsGroup(body).subscribe(() => {
            this.project = JSON.parse(JSON.stringify(this.projectService.getProject()));
          });
          break;
        }
        case 'listOfBlindsGroups': {
          const body: BlindsGroup = new BlindsGroup();
          body.id = (this.project.listOfBlindsGroups.length + 1).toString();
          body.name = this.groupName;
          body.listOfBlinds = [];
          this.apiProjectService.setBlindsGroup(body).subscribe(() => {
            this.project = JSON.parse(JSON.stringify(this.projectService.getProject()));
          });
          break;
        }
        case 'listOfGeneralEquipmentGroup': {
          const body: GeneralEquipmentsGroup = new GeneralEquipmentsGroup();
          body.id = (this.project.listOfGeneralEquipmentGroup.length + 1).toString();
          body.name = this.groupName;
          body.listOfGeneralEquipment = [];
          this.apiProjectService.setGeneralEquipmentsGroup(body).subscribe(() => {
            this.project = JSON.parse(JSON.stringify(this.projectService.getProject()));
          });
          break;
        }
        case 'listOfHvacGroups': {
          const body: HvacsGroup = new HvacsGroup();
          body.id = (this.project.listOfHvacGroups.length + 1).toString();
          body.name = this.groupName;
          body.listOfHvac = [];
          this.apiProjectService.setHvacsGroup(body).subscribe(() => {
            this.project = JSON.parse(JSON.stringify(this.projectService.getProject()));
          });
          break;
        }
        case 'listOfLightGroups': {
          const body: LightsGroup = new LightsGroup();
          body.id = (this.project.listOfLightGroups.length + 1).toString();
          body.name = this.groupName;
          body.listOfLight = [];
          this.apiProjectService.setLightsGroup(body).subscribe(() => {
            this.project = JSON.parse(JSON.stringify(this.projectService.getProject()));
          });
          break;
        }
        default:
          break;
      }
    }
  }

  createNewFloor() {
    const floor: Floor = new Floor();
    if (this.project.listOfFloors.length === 0) {
      floor.id = '0';
      floor.position = 0;
    } else {
      let maxId = 0;
      this.project.listOfFloors.forEach( fl => {
        if (+fl.id > +maxId) {
          maxId = +fl.id;
        }
      });
      floor.id = (maxId + 1).toString();
    }

    if (this.project.listOfFloors.length !== 0) {
      let maxPosition = 0;
      this.project.listOfFloors.forEach( fl => {
        if (fl.apartmentId === this.floorForApartment) {
          if (fl.position > maxPosition) {
            maxPosition = fl.position;
          }
        }
      });
      floor.position = maxPosition + 1;
    }
    floor.name = this.floorName;
    floor.listOfRooms = [];
    floor.apartmentId = this.floorForApartment;
    this.project.listOfFloors.push(floor);

    this.apiProjectService.setProject(this.project).subscribe();
  }

  updateFloor(floor: Floor) {
    this.apiProjectService.setProject(this.project).subscribe();
  }

  deleteFloor(id: string) {
    this.project.listOfFloors = this.project.listOfFloors.filter( floor => floor.id !== id);
    this.apiProjectService.setProject(this.project).subscribe();
  }


  decisionClick(choice: boolean) {
    const hasProperties = this.content?.equipmentProperties?.length > 0;
    let allEquipPropsHaveNames: boolean;
    if (hasProperties) {
      allEquipPropsHaveNames = this.content.equipmentProperties.every((prop) => {
        return prop.name;
     });
    }
    if (choice === false) {
    } else if ((choice === true && this.name && !hasProperties)
    || (choice === true && this.name && hasProperties && allEquipPropsHaveNames)) {
        this.content.name = this.name;
        this.project[this.type][this.index] = this.content;
        this.projectService.setProject(this.project);
        this.apiProjectService.setProject(this.project).subscribe();
    }
  }

  decisionClickForModal(choice: boolean) {
    const hasProperties = this.content?.equipmentProperties?.length > 0;
    let allEquipPropsHaveNames: boolean;
    if (hasProperties) {
      allEquipPropsHaveNames = this.content.equipmentProperties.every((prop) => {
        return prop.name;
     });
    }
    if (choice === false) {
      this.modalController.dismiss(choice);
    } else if ((choice === true && this.name && !hasProperties)
    || (choice === true && this.name && hasProperties && allEquipPropsHaveNames)) {
      this.content.name = this.name;
      this.project[this.type][this.index] = this.content;
      this.projectService.setProject(this.project);
      if(this.modalController.getTop()) {
      }
    }
  }

  updateGroup(group: any) {
    //console.log(group);
    switch (this.type) {
      case 'listOfBuildingGroups':
        this.apiProjectService.updateBuildingsGroup(group).subscribe();
        this.project.listOfBuildings.forEach(element => {
          if (element.listOfBuildingGroups.includes(group.id)) {
            const index = element.listOfBuildingGroups.indexOf(group.id);
            element.listOfBuildingGroups.splice(index, 1);
          }

          group.listOfBuildings.forEach(value => {
            if (value === element.id) {
              element.listOfBuildingGroups.push(group.id);
            }
          });
        });
        this.projectService.setProject(this.project);
        break;
      case 'listOfApartmentGroups':
        this.apiProjectService.updateApartmentsGroup(group).subscribe();
        this.project.listOfApartments.forEach(element => {
          if (element.listOfApartmentGroups.includes(group.id)) {
            const index = element.listOfApartmentGroups.indexOf(group.id);
            element.listOfApartmentGroups.splice(index, 1);
          }

          group.listOfApartments.forEach(value => {
            if (value === element.id) {
              element.listOfApartmentGroups.push(group.id);
            }
          });
        });
        this.projectService.setProject(this.project);
        break;
      case 'listOfRoomGroups':
        this.apiProjectService.updateRoomsGroup(group).subscribe();
        this.project.listOfRooms.forEach(element => {
          if (element.listOfRoomGroups?.includes(group.id)) {
            const index = element.listOfRoomGroups.indexOf(group.id);
            element.listOfRoomGroups.splice(index, 1);
          }

          group.listOfRooms.forEach(value => {
            if (value === element.id) {
              element.listOfRoomGroups.push(group.id);
            }
          });
        });
        this.projectService.setProject(this.project);
        break;
      case 'listOfBlindsGroups':
        this.apiProjectService.updateBlindsGroup(group).subscribe();
        this.project.listOfBlinds.forEach(element => {
          if (element.listOfBlindsGroups.includes(group.id)) {
            const index = element.listOfBlindsGroups.indexOf(group.id);
            element.listOfBlindsGroups.splice(index, 1);
          }

          group.listOfBlinds.forEach(value => {
            if (value === element.id) {
              element.listOfBlindsGroups.push(group.id);
            }
          });
        });
        this.projectService.setProject(this.project);
        break;
      case 'listOfGeneralEquipmentGroup':
        this.apiProjectService.updateGeneralEquipmentsGroup(group).subscribe();
        this.project.listOfGeneralEquipment.forEach(element => {
          if (element.listOfGeneralEquipmentGroups.includes(group.id)) {
            const index = element.listOfGeneralEquipmentGroups.indexOf(group.id);
            element.listOfGeneralEquipmentGroups.splice(index, 1);
          }

          group.listOfGeneralEquipment.forEach(value => {
            if (value === element.id) {
              element.listOfGeneralEquipmentGroups.push(group.id);
            }
          });
        });
        this.projectService.setProject(this.project);
        break;
      case 'listOfHvacGroups':
        this.apiProjectService.updateHvacsGroup(group).subscribe();
        this.project.listOfHvacs.forEach(element => {
          if (element.listOfHvacGroups.includes(group.id)) {
            const index = element.listOfHvacGroups.indexOf(group.id);
            element.listOfHvacGroups.splice(index, 1);
          }

          group.listOfHvac.forEach(value => {
            if (value === element.id) {
              element.listOfHvacGroups.push(group.id);
            }
          });
        });
        this.projectService.setProject(this.project);
        break;
      case 'listOfLightGroups':
        this.apiProjectService.updateLightsGroup(group).subscribe();
        this.project.listOfLights.forEach(element => {
          if (element.listOfLightGroups.includes(group.id)) {
            const index = element.listOfLightGroups.indexOf(group.id);
            element.listOfLightGroups.splice(index, 1);
          }

          group.listOfLight.forEach(value => {
            if (value === element.id) {
              element.listOfLightGroups.push(group.id);
            }
          });
        });
        this.projectService.setProject(this.project);
        break;
      default:
        break;
    }
  }

  deleteGroup(id: string) {
    switch (this.type) {
      case 'listOfBuildingGroups': {
        this.apiProjectService.deleteBuildingsGroup(id).subscribe(() => {
          this.project = JSON.parse(JSON.stringify(this.projectService.getProject()));
        });
        this.project.listOfBuildings.forEach(element => {
          element.listOfBuildingGroups = element.listOfBuildingGroups.filter(value => value !== id);
        });
        break;
      }
      case 'listOfApartmentGroups': {
        this.apiProjectService.deleteApartmentsGroup(id).subscribe(() => {
          this.project = JSON.parse(JSON.stringify(this.projectService.getProject()));
        });
        this.project.listOfApartments.forEach(element => {
          element.listOfApartmentGroups = element.listOfApartmentGroups.filter(value => value !== id);
        });
        break;
      }
      case 'listOfRoomGroups': {
        this.apiProjectService.deleteRoomsGroup(id).subscribe(() => {
          this.project = JSON.parse(JSON.stringify(this.projectService.getProject()));
        });
        this.project.listOfRooms.forEach(element => {
          element.listOfRoomGroups = element.listOfRoomGroups.filter(value => value !== id);
        });
        break;
      }
      case 'listOfBlindsGroups': {
        this.apiProjectService.deleteBlindsGroup(id).subscribe(() => {
          this.project = JSON.parse(JSON.stringify(this.projectService.getProject()));
        });
        this.project.listOfBlinds.forEach(element => {
          element.listOfBlindsGroups = element.listOfBlindsGroups.filter(value => value !== id);
        });
        break;
      }
      case 'listOfGeneralEquipmentGroup': {
        this.apiProjectService.deleteGeneralEquipmentsGroup(id).subscribe(() => {
          this.project = JSON.parse(JSON.stringify(this.projectService.getProject()));
        });
        this.project.listOfGeneralEquipment.forEach(element => {
          element.listOfGeneralEquipmentGroups = element.listOfGeneralEquipmentGroups.filter(value => value !== id);
        });
        break;
      }
      case 'listOfHvacGroups': {
        this.apiProjectService.deleteHvacsGroup(id).subscribe(() => {
          this.project = JSON.parse(JSON.stringify(this.projectService.getProject()));
        });
        this.project.listOfHvacs.forEach(element => {
          element.listOfHvacGroups = element.listOfHvacGroups.filter(value => value !== id);
        });
        break;
      }
      case 'listOfLightGroups': {
        this.apiProjectService.deleteLightsGroup(id).subscribe(() => {
          this.project = JSON.parse(JSON.stringify(this.projectService.getProject()));
        });
        this.project.listOfLights.forEach(element => {
          element.listOfLightGroups = element.listOfLightGroups.filter(value => value !== id);
        });
        break;
      }
      default:
        break;
    }
  }

  showThisProp(id: string) {
    if (this.showId.includes(id)) {
      return true;
    } else {
      return false;
    }
  }

  addIdToShowArray(id: string) {
    if (this.showId.includes(id)) {
      const index = this.showId.indexOf(id);
      this.showId.splice(index, 1);
    } else {
      this.showId.push(id);
    }
  }

  getGroupName(id: string, targetList: string) {
    const target = this.project[targetList].find(element => element.id === id);
    return target.name;
  }
  elementName(id: string, targetList: string) {
    const target = this.project[targetList].find(element => element.id === id);
    return target.name;
  }

  addToGroup(group: any, id: string, arrayName: string) {
    group[arrayName].push(id);
  }

  removeFromGroup(group: any, id: string, arrayName: string) {
    const index = group[arrayName].indexOf(id);
    group[arrayName].splice(index, 1);
  }

  addTofloor(floor: Floor, id: string) {
    const targetRoom = this.project.listOfRooms.find( room => room.id === id);
    targetRoom.floorId = floor.id;
    floor.listOfRooms.push(id);
  }

  removeFromFloor(floor: Floor, id: string) {
    const targetRoom = this.project.listOfRooms.find( room => room.id === id);
    targetRoom.floorId = null;
    floor.listOfRooms = floor.listOfRooms.filter( room => room !== id);
  }

  getRoomsForFloor(floor: Floor) {
    const roomsList = this.project.listOfRooms.filter( room => {
      const index = room.id.indexOf('.');
      if (room.apartmentId === floor.apartmentId) { // ovdje
        return true;
      }
    });

    return roomsList;
  }

  addComma(index: number, length: number) {
    if (length === 1) {
      return '';
    }
    if (index === length - 1) {
      return '';
    }
    return ',';
  }

  addComma2(firstLength: number, secondLength: number) {
    const numOfCommas = firstLength - secondLength;
    if (this.commaCounter < numOfCommas) {
      this.commaCounter++;
      return ',';
    } else {
      this.commaCounter = 1;
      return '';
    }
  }

  ngOnDestroy(): void {
    //this.decisionClick(true);
  }
}
