import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { UserService } from './../services/user.service';
import { User } from 'src/app/core/models/user/user.model';
import { AuthenticationService } from '../authentication/authentication.service';
import { MessagingService } from 'src/app/core/services/messaging.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class UserPermissionGuard  {

    constructor(private userService: UserService,
                private authService: AuthenticationService,
                private messagingService: MessagingService,
                private router: Router) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        let user = this.userService.getUser();
        if (user === undefined) {
            if (this.authService.isValid()) {
                await lastValueFrom(this.authService.getUserByToken()).then(u => {user = u; }).catch(err => {});
            } else {
                if (!this.authService.getAccessToken() && !this.authService.getRefreshToken()) {
                    this.messagingService.setMessage('Access denied. Please login');
                    return false;
                }
                if (!this.authService.refreshTokenInProgress) {
                    this.authService.setRefreshTokenInProgress(true);
                    await lastValueFrom(this.authService.refreshAccessToken()).then(r => user = r.user).catch(() => {});
                    this.authService.setRefreshTokenInProgress(false);
                }
                await lastValueFrom(this.authService.refreshChanged.pipe(take(2))).then(val => {
                    user = this.userService.getUser(); }).catch(err => {});
            }
            return this.validate(user, state);
        } else {
            return this.validate(user, state);
        }
    }

    validate(user: User, state: RouterStateSnapshot) {
        const splitedUrl = state.url.split('/');
        splitedUrl.shift();
        if (this.userContains(user, splitedUrl)) {
            return true;
        } else {
            this.router.navigateByUrl('/home');
            return false;
        }
    }

    userContains(user: User, wantedRoute: string[]) {
        switch (wantedRoute[0]) {
            case 'users' : {
                if (User.isAdmin(user) || User.isOwner(user)) {
                    return true;
                } else if (User.isGuest(user)){
                    return user._id === wantedRoute[1];
                }
                break;
            }
            case 'project' : {
                if (User.isAdmin(user)) {
                    return true;
                }
                break;
            }
            case 'logs' : {
                if (User.isAdmin(user) || User.isOwner(user)) {
                    return true;
                }
                break;
            }
            case 'settings' : {
                //if (User.isAdmin(user) || User.isOwner(user)) {
                    return true;
                // }
                //break;
            }
            case 'rooms' : {
                return true;

            }
            case 'automation' : {
                    return true;
            }
            case 'scenes' : {
                  return true;
          }
            case 'modes' : {
                    return true;
            }
            case 'admin-settings' : {
                if (User.isAdmin(user) || User.isOwner(user)) {
                    return true;
                }
                break;
            }
            case 'active-devices' : {
                return true;
            }
            case 'alarms' : {
                if (User.isAdmin(user) || User.isOwner(user)) {
                    return true;
                }
                break;
            }
            case 'doors' : {
              if (User.isAdmin(user) || User.isOwner(user)) {
                return true;
              }
            }
            case 'network-error' : {
              return true;
          }
          default: {
            return false;
          }
        }
    }

}
