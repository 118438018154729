import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Action } from 'src/app/core/models/automation/action.model';
import { Condition } from 'src/app/core/models/automation/condition.model';
import { Light } from 'src/app/core/models/project/devices/light.model';
import { LightsGroup } from 'src/app/core/models/project/groups/lights-group';
import { DaliSceneElement } from 'src/app/core/models/scenes/dali-scene-element.model';
import { User } from 'src/app/core/models/user/user.model';
import { UserService } from 'src/app/core/services/user.service';
import { DaliProjectService } from 'src/app/modules/project/services/dali-project.service';
import { ApiDaliProjectService } from 'src/app/modules/project/services/http/api-dali-project.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { USE_DEVICE_VISIBLE_TO_USER } from 'src/environments/environment';

@Component({
  selector: 'app-dimmable-light-group',
  templateUrl: './dimmable-light-group.component.html',
  styleUrls: ['./dimmable-light-group.component.scss']
})
export class DimmableLightGroupComponent implements OnInit, OnDestroy {
  @Input() daliLightsGroup: LightsGroup;
  @Input() mode: string;
  @Input() daliSceneElement: DaliSceneElement;

  @Input() set daliCondition(condition: Condition) {
    if (condition) {
      this.edit = true;
      this.condition = condition
      this.deviceCondition$.emit(condition);
    }
  }

  @Input() set daliAction(action: Action) {
    if (action) {
      this.edit = true;
      this.action = action;
      this.deviceAction$.emit(this.action);
    }
  }


  whiteValue = 50;
  sliderValues = new BehaviorSubject(null);
  sliderSubscription: Subscription;
  inputDelay = 1000;
  edit = false;

  @Output() deviceAction$: EventEmitter<Action> = new EventEmitter();
  @Output() deviceCondition$: EventEmitter<Condition> = new EventEmitter();

  action: Action = new Action();
  condition: Condition = new Condition();

  loadingSubscription: Subscription;
  loadingElements = [];
  daliProject = this.daliProjectService.getProject();
  daliProjectSubscription: Subscription
  daliDimmableLightsInGroup: Light[] = [];
  daliNormalLightsInGroup: Light[] = [];

  USE_DEVICE_VISIBLE_TO_USER = USE_DEVICE_VISIBLE_TO_USER;
  user: User;

  sliderOptions = {
    floor : 0,
    ceil : 100
  };

  @Output() newLightGroupDimmValue: EventEmitter<number> = new EventEmitter();

  constructor(
    private apiDaliProjectService: ApiDaliProjectService,
    private daliProjectService: DaliProjectService,
    private loadingService: LoadingService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.loadingSub();
    this.getDaliProject()
    // console.log(this.daliLightsGroup)
    // console.log(this.daliDimmableLightsInGroup)
    // console.log(this.daliNormalLightsInGroup)
    this.user = this.userService.getUser();
    /* this.sliderSubscription = this.sliderValues.pipe(
      debounceTime(this.inputDelay),
      distinctUntilChanged(),
    ).subscribe((newSliderValue) => {
      if (newSliderValue !== null) {
        this.loadingService.addToLoading(this.daliLightsGroup.id);
        this.loadingService.removeFromLoading(this.daliLightsGroup.id);
        this.apiDaliProjectService.activateDaliLightsGroup(this.daliLightsGroup.id, newSliderValue).subscribe();
      }
  }); */
    if (this.mode === 'live') {
    this.loadingSub();
    this.sliderSubscription = this.sliderValues.pipe(
      debounceTime(this.inputDelay),
      distinctUntilChanged(),
    ).subscribe((newSliderValue) => {
      if (newSliderValue !== null) {
        this.loadingService.addToLoading(this.daliLightsGroup.id);
        this.loadingService.removeFromLoading(this.daliLightsGroup.id);
        this.apiDaliProjectService.activateDaliLightsGroup(this.daliLightsGroup.id, newSliderValue).subscribe();
      }
  });
  }
    if (this.mode === 'editScene') {
    this.whiteValue = Number(this.daliSceneElement.value);
    this.daliLightsGroup = JSON.parse(JSON.stringify(this.daliLightsGroup));
  }
    if (this.mode === 'action' && !this.edit) {
    this.action.id = 'ca0017';
    this.action.name = 'Activate DALI group';
    this.action.description = '';
    this.action.command = 'DaliActions.ActivateGroup';
    this.action.parameters = [`${this.daliLightsGroup.id}`, `${this.whiteValue}`];
    this.action.builtIn = true;
    this.deviceAction$.emit(this.action);
    } else if (this.mode === 'condition' && !this.edit) {
      this.condition.automationConditionType = 'simple';
      this.condition.type = 4; // equal or greather then
      this.condition.parameter1 = `ValRef:${this.daliLightsGroup.id}`;
      this.condition.parameter2 = 0;
      this.condition.parameter3 = 0; // is ignored in on off (binary) component
      this.condition.editable = false;
      this.condition.parameter1Editable = false;
      this.condition.parameter2Editable = false;
      this.condition.parameter3Editable = false;
  }

    if (this.mode === 'action' && this.edit) {
    this.whiteValue = this.action.parameters[1];
  }
}

getDaliProject() {
  this.getDimmableDaliLightsForRoom();
  this.getNormalDaliLightsForRoom()
  this.daliProjectSubscription = this.daliProjectService.daliProjectChanged.subscribe(() => {
    this.daliProject = this.daliProjectService.getProject();
    this.getDimmableDaliLightsForRoom();
    this.getNormalDaliLightsForRoom()
  });
}

  minusClicked() {
    if (this.whiteValue > 0 && (this.mode === 'live' || this.mode === 'editScene')) {
      this.whiteValue--;
    } else if (this.mode === 'action') {
      this.whiteValue--;
      this.action.parameters[1] = `${this.whiteValue}`;
      this.deviceAction$.emit(this.action);
    }
  }

  plusClicked() {
    if (this.whiteValue < 100 && (this.mode === 'live' || this.mode === 'editScene')) {
      this.whiteValue++;
    } else if (this.mode === 'action') {
      this.whiteValue++;
      this.action.parameters[1] = `${this.whiteValue}`;
      this.deviceAction$.emit(this.action);
    }
  }

  onInputChanged(rangeValue) {
    if (this.mode === 'live' || this.mode === 'editScene') {
      this.whiteValue = rangeValue;
      this.sliderValues.next( this.whiteValue);
    } else if (this.mode === 'action') {
      this.whiteValue = rangeValue;
      this.action.parameters[1] = `${this.whiteValue}`;
      this.deviceAction$.emit(this.action);
    }
  }

  daliLightGroupOnStatus() {
    if ((this.daliLightsGroup.value > 0 && this.daliLightsGroup.value <= 100) || this.daliLightsGroup.value == 200) {
      return 'orange'
    } else {
      return 'ocean-blue'
    }
  }

  daliLightGroupOffStatus() {
    if (this.daliLightsGroup.value == 0 || this.daliLightsGroup.value == 200) {
      return 'orange'
    } else {
      return 'ocean-blue'
    }
  }

  toggleDaliLightGroupOn() {
    if (this.mode === 'live') {
      this.whiteValue = 100;
      this.apiDaliProjectService.activateDaliLightsGroup(this.daliLightsGroup.id, this.whiteValue).subscribe();
    } else if (this.mode === 'action') {
      this.whiteValue = 100;
      this.action.parameters[1] = `${this.whiteValue}`;
      this.deviceAction$.emit(this.action);
    }
  }

  toggleDaliLightGroupOff() {
    if (this.mode === 'live') {
      this.whiteValue = 0;
      this.apiDaliProjectService.activateDaliLightsGroup(this.daliLightsGroup.id, this.whiteValue).subscribe();
    } else if (this.mode === 'action') {
      this.whiteValue = 0;
      this.action.parameters[1] = `${this.whiteValue}`;
      this.deviceAction$.emit(this.action);
    }
  }

  getDimmableDaliLightsForRoom() {
      this.daliDimmableLightsInGroup = this.daliProject?.listOfLights.filter((el: Light) => {
          return this.daliLightsGroup.listOfLightIds.some( id => id == el.id && Light.isDimmableDaliLight(el))
        });
  }

  getNormalDaliLightsForRoom() {
    this.daliNormalLightsInGroup = this.daliProject?.listOfLights.filter((el: Light) => {
      return this.daliLightsGroup.listOfLightIds.some( id => id == el.id && Light.isNormalDaliLight(el))
    });
  }

  loadingElement(id: string) {
    return this.loadingElements.includes(id);
  }

  toggleDaliLight(daliLight: Light, event) {
    event.stopPropagation();
      if (Number(daliLight.equipmentProperties[0].value) === 0) {
        this.apiDaliProjectService.activateDaliLight(daliLight.id, 100).subscribe( responce => {
        });
      } else if (Number(daliLight.equipmentProperties[0].value) > 0) {
        this.apiDaliProjectService.activateDaliLight(daliLight.id, 0).subscribe();
      }

  }

  handleBinaryChange(event) {
    if (this.mode === 'condition') {
      this.condition.parameter2 = event.target.value;
      this.deviceCondition$.emit(this.condition);
    }
  }

  loadingSub() {
    this.loadingElements = this.loadingService.getLoadingElements();
    this.loadingSubscription = this.loadingService.loadingChanged.subscribe( response => {
      this.loadingElements = this.loadingService.getLoadingElements();
    });
  }

  onAccept() {
    this.newLightGroupDimmValue.emit(this.whiteValue);
  }


  ngOnDestroy() {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
    if (this.daliProjectSubscription) {
      this.daliProjectSubscription.unsubscribe();
    }
    setTimeout(() => {
      if (this.sliderSubscription) {
        this.sliderSubscription.unsubscribe();
      }
    }, this.inputDelay);

  }

}
