import { Pipe, PipeTransform } from '@angular/core';
import { Floor } from 'src/app/core/models/project/floor.model';
import { Room } from 'src/app/core/models/project/room.model';

@Pipe({
  name: 'filterRoomsByFloor'
})
export class FilterRoomsByFloorPipe implements PipeTransform {

  transform(rooms: Room[], floor: Floor): Room[] {
    if (!rooms) {
      return [];
    }
    if (!floor) {
      return rooms;
    }
    return rooms.filter((room: Room) => {
      return floor.listOfRooms.includes(room.id);
    });
  }
}
