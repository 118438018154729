import { Injectable } from '@angular/core';
import { UserSettings } from 'src/app/core/models/user-settings.model';
import { UserLanguage } from 'src/app/core/models/user-language.model';
import { Subject } from 'rxjs';


interface ColorsSelected {
  userID: string;
  selectedColors: string[];
}

@Injectable({
  providedIn: 'root'
})

export class UserSettingsService {

  scrollwatch$ = new Subject<boolean>()
  last10SelectedColors: ColorsSelected[] = [{userID: null, selectedColors: []}];
  defaultUserSettings: UserSettings = {
    roomViewType: 'allDevices'
  };

  defaultUserLanguage: UserLanguage = {
    language: 'en'
  };

  constructor() {}

  getLast10UsedColors(userId: string):  ColorsSelected{
    const userSettingsStorage: string = localStorage.getItem('last_10_used_colors');
    if (userSettingsStorage) {
      const abasUserSettings: ColorsSelected[] = JSON.parse(userSettingsStorage);
      const targetUserColors = abasUserSettings.find( (element: ColorsSelected) => element.userID == userId)
      if (targetUserColors) {
        return targetUserColors;
      } else {
        const newUser: ColorsSelected = {userID: userId, selectedColors: ['#ff0000',
        '#00ff00',
        '#0000ff',
        '#ffa500',
        '#ffff00',
        '#800080',
        '#ffc0cb',
        '#ffffff']}
        abasUserSettings.push(newUser)
        localStorage.setItem('last_10_used_colors', JSON.stringify(abasUserSettings))
        return newUser;
      }
    } else {
      this.last10SelectedColors[0].userID = userId;
      this.last10SelectedColors[0].selectedColors = ['#ff0000',
      '#00ff00',
      '#0000ff',
      '#ffa500',
      '#ffff00',
      '#800080',
      '#ffc0cb',
      '#ffffff']
      localStorage.setItem('last_10_used_colors', JSON.stringify(this.last10SelectedColors));
      return this.last10SelectedColors[0];
    }
  }

  setColor(userId: string, color: string) {
    const userSettingsStorage: string = localStorage.getItem('last_10_used_colors');
    if (userSettingsStorage) {
      const abasUserSettings: ColorsSelected[] = JSON.parse(userSettingsStorage);
      const targetUserColors = abasUserSettings.find( (element: ColorsSelected) => element.userID == userId)
      if (!targetUserColors.selectedColors.includes(color)) {
        if (targetUserColors) {
          if (targetUserColors.selectedColors.length < 10) {
            targetUserColors.selectedColors.push(color)
          } else {
            targetUserColors.selectedColors.shift()
            targetUserColors.selectedColors.push(color)
          }
        } 
      }
      localStorage.setItem('last_10_used_colors', JSON.stringify(abasUserSettings))
    } 
  }

  getRoomViewTypeSetting(): 'allDevices' | 'rooms' {
    const userSettingsStorage: string = localStorage.getItem('abas_user_settings');
    if (userSettingsStorage) {
      const abasUserSettings: UserSettings = JSON.parse(userSettingsStorage);
      return abasUserSettings.roomViewType;
    } else {
      localStorage.setItem('abas_user_settings', JSON.stringify(this.defaultUserSettings));
      return this.defaultUserSettings.roomViewType;
    }
  }

  setRoomViewTypeSetting(newViewType: 'allDevices' | 'rooms') {
    const userSettingsStorage: string = localStorage.getItem('abas_user_settings');
    if (userSettingsStorage) {
      const newAbasUserSettings: UserSettings = JSON.parse(userSettingsStorage);
      newAbasUserSettings.roomViewType = newViewType;
      localStorage.setItem('abas_user_settings', JSON.stringify(newAbasUserSettings));
    } else {
      const newAbasUserSettings: UserSettings = this.defaultUserSettings;
      newAbasUserSettings.roomViewType = newViewType;
      localStorage.setItem('abas_user_settings', JSON.stringify(newAbasUserSettings));
    }
  }


  getLanguage(): 'en' | 'hr' {
    const userLanguage: string = localStorage.getItem('abas_user_language');
    if (userLanguage) {
      const abasUserLanguage: UserLanguage = JSON.parse(userLanguage);
      return abasUserLanguage.language;
    } else {
      localStorage.setItem('abas_user_language', JSON.stringify(this.defaultUserLanguage));
      return this.defaultUserLanguage.language;
    }
  }

  setLanguage(newLanguage: 'en' | 'hr') {
    const userLanguage: string = localStorage.getItem('abas_user_language');
    if (userLanguage) {
      const newUserLanguage: UserLanguage = JSON.parse(userLanguage);
      newUserLanguage.language = newLanguage;
      localStorage.setItem('abas_user_language', JSON.stringify(newUserLanguage));
    } else {
      const newUserLanguage: UserLanguage = this.defaultUserLanguage;
      newUserLanguage.language = newLanguage;
      localStorage.setItem('abas_user_language', JSON.stringify(newUserLanguage));
    }
  }

}
