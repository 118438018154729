<div *ngIf="mode === 'live' && propIsBlinds === true">
 <!--   BLINDS ORDER IS REVERSED ON DRIVER
        SO IT IS REVERSED ON FRONTEND TO MATCH -->
  <div class="flex-column-center margin-top50">
    <div (click)="specialPropClicked(propertyArray[1])" class="blinds-prop margin-bottom10">
      <div>
        {{ 'blinds_up' | translate }}
      </div>
      <div  *ngIf="!loadingElement(parentDevice.id + propertyArray[1].id.toString())" class="i-blinds-up-big margin-top5 margin-bottom5"></div>
        <div *ngIf="loadingElement(parentDevice.id + propertyArray[1].id.toString())"  class="lds-spinner margin-top5 margin-bottom5"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        <div>
          {{ (+propertyArray[1].value === 0 ? propertyArray[1].data.nameForFalse : propertyArray[1].data.nameForTrue) | genComTranslate}}
        </div>
    </div>

    <div (click)="specialPropClicked(propertyArray[0])" class="blinds-prop">
      <div>
        {{ 'blinds_down' | translate }}
      </div>
      <div *ngIf="!loadingElement(parentDevice.id + propertyArray[0].id.toString())" class="i-blinds-down-big margin-top5 margin-bottom5"> </div>
      <div *ngIf="loadingElement(parentDevice.id + propertyArray[0].id.toString())"  class="lds-spinner margin-top5 margin-bottom5"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      <div>
        {{ (+propertyArray[0].value === 0 ? propertyArray[0].data.nameForFalse : propertyArray[0].data.nameForTrue) | genComTranslate}}
      </div>
    </div>
  </div>
</div>

<div *ngIf="mode === 'live' && propIsBlinds === false && propIsHvacControl === false" class="equip-prop-container" [ngClass]="activeProperty?.data.icon" (click)="propertyClicked()">
  <div>
  {{activeProperty.name}}
  </div>
  <div class="blue-text">
    {{ (+activeProperty?.value === 0 ? activeProperty?.data.nameForFalse : activeProperty?.data.nameForTrue) | genComTranslate}}
  </div>
</div>

<!-- HTML for hvac mutex  -->
<div *ngIf="mode === 'live' && propIsHvacControl === true" >
  <ion-item class="margin-top10 margin-bottom40">
    <ion-segment [ngModel]="activeProperty" color="orange" mode="md">
      <ion-segment-button *ngFor="let prop of propertyArray" [value]="prop" (click)="specialPropClicked(prop)">
        <ion-label class="ion-text-wrap" >{{ prop.name}}</ion-label>
      </ion-segment-button>
    </ion-segment>
</ion-item>
</div>


<div *ngIf="mode === 'action'">
  <!-- <div class="flex-column">
    <div class="label">{{ title }}</div>
  </div> -->
   <ion-radio-group [value]="action?.parameters[0]?.split('/')[1]">
      <ion-item
        *ngFor="let property of propertyArray | reverseListIfBlinds; let i = index" lines="none">
        <ion-radio
        labelPlacement="end"
        slot="start"
        class="color-radio margin-right40"
        [value]="property.type.toString()"
        (click)="handleActionChange(property)">{{ property.name }}</ion-radio>
      </ion-item>
    </ion-radio-group>
</div>

<div *ngIf="mode === 'condition'" class="flex-column">
    <!-- <div class="flex-column">
      <div class="label">{{ title }}</div>
    </div> -->
     <ion-radio-group [value]="condition?.parameter1?.split('/')[1]">
     <ion-item
          *ngFor="let property of propertyArray; let i = index" lines="none">
          <ion-radio
          labelPlacement="end"
          slot="start"
          class="color-radio margin-right40"
          [value]="property.type.toString()"
          (click)="handleConditionChange(property)">{{ property.name }}</ion-radio>
        </ion-item>
      </ion-radio-group>

</div>

