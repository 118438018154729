import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DaliProject } from 'src/app/core/models/project/dali-project.model';
import { DaliProjectService } from 'src/app/modules/project/services/dali-project.service';
import { Color } from 'src/app/core/models/scenes/color.model';
import { DaliSceneElement } from 'src/app/core/models/scenes/dali-scene-element.model';
import { ApiDaliProjectService } from 'src/app/modules/project/services/http/api-dali-project.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { Project } from 'src/app/core/models/project/project.model';
import { Room } from 'src/app/core/models/project/room.model';

@Component({
  selector: 'app-modal-dali-project',
  templateUrl: './modal-dali-project.component.html',
  styleUrls: ['./modal-dali-project.component.scss']
})
export class ModalDaliProjectComponent implements OnInit {
  @Input() type: string;
  @Input() modalContent: any;
  @Input() index: number;
  content: any;
  picker1Opened = false;
  picker2Opened = false;
  selectedColorRgb1: string;
  selectedColorRgb2: string;

  showId = '';
  showIds: Array<string> = [];
  name: string;
  daliProject: DaliProject;
  project: Project = this.projectService.getProject();
  submitted = false;

  sliderOptions = {
    floor : 0,
    ceil : 100
  };

  constructor(
    private daliProjectService: DaliProjectService,
    private apiDaliProjectService: ApiDaliProjectService,
    private projectService: ProjectService) { }

  ngOnInit(): void {
    if (this.modalContent) {
      this.content = JSON.parse(JSON.stringify(this.modalContent));
    }
    this.name = this.content?.name;
    this.daliProject = JSON.parse(JSON.stringify(this.daliProjectService.getProject()));
  }

  addComma(index: number, length: number) {
    if (length === 1) {
      return '';
    }
    else if (index === length - 1) {
      return '';
    } else {
      return ',';
    }
  }

  toggleShowProp(id: string) {
    if (this.showId === id) {
      this.showId = '';
    } else {
      this.showId = id;
    }
  }

  showThisProp(id: string) {
    return this.showId.toString() === id.toString();
  }

  toggleShowElement(element: DaliSceneElement) {
    this.picker1Opened = false;
    this.picker2Opened = false;
    if (this.showId === element.id.toString()) {
      this.showId = '';
    } else {
      this.showId = element.id.toString();
      this.selectedColorRgb1 = `rgb(${element.color.red},${element.color.green},${element.color.blue})`;
      this.selectedColorRgb2 = `rgb(${element.color2.red},${element.color2.green},${element.color2.blue})`;
    }
  }

  updatePicker1(element: DaliSceneElement, event: string) {
    this.selectedColorRgb1 = event;
    const rgb = event.split('(')[1].split(')')[0].split(',');
    const red = rgb[0];
    const green = rgb[1];
    const blue = rgb[2];
    element.color.red = Number(red);
    element.color.green = Number(green);
    element.color.blue = Number(blue);
  }

  updatePicker2(element: DaliSceneElement, event: string) {
    this.selectedColorRgb2 = event;
    const rgb = event.split('(')[1].split(')')[0].split(',');
    const red = rgb[0];
    const green = rgb[1];
    const blue = rgb[2];
    element.color2.red = Number(red);
    element.color2.green = Number(green);
    element.color2.blue = Number(blue);
  }

  decisionClick(choice: boolean) {
    const hasProperties = this.content?.equipmentProperties?.length > 0;
    let allEquipPropsHaveNames: boolean;
    if (hasProperties) {
      allEquipPropsHaveNames = this.content.equipmentProperties.every((prop) => {
        return prop.name;
     });
    }
    if (choice === false) {
      // this.modalController.dismiss();
    }  else if ((choice === true && this.name && !hasProperties)
    || (choice === true && this.name && hasProperties && allEquipPropsHaveNames)) {
        this.content.name = this.name;
        this.daliProject[this.type][this.index] = this.content;
        this.submitted = true;
        this.apiDaliProjectService.setProject(this.daliProject).subscribe(() => {
          // this.modalController.dismiss();
        });


    }
  }

  getColor(col: Color) {
    return `rgb(${col.red},${col.green},${col.blue})`;
  }

  toggleColorPicker1(event) {
    event.stopPropagation();
    this.picker1Opened = !this.picker1Opened;
  }

  toggleColorPicker2(event) {
    event.stopPropagation();
    this.picker2Opened = !this.picker2Opened;
  }


  addToListOfApartmentIds( room: Room) {
    this.content.listOfLocationIds.push(room.id);
  }

  removeLocationId( roomId: string) {
    this.content.listOfLocationIds = this.content.listOfLocationIds.filter((id: string) => id !== roomId);
  }


}
