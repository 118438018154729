<div class="content-header flex-row-center-vertical no-wrap" >
  <div class="i-back-arrow" (click)="goBack()"></div>
  <h1 class="ellipsis middle">{{room?.name | uppercase}} </h1>
  <div
  *ngIf="deviceIsHvac && !demo"
  class="margin-top10 to-right tooltip"
  (click)="graphShowClicked()">
    <i class="i-graph-bl"></i>
  <span class="bottom">{{ 'show' | translate }}<br>{{ 'graph' | translate }}</span>
</div>
</div>

<div *ngIf="deviceIsHvac && bathroomPropertyExists && roomHvacPropertyExists" class="flex-row-space-between">
  <div (click)="hvacLocationChanged('room')" class="tab" [ngClass]="{'tab-selected': hvacLocation==='room'}">{{ (this.room.roomTitle || 'room') | translate }}</div>
  <div (click)="hvacLocationChanged('bathroom')" class="tab" [ngClass]="{'tab-selected': hvacLocation==='bathroom'}">{{bathroomName | genComTranslate}}</div>
</div>



<div [hidden]="hvacLocation !== 'room'">
    <ng-template #hvacControlPropertyContainer></ng-template>
    <ng-template #roomTempPropertyContainer></ng-template>
</div>

<div [hidden]="hvacLocation !== 'bathroom'">
  <ng-template #bathroomTempPropertyContainer></ng-template>
</div>


<div [hidden]="hvacLocation !== 'room'">
  <ng-template #roomEquipmentPropertyContainer></ng-template>
</div>

<div [hidden]="hvacLocation !== 'bathroom'">
  <ng-template #bathroomEquipmentPropertyContainer></ng-template>
</div>

<div>
    <ng-template #equipmentPropertyContainer></ng-template>
</div>

<div class="flex-column margin-top10">
  <div *ngFor="let equipProp of equipmentPropertyFilterForStatus(deviceInSetup)">
      <div class="statuses flex-row">
        <ng-container *ngIf="!propIsFanSpeedMutex(equipProp); else fanSpeedMutex">{{equipProp.name}}</ng-container>
        <ng-template #fanSpeedMutex>{{'Status' | genComTranslate}}</ng-template>
        <!-- {{ propIsFanSpeedMutex(equipProp)?'Status':equipProp.name}} -->
        <div class="margin-right10">:</div>
        <span
          *ngIf="getTextualValue(equipProp) as textValue"
          [ngClass]="{'alarm': textValue==='Alarm'}">
          {{textValue | genComTranslate}}
        </span>
      </div>
  </div>
</div>
<div *ngIf="showGraph" id="graphTarget">
  <div class="flex-row gap5 margin-top10">
    <ion-button color="primary" size="small" (click)="showLast24hours()">24 H</ion-button>
    <ion-button color="primary" size="small" (click)="showLast7days()">7 D</ion-button>
    <ion-button color="primary" size="small" (click)="showLast30days()">30 D</ion-button>
    <ion-button color="primary" size="small" (click)="showCustom()">{{'Custom' | genComTranslate}}</ion-button>
  </div>
  <app-date-time-from-to #dateTimeFromTo (selectedDateTimeFromTo)="setDateTimeFromTo($event)" [defaultTime]="defaultTime" [hidden]="hideCustomTime"></app-date-time-from-to>
  <div *ngIf="graphCreated && !graphLoading">
    <div #graphContainerRef>
      <ngx-charts-line-chart
      [view]="[graphContainerRef.offsetWidth, 400]"
      [scheme]="colorScheme"
      [legend]="legend"
      [legendPosition]="'below'"
      [showXAxisLabel]="true"
      [showYAxisLabel]="false"
      [xAxis]="true"
      [yAxis]="true"
      [xAxisLabel]="xAxisLabel"
      [timeline]="true"
      [results]="multi"
      [autoScale]="true">
    </ngx-charts-line-chart>

    <!-- <ngx-charts-line-chart
      [legend]="true"
      [legendPosition]="LegendPosition.Below"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [xAxis]="true"
      [yAxis]="true"
      [xAxisLabel]="'Time'"
      [yAxisLabel]="'Values'"
      [timeline]="false"
      [results]="tempGraph"
      [autoScale]="true">
    </ngx-charts-line-chart> -->
    </div>
  </div>
</div>

<div *ngIf="showGraph && !graphCreated && !graphLoading">
<h2>{{ 'no_data_for_graph' | translate }}</h2>
</div>

<div *ngIf="showGraph && !graphCreated && graphLoading" class="loading-indicator"><div></div><div></div><div></div><div></div></div>




