import { Subscription, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { timeout } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CardOnHolderService } from './card-on-holder.service';
import { CardStatus } from '../../core/models/card/card-status.model';
import { CardReader } from '../../core/models/card/card-reader.model';
import { CARD_READER_URL } from 'src/environments/environment';
import { API_BASE_URL } from 'src/environments/environment';




export interface CardIdentityInfo {
  uid: string,
  userId:number,
  controllerDesignation: string
}


@Injectable({
    providedIn: 'root'
})

export class CardReaderService {
    cardReaderHwId: string;
    readerTopicSubscriptions = new Subscription();
    cardReaderConnected = false;
    cardReaderConnectedChanged = new Subject<boolean>();
    URLRegex: RegExp;

    constructor(private http: HttpClient,
                private injector: Injector,
                private mqttService: MqttService,
                private cardOnHolderService: CardOnHolderService
                ) {}

    async initialize() {
            this.requestCardReaderHwId();
            this.subscribeToReaderTopics();
    }

    getCardReaderHwId() {
        return this.cardReaderHwId;
    }

    getCardReaderConnected() {
        return this.cardReaderConnected;
    }

    setCardReaderConnected(connected: boolean) {
        this.cardReaderConnected = connected;
        this.cardReaderConnectedChanged.next(this.cardReaderConnected);
    }


    requestCardReaderHwId() {
        this.http.get<CardReader>(CARD_READER_URL).pipe(timeout(500)).subscribe(response => {
            this.cardReaderHwId = response.hwid;
            this.setCardReaderConnected(true);
        });
    }

    getCard() {
        return this.http.get<CardStatus>(CARD_READER_URL + '/card/status');
    }

    subscribeToReaderTopics() {
        this.readerTopicSubscriptions.add(
            this.mqttService
            .observe('dev/+/cardreader').subscribe((message: IMqttMessage) => {
                this.cardReaderMessageReceived(message);
        }));
        this.readerTopicSubscriptions.add(
            this.mqttService
            .observe('dev/+/card').subscribe((message: IMqttMessage) => {
                this.cardMessageReceived(message);
        }));
    }

    cardReaderMessageReceived(message: IMqttMessage) {
        const subtopics = message.topic.split('/');
        const messageString = message.payload.toString();
        const receivedFromHwid = subtopics[1];
        if (messageString === 'connected') {
            if (receivedFromHwid === this.cardReaderHwId) {
                this.setCardReaderConnected(true);
            }
            if (this.cardReaderHwId === undefined) {
                this.requestCardReaderHwId();
            }
        } else if (messageString === 'disconnected' && receivedFromHwid === this.cardReaderHwId) {
            this.setCardReaderConnected(false);
        }
    }

    cardMessageReceived(message: IMqttMessage) {
        const router = this.injector.get(Router);
        const subtopics = message.topic.split('/');
        const receivedFromHwid = subtopics[1];
        const messageString = message.payload.toString();
            if (this.cardReaderHwId === receivedFromHwid && messageString !== '') {
                const messageJson = JSON.parse(messageString);
                // this.openEnterPinForLoginWithCard(messageJson.uid);
                this.cardOnHolderService.setCardUid(messageJson.uid)
                this.cardOnHolderService.setCardOnHolder(true);
            } else {
                this.cardOnHolderService.setCardOnHolder(false);
            }
    }

    unsubscribeFromReaderTopic() {
        this.readerTopicSubscriptions.unsubscribe();
    }

}
