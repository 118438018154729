import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { Property } from 'src/app/core/models/project/property.model';
import { PropertyType } from 'src/app/core/models/project/property-type.model';
import { Action } from 'src/app/core/models/automation/action.model';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { Condition } from 'src/app/core/models/automation/condition.model';
import { ComponentMode } from 'src/app/core/models/equipment-property-component-to-load.model';
import { AdminSettingsService } from 'src/app/modules/admin-settings/admin-settings.service';
import { backgroundFade, slideFromBottom } from 'src/app/shared/animations/animations';
import { ModalController } from '@ionic/angular';
import { EquipmentNumberLiveComponent } from 'src/app/shared/components/equipment/equipment-number-live/equipment-number-live.component';
import { EquipmentNumberSettingsComponent } from 'src/app/shared/components/equipment/equipment-number-settings/equipment-number-settings.component';
import { PinService } from 'src/app/shared/services/pin.service';
import { ModalPinComponent } from 'src/app/shared/components/modal-pin/modal-pin.component';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { DemoService } from 'src/app/core/services/demo.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { GenComTranslatePipe } from 'src/app/shared/pipes/gen-com-translate.pipe';
import { Mode } from 'src/app/core/models/modes/mode.model';
import { ModesService } from 'src/app/modules/modes/services/modes.service';
import { ApiModesService } from 'src/app/modules/modes/services/http/api-modes.service';
import { ApartmentService } from 'src/app/shared/services/apartment.service';


@Component({
  selector: 'app-equipment-number',
  templateUrl: './equipment-number.component.html',
  animations: [slideFromBottom, backgroundFade],
  styleUrls: ['./equipment-number.component.scss']
})
export class EquipmentNumberComponent implements OnInit, OnDestroy {
  @Input() parentDevice: Device;
  @Input() equipmentProperty: Property;
  @Input() equipmentPropertyType: PropertyType;
  @Input() actionInput: Action;
  @Input() conditionInput: Condition;
  @Input() mode: ComponentMode;
  @Input() data;

  @ViewChild('actionInputRef') actionInputRef: ElementRef;

  action: Action = new Action();
  actionChanged$ = new Subject<Action>();
  conditionChanged$ = new Subject<Condition>();

  targetNumber: number;
  tempTargetNumber: number;
  numberSubscription: Subscription;
  numberChanged$ = new Subject<number>();

  condition: Condition;
  showProp = false;

  sliderValues = new BehaviorSubject(0);
  sliderButtonClicked = false;
  sliderLineClicked = false;

  relationalOperators = [{ key: 0, value: 'Equal' },
  { key: 1, value: 'Less' },
  { key: 2, value: 'Greater' },
  { key: 3, value: 'Less or equal' },
  { key: 4, value: 'Greater or equal' },
  { key: 5, value: 'In range' },
  { key: 6, value: 'In range lower included' },
  { key: 7, value: 'In range upper included' },
  { key: 8, value: 'In range both included' }];

  // SLIDER SETTINGS
  options = {
    floor: 0,
    ceil: 50,
  };
  settingValue$: Observable<any>;
  propIdentifier: string; // example 'BL-2/6500'

  apartmentIdSubscription: Subscription;
  modesSubscription: Subscription;
  modes: Mode[];
  apartmentId: string;
  heatingActive: boolean;
  coolingActive: boolean;

  inputTimeout: NodeJS.Timeout;

  constructor(
    private modalController: ModalController,
    private apiProjectService: ApiProjectService,
    private adminSettingsService: AdminSettingsService,
    private pinService: PinService,
    private loadingService: LoadingService,
    private demoService: DemoService,
    private projectService: ProjectService,
    private pipe: GenComTranslatePipe,
    private apiModesService: ApiModesService,
    private modesService: ModesService,
    private apartmentService: ApartmentService) { }

  ngOnInit(): void {
    if (Property.isRoomTemperatureSet(this.equipmentProperty)) {
      this.options.floor = this.parentDevice.roomMin;
      this.options.ceil = this.parentDevice.roomMax;
      if (this.parentDevice.roomMin === this.parentDevice.roomMax) {
        this.options.floor = 0
        this.options.ceil = 30
      }
    }

    if (Property.isBathroomTemperatureSet(this.equipmentProperty)) {
      this.options.floor = this.parentDevice.bathroomMin;
      this.options.ceil = this.parentDevice.bathroomMax;
      if (this.parentDevice.bathroomMin === this.parentDevice.bathroomMax) {
        this.options.floor = 0
        this.options.ceil = 30
      }
    }

    this.propIdentifier = `${this.parentDevice.id}/${this.equipmentProperty.type}`;
    if (this.mode === 'action') {
      this.initActionMode();
    } else if (this.mode === 'live') {
      this.initLiveMode();
    } else if (this.mode === 'condition') {
      if (this.conditionInput) {
        this.condition = JSON.parse(JSON.stringify(this.conditionInput));
      } else {
        this.initConditionMode();
      }
    } else if (this.mode === 'settings') {
      this.settingValue$ = this.adminSettingsService.getSetting(this.propIdentifier);
    }

    this.getModes();
    this.apartmentIdSubscription = this.apartmentService.getApartmentId().subscribe((aptId: string) => {
      this.apartmentId = aptId;
      if (this.modes) {
        this.updateStatuses();
      }
    });
  }

  getModes() {
    this.modes = this.modesService.getModes();
    this.apiModesService.getModes().subscribe();
    this.modesSubscription = this.modesService.modesChanged.subscribe(() => {
      this.modes = this.modesService.getModes();
      if (this.demoService.isDemo()) {
        this.demoService.setModesForDemo(this.modes);
        this.modes = this.demoService.getModesForDemo();
      }
      this.updateStatuses();
    });
  }


  initConditionMode() {
    this.condition = new Condition();
    this.condition.automationConditionType = 'simple';
    this.condition.type = 100; // 100 is only starter value. Must be changed before saving condition
    this.condition.parameter1 = `ValRef:${this.parentDevice.id}/${this.equipmentProperty.type}`;
    this.condition.parameter2 = 0;
    this.condition.parameter3 = 0; // is ignored in on off (binary) component
    this.condition.editable = false;
    this.condition.parameter1Editable = false;
    this.condition.parameter2Editable = false;
    this.condition.parameter3Editable = false;
  }

  getComparatorString(type: number) {
    if (type === 0) {
      return this.pipe.transform('to');
    } else if (type >= 1 && type <= 4) {
      return this.pipe.transform('than');
    } else if (type >= 5 && type <= 8) {
      return this.pipe.transform('from');
    }
  }



  initActionMode() {
    if (!this.actionInput) {
      this.action = this.createNewAction();
    } else {
      this.action = Object.assign(new Action(), this.actionInput);
    }
    this.targetNumber = Number(this.action.parameters[1]);
  }

  initLiveMode() {
    this.sliderValues.next(this.equipmentProperty.value);
    this.targetNumber = this.equipmentProperty.value;
    this.tempTargetNumber = this.equipmentProperty.value;
    this.numberSubscription = this.numberChanged$.subscribe(newNumber => {
      if (this.demoService.isDemo()) {
        this.projectService.changePropertyValueForDemo(this.parentDevice.id, this.equipmentProperty.type, newNumber);
      } else {
        if (this.equipmentProperty.security.requirePinToActivate && !this.pinService.pinIsSet() && this.equipmentProperty.data.showSpecialHTML) {
          this.openPinModalForSpecialHTML();
        } else {
          this.loadingService.addToLoading(this.parentDevice.id + this.equipmentProperty.id.toString());
          if (this.pinService.pinIsSet() && this.equipmentProperty.security.requirePinToActivate) {
              this.apiProjectService.changeProperty(this.parentDevice.id, this.equipmentProperty.type, newNumber.toString(), this.pinService.getPin()).subscribe(() => {
                this.equipmentProperty.value = newNumber;
              });
          } else {
              this.apiProjectService.changeProperty(this.parentDevice.id, this.equipmentProperty.type, newNumber.toString()).subscribe(() => {
                this.equipmentProperty.value = newNumber;
              });
          }
        }
      }
    });
  }

  createNewAction() {
    const newAction = new Action();
    newAction.id = `tempActionId1`; // = this.automationThenId + '|' + this.selectedAction.id; aa0001|ca0001",
    newAction.name = `tempActionName1`;
    newAction.command = 'EquipmentActions.Activate';
    newAction.description = 'Action for activating equipment property';
    newAction.parameters = [this.propIdentifier, undefined];
    newAction.builtIn = false;
    return newAction;
  }

  onInputChanged(inputNumber, source: string) {
    clearTimeout(this.inputTimeout);
    if (inputNumber > this.options.ceil) {
      this.inputTimeout = setTimeout(() => {
        inputNumber = this.options.ceil;
        this.targetNumber = this.options.ceil;
        if (source === 'actionInputRef') {
          this.actionInputRef.nativeElement.value = this.targetNumber;
        } else if (source === 'conditionInputRef1') {
          this.condition.parameter2 = this.targetNumber;
        } else if (source === 'conditionInputRef2') {
          this.condition.parameter3 = this.targetNumber;
        }
        this.handleInput(inputNumber);
      }, 1500);
    } else if (inputNumber < this.options.floor) {
      this.inputTimeout = setTimeout(() => {
        inputNumber = this.options.floor;
        this.targetNumber = this.options.floor;
        if (source === 'actionInputRef') {
          this.actionInputRef.nativeElement.value = this.targetNumber;
        } else if (source === 'conditionInputRef1') {
          this.condition.parameter2 = this.targetNumber;
        } else if (source === 'conditionInputRef2') {
          this.condition.parameter3 = this.targetNumber;
        }
        this.handleInput(inputNumber);
      }, 1500);
    } else {
      this.inputTimeout = setTimeout( () => {
        this.handleInput(inputNumber);
      },1500)
    }
  }

  sliderClicked() {
    this.sliderLineClicked = true;
  }

  handleInput(inputNumber) {
    if (this.mode === 'action') {
      this.targetNumber = inputNumber;
      this.action.parameters[1] = inputNumber;
      this.actionChanged$.next(this.action);
    }
    else if (this.mode === 'live') {
      if (!this.sliderButtonClicked && this.sliderLineClicked) {
        this.sliderLineClicked = false;
        this.sliderValues.next(inputNumber);
        this.targetNumber = inputNumber;
        setTimeout(() => {
          if (this.sliderValues.getValue() === inputNumber) {
            this.targetNumber = inputNumber;
            if (Number(inputNumber) !== Number(this.equipmentProperty.value)) {
              this.numberChanged$.next(inputNumber);
            }
          }
        }, 2000);
      }
    } else if (this.mode === 'condition') {
      this.conditionChanged$.next(this.condition);
    }
    this.sliderButtonClicked = false;
  }


  plusClicked(inputNumber?: number) {
    if (this.mode === 'action') {
      if (!this.targetNumber) {
        this.targetNumber = 0;
      }
      this.actionInputRef.nativeElement.stepUp();
      this.targetNumber = this.actionInputRef.nativeElement.value;
      this.action.parameters[1] = this.targetNumber.toString();
      this.actionChanged$.next(this.action);
    } else if (this.mode === 'live') {
      if (Number(inputNumber) + 1 <= this.options.ceil) {
        this.sliderButtonClicked = true;
        this.targetNumber = Number(inputNumber) + 1;
        this.sliderValues.next(this.targetNumber);
        this.equipmentProperty.value = this.targetNumber;
      }
      setTimeout(() => {
        if (this.sliderValues.getValue() === Number(inputNumber) + 1) {
          if (this.targetNumber >= this.options.ceil) {
            this.targetNumber = this.options.ceil;
            this.numberChanged$.next(this.options.ceil);
            this.sliderValues.next(this.options.ceil);
          } else {
            this.numberChanged$.next(this.targetNumber);
          }
        }
      }, 2000);
    }
  }

  minusClicked(inputNumber?: number) {
    if (this.mode === 'action') {
      if (!this.targetNumber) {
        this.targetNumber = 0;
      }
      this.actionInputRef.nativeElement.stepDown();
      this.targetNumber = this.actionInputRef.nativeElement.value;
      this.action.parameters[1] = this.targetNumber.toString();
      this.actionChanged$.next(this.action);
    } else if (this.mode === 'live') {
      if (Number(inputNumber) - 1 >= this.options.floor) {
        this.sliderButtonClicked = true;
        this.targetNumber = Number(inputNumber) - 1;
        this.sliderValues.next(this.targetNumber);
        this.equipmentProperty.value = this.targetNumber;
      }
      setTimeout(() => {
        if (this.sliderValues.getValue() === Number(inputNumber) - 1) {
          if (this.targetNumber <= this.options.floor) {
            this.targetNumber = this.options.floor;
            this.numberChanged$.next(this.options.floor);
            this.sliderValues.next(this.options.floor);
          } else {
            this.numberChanged$.next(this.targetNumber);
          }
        }
      }, 2000);
    }
  }

  onChangeConditionType() {
    this.conditionChanged$.next(this.condition);
  }

  propertyClicked() {
    if (this.equipmentProperty.security.requirePinToActivate && !this.pinService.pinIsSet()) {
      this.openPinModal();
    } else {
      this.openEquipmentNumberLiveModal();
    }
  }

  async openPinModal() {
    const modal = await this.modalController.create({
      component: ModalPinComponent,
      cssClass: 'pin-modal-container',
      backdropDismiss: true,
      showBackdrop: true
    });
    modal.onDidDismiss()
      .then((resp) => {
        if (resp?.data?.choice) {
          this.pinService.setPin(resp?.data?.pin).subscribe( response => {
            if (response === true) {
              this.openEquipmentNumberLiveModal();
            }
          });
        }
      });
    return await modal.present();
  }

  async openPinModalForSpecialHTML() {
    const modal = await this.modalController.create({
      component: ModalPinComponent,
      cssClass: 'pin-modal-container',
      backdropDismiss: true,
      showBackdrop: true
    });
    modal.onDidDismiss()
      .then((resp) => {
        if (resp?.data?.choice) {
          this.pinService.setPin(resp?.data?.pin).subscribe( response => {
            if (response === true) {
              this.numberChanged$.next(this.targetNumber);
            } else {
              this.sliderValues.next(this.tempTargetNumber);
              this.equipmentProperty.value = this.tempTargetNumber;
              this.targetNumber = this.tempTargetNumber;
            }
          });
        } else {
          this.sliderValues.next(this.tempTargetNumber);
          this.equipmentProperty.value = this.tempTargetNumber;
          this.targetNumber = this.tempTargetNumber;
        }
      });
    return await modal.present();
  }



  async openEquipmentNumberLiveModal() {
    const modal = await this.modalController.create({
      component: EquipmentNumberLiveComponent,
      cssClass: 'equipment-property-modal',
      backdropDismiss: true,
      showBackdrop: true,
      componentProps: {
        equipmentProperty: this.equipmentProperty,
        parentDevice: this.parentDevice,
        unitOfMeasurement: this.data.unitOfMeasurement
      }
    });
    return await modal.present();
  }

  async openEquipmentNumberSettingsModal() {
    const modal = await this.modalController.create({
      component: EquipmentNumberSettingsComponent,
      cssClass: 'equipment-property-modal',
      backdropDismiss: true,
      showBackdrop: true,
      componentProps: {
        propIdentifier: this.propIdentifier,
        unitOfMeasurement: this.data.unitOfMeasurement
      }
    });
    return await modal.present();
  }

  updateStatuses() {
    this.modes?.forEach((mode: Mode) => {
      if (Mode.isCooling(mode) && (mode.apartmentId === this.apartmentId)){
        this.coolingActive = mode.isActive;
      } else if (Mode.isHeating(mode) && (mode.apartmentId === this.apartmentId)){
        this.heatingActive = mode.isActive;
      }
    });
  }

  getSliderColor() {
    if (this.coolingActive) {
      return 'primary';
    } else if (this.heatingActive) {
      return 'danger';
    }
  }


  ngOnDestroy() {
    clearTimeout(this.inputTimeout);
    if (this.numberSubscription) {
      this.numberSubscription.unsubscribe();
    }
    if (this.modesSubscription) {
      this.modesSubscription.unsubscribe();
    }
    if (this.apartmentIdSubscription) {
      this.apartmentIdSubscription.unsubscribe();
    }
  }

}
