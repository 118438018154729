
<div class="footer-button-container ">
  <div class="footer-button flex-grow4" >
    <div routerLink="/home/" routerLinkActive="footer-link-active">
      <div class="i-home-footer" ></div>
    </div>
  </div>

  <div class="footer-button flex-grow4" >
    <div routerLink="/automation/" routerLinkActive="footer-link-active">
      <div class="i-automation-footer" ></div>
    </div>
  </div>

  <div class="footer-button flex-grow4">
    <div routerLink="/rooms/" routerLinkActive="footer-link-active" (click)="onRouteToRooms()">
      <div class="i-rooms-footer"></div>
    </div>
  </div>

  <div class="footer-button flex-grow4" >
    <div routerLink="/scenes/" routerLinkActive="footer-link-active">
      <div class="i-scenes-footer"></div>
    </div>
  </div>

</div>
