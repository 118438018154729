<div class="landing-full-screen">
  <div class="top-container flex-column flex-center">
    <i class="i-logo landing-logo"></i>
    <div class="text-1 margin-bottom20">
      {{ "welcome_to_abas" | translate }}
    </div>
    <div class="white text-2 margin-bottom20">ABAS</div>
    <div class="text-3">ADRIA BUILDING AUTOMATION SYSTEM</div>
    <!-- <button class="margin-top60" (click)="openAbasCodeAlert()">Enter ABAS code</button> -->
  </div>
  <div class="flex-column flex-center">
    <div
      *ngIf="(allConfigsArray$ | async)?.length > 0; else noStoredConfigs" class="margin-top30 width-full"
    >
      <div
        *ngFor="let config of allConfigsArray$ | async"
        class="flex-row-center-full width-full"
      >
        <div class="flex-row-center-vertical width80">
          <ion-button
            class="square-primary margin-top10 landing-btn width85"
            (click)="loadConfigPrompt(config)"
            >{{ config?.configuration.OBJECT_NAME ?? config?._id }}</ion-button
          >
          <div
            (click)="onClickDelete(config)"
            class="margin-left15 i-delete"
          ></div>
        </div>
      </div>
    </div>
    <ng-template #noStoredConfigs>
      <ion-button
        class="square-primary margin-top6 landing-btn"
        (click)="openAbasCodeAlert()"
        >{{ "enter_code" | translate }}</ion-button
      >
      <h4>{{ "or" | translate }}</h4>
      <!-- <button (click)="demoMode()" class="margin-top30">Try ABAS demo mode</button> -->

      <ion-button
        class="square-primary margin-top6 landing-btn"
        (click)="demoMode()"
        >{{ "try_demo" | translate }}</ion-button
      >
    </ng-template>
  </div>
</div>
