<div class="flex-column flex-center">
  <div class="margin-bottom10">{{ "add_delay_in_sec" | translate }}</div>
  <form>
    <input
      class="input"
      type="number"
      [formControl]="delayForm"
      min="1"
      [ngClass]="{ 'is-invalid': submitted && delayForm.errors }"
    />
    <div *ngIf="submitted && delayForm.errors" class="invalid-input">
      <div *ngIf="delayForm.errors.min || delayForm.errors.required">{{ 'delay_must_be_greater_than_zero' | translate }}</div>
    </div>
  </form>
</div>

<div class="margin-top40 flex-row flex-end">
  <ion-button

    size="default"
    class="square-secondary"
    (click)="onApply()"
    color="ocean-blue"
    >{{ "apply" | translate }}</ion-button
  >
</div>
