import { Pipe, PipeTransform } from '@angular/core';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { User } from 'src/app/core/models/user/user.model';

@Pipe({
  name: 'filterDeviceByVisibleToUser'
})
export class FilterDeviceByVisibleToUserPipe implements PipeTransform {

  transform(devices: Device[], useDeviceVisibleByUser: boolean, user: User): Device[] {
    if (!devices) {
      return [];
    }
    if (!useDeviceVisibleByUser || User.isAdmin(user)) {
      return devices;
    }
    return devices.filter((dev: Device) => {
      return dev.visibleToUser;
    });
  }
}
