<ng-container *ngIf="mode === 'live'">
  <div class="flex-column-center margin-top50">
    <div (click)="onClickBlindsUp()" class="blinds-prop margin-bottom10">
      <div>
        {{ 'blinds_up' | translate }}
      </div>
      <div  *ngIf="!loadingElement(daliBlind.equipmentProperties[0].id)" class="i-blinds-up-big margin-top5 margin-bottom5"></div>
        <div *ngIf="loadingElement(daliBlind.equipmentProperties[0].id)"  class="lds-spinner margin-top5 margin-bottom5"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        <div>
          {{ (loadingElement(daliBlind.equipmentProperties[0].id) ? "Moving" : "Not moving") | genComTranslate }}
        </div>
    </div>

    <div (click)="onClickBlindsDown()" class="blinds-prop">
      <div>
        {{ 'blinds_down' | translate }}
      </div>
      <div *ngIf="!loadingElement(daliBlind.equipmentProperties[1].id)" class="i-blinds-down-big margin-top5 margin-bottom5"> </div>
      <div *ngIf="loadingElement(daliBlind.equipmentProperties[1].id)"  class="lds-spinner margin-top5 margin-bottom5"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      <div>
        {{ (loadingElement(daliBlind.equipmentProperties[1].id) ? "Moving" : "Not moving") | genComTranslate }}
      </div>
    </div>
  </div>

</ng-container>


<ng-container *ngIf="mode === 'action'">

  <div class="flex-column">
    <!-- <div class="label">{{ daliBlind.name }}</div> -->
  </div>
   <ion-radio-group [value]="action?.parameters[0]">
      <ion-item
       >
        <ion-radio
        labelPlacement="end"
        slot="start"
        class="color-radio margin-right40"
        [value]="daliBlind.equipmentProperties[0].id"
        (click)="handleActionChange(daliBlind.equipmentProperties[0].id)">{{ "blinds_up" | translate }}</ion-radio>
      </ion-item>
      <ion-item
       >
        <ion-radio
        labelPlacement="end"
        slot="start"
        class="color-radio margin-right40"
        [value]="daliBlind.equipmentProperties[1].id"
        (click)="handleActionChange(daliBlind.equipmentProperties[1].id)">{{ "blinds_down" | translate }}</ion-radio>
      </ion-item>

    </ion-radio-group>
</ng-container>
