<div class="search-wrapper margin-left10 margin-top10">
  <input
  class="search-input"
  type="text"
  (keyup)="onFilter()"
  (keyup.enter)="onEnter()"
  autofocus
  [(ngModel)]=searchFilter
  placeholder="{{'search' | translate}}">
  <div class="search-icon" *ngIf="searchFilter===''"></div>
  <div class="delete-icon" *ngIf="searchFilter!==''" (click)=onClear() ></div>
</div>
