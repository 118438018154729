import { Pipe, PipeTransform } from '@angular/core';
import { Mode } from 'src/app/core/models/modes/mode.model';

@Pipe({
  name: 'modeActivationTypeFilter'
})
export class ModeActivationTypeFilterrPipe implements PipeTransform {
  transform(modes: Mode[], type: string): Mode[] {
    if (!modes) {
      return [];
      }
    if (type === 'activate') {
      return modes;
      }
    if (type === 'deactivate') {
        return modes.filter((mode: Mode) => {
          return mode.actions.actions[0].command !== "ModeActions.HeatingCooling";
        } );
    }
  }
}
