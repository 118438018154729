import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Action } from 'src/app/core/models/automation/action.model';
import { Condition } from 'src/app/core/models/automation/condition.model';
import { ComponentMode } from 'src/app/core/models/equipment-property-component-to-load.model';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { Property } from 'src/app/core/models/project/property.model';

export const OutputModeOptions = [
  {
    name: 'Off',
    value: 0
  },
  {
    name: 'On',
    value: 1
  },
  {
    name: 'Luxomat',
    value: 2
  },
  // {
  //   name: 'u zadano vrijeme',
  //   value: 3
  // },
  // {
  //   name: 'lux + vrijeme',
  //   value: 4
  // },
  {
    name: 'PIR',
    value: 7
  },
  {
    name: 'Touchpad',
    value: 8
  },
  {
    name: 'Switch',
    value: 9
  },
  {
    name: 'Luxomat + PIR',
    value: 72
  },
  // {
  //   name: 'Vrijeme + PIR',
  //   value: 73
  // }
];

@Component({
  selector: 'app-output-mode',
  templateUrl: './output-mode.component.html',
  styleUrls: ['./output-mode.component.scss']
})
export class OutputModeComponent implements OnInit {
  @Input() mode: ComponentMode;
  @Input() equipmentProperty: Property;
  @Input() parentDevice: Device;
  @Input() actionInput: Action;
  @Input() data;
  @Input() conditionInput: Condition;

  condition: Condition;
  conditionChanged$ = new Subject<Condition>();

  action: Action = new Action();
  actionChanged$ = new Subject<Action>();

  outputModeOptions = OutputModeOptions;

  constructor() { }

  ngOnInit(): void {
    if (this.mode === 'action') {
      if (!this.actionInput) {
        this.action = this.createNewAction();
      } else {
        this.action = Object.assign(new Action(), this.actionInput);
      }
    }    else if (this.mode === 'condition') {
      if (!this.conditionInput) {
        this.condition = this.createNewCondition();
      } else {
        this.condition = JSON.parse(JSON.stringify(this.conditionInput));
      }
    }
  }

  createNewAction(): Action {
    const newAction = new Action();
    newAction.id = `tempActionId1`; // = this.automationThenId + '|' + this.selectedAction.id; aa0001|ca0001",
    newAction.name = `tempActionName1`;
    newAction.command = 'EquipmentActions.Activate';
    newAction.description = 'Action for activating equipment property';
    newAction.parameters = [`${this.parentDevice.id}/${this.equipmentProperty.type}`, undefined];
    newAction.builtIn = false;
    return newAction;
  }

  createNewCondition() {
    const newCondition = new Condition();
    newCondition.automationConditionType = 'simple';
    newCondition.type = 0; // on off component always generates equals condition type (0)
    newCondition.parameter1 = `ValRef:${this.parentDevice.id}/${this.equipmentProperty.type}`;
    // this.condition.parameter2 = 0;
    newCondition.parameter3 = 0; // is ignored in on off (binary) component
    newCondition.editable = false;
    newCondition.parameter1Editable = false;
    newCondition.parameter2Editable = false;
    newCondition.parameter3Editable = false;
    return newCondition;
  }

  handleActionChange(event) {
    /* this.action.parameters[1] = event.target.value;
    this.actionChanged$.next(this.action); */
    const newAction = JSON.parse(JSON.stringify(this.action));
    this.action.parameters[1] = Number(event.target.value);
    newAction.parameters[1] = event.target.value;
    this.actionChanged$.next(newAction);
  }

  handleConditionChange(event) {
    /* this.condition.parameter2 = event.target.value;
    this.conditionChanged$.next(this.condition); */
    const newCondition: Condition = JSON.parse(JSON.stringify(this.condition))
    this.condition.parameter2 = Number(event.target.value);
    newCondition.parameter2 = event.target.value;
    this.conditionChanged$.next(newCondition);
  }

}
