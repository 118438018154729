import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CONNREFUSED } from 'dns';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Action } from 'src/app/core/models/automation/action.model';
import { Condition } from 'src/app/core/models/automation/condition.model';
import { RgbLight } from 'src/app/core/models/project/devices/rgbLight.model';
import { DaliSceneElement } from 'src/app/core/models/scenes/dali-scene-element.model';
import { User } from 'src/app/core/models/user/user.model';
import { DemoService } from 'src/app/core/services/demo.service';
import { UserSettingsService } from 'src/app/core/services/user-settings.service';
import { UserService } from 'src/app/core/services/user.service';
import { DaliProjectService } from 'src/app/modules/project/services/dali-project.service';
import { ApiDaliProjectService } from 'src/app/modules/project/services/http/api-dali-project.service';
import { LoadingService } from 'src/app/shared/services/loading.service';

@Component({
  selector: 'app-rgbw-light',
  templateUrl: './rgbw-light.component.html',
  styleUrls: ['./rgbw-light.component.scss']
})
export class RgbwLightComponent implements OnInit, OnDestroy {
  daliRgbLight: RgbLight;
  @Input() mode: string;
  @Input() daliSceneElement: DaliSceneElement;
  @Input() set daliRgbLightInput(rgbLight: RgbLight) {
    this.daliRgbLight = rgbLight;
    this.selectedColorHex = rgbLight?.value?.split('|')[0];
    this.whiteValue = Number(rgbLight?.value?.split('|')[1]) || 0;
  }
  @Input() set daliCondition(condition: Condition) {
    if (condition) {
      this.edit = true;
      this.condition = condition
      if(condition.type === 0) {
        this.selectedRadio = '0';
      } else if(condition.type === 9) {
        this.selectedRadio = '1'
      }
      this.deviceCondition$.emit(condition);
    }
  }

  @Input() set daliAction(action: Action) {
    if (action) {
      this.edit = true;
      this.action = action;
      this.deviceAction$.emit(this.action);
    }
  }

  @Output() deviceAction$: EventEmitter<Action> = new EventEmitter();
  @Output() deviceCondition$: EventEmitter<Condition> = new EventEmitter();
  @Output() newRgbLightValue: EventEmitter<string> = new EventEmitter();
  action: Action = new Action();
  condition: Condition = new Condition();

  selectedColorHex: string;
  whiteValue: number;
  selectedRadio: string;
  edit: boolean = false;

  sliderOptions = {
    floor: 0,
    ceil: 100
  };
  colorSubscription: Subscription;
  newColorValue = new BehaviorSubject<string>(null);
  inputDelay = 1000;

  loadingSubscription: Subscription;
  loadingElements = [];


  colorPresets: string[] = [];
  pickerOpened = false;


  last10ColorsUsed: string[] = [];
  user: User;

  constructor(
    private apiDaliProjectService: ApiDaliProjectService,
    private loadingService: LoadingService,
    private demoService: DemoService,
    private daliProjectService: DaliProjectService,
    private userSettingsService: UserSettingsService,
    private userService: UserService) { }

  ngOnInit(): void {
    if (this.mode === 'live') {
      this.loadingSub();
      this.colorSubscription = this.newColorValue.pipe(
        debounceTime(this.inputDelay),
        distinctUntilChanged(),
      ).subscribe((newColorValue) => {
        if (newColorValue) {
          const col = newColorValue.split('|')[0];
          const wh = Number(newColorValue.split('|')[1]) || 0;
          this.userSettingsService.setColor(this.user._id,col);
          this.addLastSelectedColors();
          this.apiDaliProjectService.activateRgbLight(this.daliRgbLight.id, col, wh).subscribe();
        }
      });
    }
    if (this.mode === 'editScene') {
      this.daliRgbLight = JSON.parse(JSON.stringify(this.daliRgbLight));
      this.selectedColorHex = `#${this.rgbToHex(this.daliSceneElement.color.red)}${this.rgbToHex(this.daliSceneElement.color.green)}${this.rgbToHex(this.daliSceneElement.color.blue)}`;
      this.whiteValue = this.daliSceneElement.color.white;
    }
    if (this.mode === 'action' && !this.edit) {
      this.action.id = 'ca0016';
      this.action.name = 'Activate DALI RGB';
      this.action.description = '';
      this.action.command = 'DaliActions.ActivateRgb';
      this.action.parameters = [`${this.daliRgbLight.id}`, `${this.daliRgbLight.value}`];
      this.action.builtIn = true;
      this.deviceAction$.emit(this.action);
    } else if (this.mode === 'condition' && !this.edit) {
      this.condition.automationConditionType = 'simple';
      this.condition.type = 0; // equal
      this.condition.parameter1 = `ValRef:${this.daliRgbLight.id}`;
      this.condition.parameter2 = '#000000|0';
      this.condition.parameter3 = 0; // is ignored in on off (binary) component
      this.condition.editable = false;
      this.condition.parameter1Editable = false;
      this.condition.parameter2Editable = false;
      this.condition.parameter3Editable = false;
    }

    if(this.mode === 'action' && this.edit) {
      this.selectedColorHex = this.action.parameters[1].split('|')[0];
      this.whiteValue = Number(this.action.parameters[1].split('|')[1]) || 0;
    }
    this.user = this.userService.getUser();
    if (this.userSettingsService.getLast10UsedColors(this.user._id)) {
      this.last10ColorsUsed = this.userSettingsService.getLast10UsedColors(this.user._id).selectedColors
      
      this.addLastSelectedColors()
    }
  }

  addLastSelectedColors() {
    this.last10ColorsUsed = this.userSettingsService.getLast10UsedColors(this.user._id).selectedColors;
    this.colorPresets = [...this.last10ColorsUsed]
  }


  minusClicked() {
    if (this.demoService.isDemo()) {
      if(this.whiteValue > 0) {
        this.whiteValue--;
        this.daliProjectService.changePropertyValueForDemo(this.daliRgbLight.id, `${this.selectedColorHex}|${this.whiteValue}`);
      }
    } else {
      if (this.whiteValue > 0 && (this.mode === 'live' || this.mode === 'editScene')) {
        this.whiteValue--;
        this.newColorValue.next(`${this.selectedColorHex}|${this.whiteValue}`);
      } else if (this.mode === 'action') {
        this.whiteValue--;
        this.action.parameters[1] = `${this.selectedColorHex}|${this.whiteValue}`;
        this.deviceAction$.emit(this.action);
      }
    }
  }

  plusClicked() {
    if (this.demoService.isDemo()) {
      if(this.whiteValue < 100) {
        this.whiteValue++;
        this.daliProjectService.changePropertyValueForDemo(this.daliRgbLight.id, `${this.selectedColorHex}|${this.whiteValue}`);
      }
    } else {
      if (this.whiteValue < 100 && (this.mode === 'live' || this.mode === 'editScene')) {
        this.whiteValue++;
        this.newColorValue.next(`${this.selectedColorHex}|${this.whiteValue}`);
      } else if (this.mode === 'action') {
        this.whiteValue++;
        this.action.parameters[1] = `${this.selectedColorHex}|${this.whiteValue}`;
        this.deviceAction$.emit(this.action);
      }
    }
  }

  onSliderInputChanged(rangeValue) {
    if (this.demoService.isDemo()) {
      this.whiteValue = rangeValue;
      this.daliProjectService.changePropertyValueForDemo(this.daliRgbLight.id, `${this.selectedColorHex}|${this.whiteValue}`);
    } else {
      if (this.mode === 'live' || this.mode === 'editScene') {
        if (`${this.selectedColorHex}|${rangeValue}` !== `${this.selectedColorHex}|${this.whiteValue}`) {
          this.whiteValue = rangeValue;
          this.newColorValue.next(`${this.selectedColorHex}|${this.whiteValue}`);
        }
      } else if (this.mode === 'action') {
        this.whiteValue = rangeValue;
        this.action.parameters[1] = `${this.selectedColorHex}|${this.whiteValue}`;
        this.deviceAction$.emit(this.action);
      }
    }
  }


  onNewRgbValue(newColor: string) {
    if (this.demoService.isDemo()) {
      this.selectedColorHex = newColor;
      this.daliProjectService.changePropertyValueForDemo(this.daliRgbLight.id, `${this.selectedColorHex}|${this.whiteValue}`);
    }else {
      if (this.mode === 'live' || this.mode === 'editScene') {
        this.selectedColorHex = newColor;
        this.newColorValue.next(`${this.selectedColorHex}|${this.whiteValue}`);
      } else if (this.mode === 'action') {
        this.selectedColorHex = newColor;
        this.action.parameters[1] = `${this.selectedColorHex}|${this.whiteValue}`;
        this.deviceAction$.emit(this.action);
      }
    }
  }



  closeColorPicker() {
    if (this.pickerOpened) {
      this.pickerOpened = false;
    }
  }

  toggleColorPicker(event) {
    event.stopPropagation();
    this.pickerOpened = !this.pickerOpened;
  }

  toggleDaliRgbLight() {
    if (this.demoService.isDemo()) {
      if (this.daliRgbLight.value === '#000000|0') {
        this.selectedColorHex = '#FFFFFF';
        this.whiteValue = 100;
        this.daliRgbLight.value = '#FFFFFF|100';
        this.daliProjectService.changePropertyValueForDemo(this.daliRgbLight.id, `${this.selectedColorHex}|${this.whiteValue}`);
      } else {
        this.selectedColorHex = '#000000';
        this.whiteValue = 0;
        this.daliRgbLight.value = '#000000|0';
        this.daliProjectService.changePropertyValueForDemo(this.daliRgbLight.id, `${this.selectedColorHex}|${this.whiteValue}`);
      }
    } else {
      if (this.mode === 'live') {
        if (this.daliRgbLight.value === '#000000|0') {
          this.apiDaliProjectService.activateRgbLight(this.daliRgbLight.id, '#FFFFFF', 100).subscribe();
        } else {
          this.apiDaliProjectService.activateRgbLight(this.daliRgbLight.id, '#000000', 0).subscribe();
        }
      }
    }
  }

  handleBinaryChange(event) {
    if (this.mode === 'condition') {
      if (+event.target.value === 1) {
        this.condition.type = 9;
      } else {
        this.condition.type = 0;
      }
      this.deviceCondition$.emit(this.condition);
    }
  }

  loadingSub() {
    this.loadingElements = this.loadingService.getLoadingElements();
    this.loadingSubscription = this.loadingService.loadingChanged.subscribe(response => {
      this.loadingElements = this.loadingService.getLoadingElements();
    });
  }

  loadingElement(id: string) {
    return this.loadingElements.includes(id);
  }

  onApply() {
    this.newRgbLightValue.emit(`${this.selectedColorHex}|${this.whiteValue}`);
  }

  rgbToHex(rgbNumber: number): string {
    let hex = Number(rgbNumber).toString(16);
    if (hex.length < 2) {
      hex = '0' + hex;
    }
    return hex;
  }

  ngOnDestroy() {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
    setTimeout(() => {
      if (this.colorSubscription) {
        this.colorSubscription.unsubscribe();
      }
    }, this.inputDelay);

  }
}
