import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Alarms } from 'src/app/core/models/alarms.model';
import { API_BASE_URL } from 'src/environments/environment';
import { AlarmAlertsService } from '../alarm-alerts.service';

@Injectable({
  providedIn: 'root',
})
export class ApiAlarmAlertsService {
  constructor(
    private http: HttpClient,
    private alarmAlertsService: AlarmAlertsService
  ) {}

  getUnconfirmedAlarms(
    pagenumber: number,
    pagesize: number,
    requestBody: Alarms
  ) {
    const params = new HttpParams()
      .set('pagenumber', pagenumber.toString())
      .set('pagesize', pagesize.toString());

    return this.http
      .post<any>(API_BASE_URL + '/alarms', requestBody, { params })
      .pipe(
        map((alarms) => {
          this.alarmAlertsService.setAlarms(alarms);
          return alarms;
        })
      );
  }

  confirmAlarm(alarmId: number, text: string, userId: string) {
    return this.http
      .post<any>(API_BASE_URL + '/alarms/confirm', {
        ConfirmUserId: userId,
        AlarmLogId: alarmId,
        Text: text
      })
      .pipe(
        map((resp) => {
          this.alarmAlertsService.confirmAlarm(alarmId);
          this.getUnconfirmedAlarms(1, 100, {
            Filtering: '',
            Sorting: '',
            DateTimeFrom: '',
            DateTimeTo: '',
            Levels: [],
            Types: [],
            Confirmed: [0],
            ConfirmUsers: [],
          }).subscribe();
          return resp;
        })
      );
  }

  confirmAllAlarms(text: string, userId: string) {
    return this.http
      .post<any>(API_BASE_URL + '/alarms/confirmall', { Text: text, ConfirmUserId: userId })
      .pipe(
        map((resp) => {
          this.alarmAlertsService.setAlarms([]);
          return resp;
        })
      );
  }
}
