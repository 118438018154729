import { RelationalOperators } from './relational-operators.enum';

export class Condition {
    $type: string;
    automationConditionType: string;
    type: RelationalOperators | 100;
    parameter1: string;
    parameter2: any;
    parameter3: number;
    editable: boolean;
    parameter1Editable: boolean;
    parameter2Editable: boolean;
    parameter3Editable: boolean;


    constructor() {
        this.$type = 'Abas.Models.Project.AutomationRequirements.Condition, Abas';
    }
}
