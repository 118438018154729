import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL } from 'src/environments/environment';



@Injectable({
    providedIn: 'root'
})
export class ApiRoomsService {

    roomID: string = null;
    constructor(private http: HttpClient) { }


    setRoomDevicesPosition(body: any) {
        return this.http.post<any>(`${API_BASE_URL}/equipment/positions`, body);
    }

    setRoomDaliDevicesPosition(body: any) {
      return this.http.post<any>(`${API_BASE_URL}/dali/positions`, body);
  }

    setRoomId(id: string) {
        this.roomID = id;
    }

    getRoomId() {
        return this.roomID;
    }

    removeId() {
        this.roomID = null;
    }
}

