    <div class="flex-column flex-center" style="padding:30px">
        <div class="modal-header">
            <input type="password" [(ngModel)]="pin" placeholder="{{'enter_pin' | translate}}">
        </div>
        <div class="flex-column-center">
            <div class="flex-row">
                <button class="number-button" (click)="addNumber('1')">1</button>
                <button class="number-button" (click)="addNumber('2')">2</button>
                <button class="number-button" (click)="addNumber('3')">3</button>
            </div>
            <div class="flex-row">
                <button class="number-button" (click)="addNumber('4')">4</button>
                <button class="number-button" (click)="addNumber('5')">5</button>
                <button class="number-button" (click)="addNumber('6')">6</button>
            </div>
            <div class="flex-row">
                <button class="number-button" (click)="addNumber('7')">7</button>
                <button class="number-button" (click)="addNumber('8')">8</button>
                <button class="number-button" (click)="addNumber('9')">9</button>
            </div>
            <div class="flex-row">
                <button class="icon-button i-backspace-wh" (click)="removeLastNumber()"></button>
                <button class="number-button" (click)="addNumber('0')">0</button>
                <button class="icon-button i-enter-key-wh" (click)="decisionClick(true)"></button>
            </div>
        </div>
        <span
        class="delete-btn-red delete-icon-red"
        (click)="decisionClick(false)"
      ></span>
    </div>
