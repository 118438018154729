import { Component, OnInit } from '@angular/core';
import { ConfigLoaderService } from 'src/app/core/app-load/config-loader.service';
import { DEFAULT_REQUEST_TIMEOUT } from 'src/environments/environment';

@Component({
  selector: 'app-network-error',
  templateUrl: './network-error.component.html',
  styleUrls: ['./network-error.component.scss']
})
export class NetworkErrorComponent implements OnInit {
  loading = false;

  constructor(private configLoaderService: ConfigLoaderService) { }

  ngOnInit(): void {
  }

  retryConnection() {
    this.loading = true;
    this.configLoaderService.loadMobileConfig(true);
    setTimeout(() => {
      this.loading = false;
    }, DEFAULT_REQUEST_TIMEOUT);
  }

}
