import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_BASE_URL } from 'src/environments/environment';
import { Scene } from 'src/app/core/models/scenes/scene.model';
import { SceneGroup } from 'src/app/core/models/scenes/scene-group.model';
import { map, tap } from 'rxjs/operators';
import { ScenesService } from '../scenes/scenes.service';
import { DemoService } from 'src/app/core/services/demo.service';

@Injectable({
  providedIn: 'root'
})
export class ApiScenesService {

  constructor(
    private http: HttpClient,
    private scenesService: ScenesService,
    private demoService: DemoService
  ) { }

  addScene(scene: Scene) {
    const body = scene;
    return this.http.post(`${API_BASE_URL}/scenes`, body);
  }

  getScenes() {
    if (this.demoService.isDemo()) {
      return this.demoService.getDemoScenes().pipe(
        tap(demoScenes => {
          this.scenesService.setScenes(demoScenes);
        })
      );
    } else {
      return this.http.get<Scene[]>(`${API_BASE_URL}/scenes`).pipe(
        map((scenes: Scene[]) => {
          this.scenesService.setScenes(scenes);
          return scenes;
        }));
    }
  }

  getScene(sceneId: string) {
    const params = new HttpParams().set('id', sceneId);
    return this.http.get<Scene>(`${API_BASE_URL}/scenes/byId`, { params }).pipe(
      tap((scene: Scene) => {
        if (this.scenesService.getScenes() === undefined) {
          this.getScenes().subscribe(() => {
            this.scenesService.updateScene(scene);
          });
        } else {
          this.scenesService.updateScene(scene);
        }
      })
    );
  }

  updateScene(scene: Scene) {
    const body = scene;
    return this.http.put<Scene>(`${API_BASE_URL}/scenes/`, body);
  }

  activateScene(sceneId: string) {
    const params = new HttpParams().set('id', sceneId);
    return this.http.get<Scene>(`${API_BASE_URL}/scenes/activate`, { params }).pipe(
      map(scene => {
        this.scenesService.activateOrDeactivateScene(sceneId);
        return scene;
      })
    );
  }

  deactivateScene(sceneId: string) {
    const params = new HttpParams().set('id', sceneId);
    return this.http.get<Scene>(`${API_BASE_URL}/scenes/deactivate`, { params }).pipe(
      map(scene => {
        this.scenesService.activateOrDeactivateScene(sceneId);
        return scene;
      })
    );
  }

  deleteScene(sceneId: string) {
    const params = new HttpParams().set('id', sceneId);
    return this.http.delete<Scene>(`${API_BASE_URL}/scenes/`, { params });
  }

  activateSceneGroup(sceneId: string) {
    const params = new HttpParams().set('id', sceneId);
    return this.http.get<Scene>(`${API_BASE_URL}/scenes/group/activate`, { params });
  }


  getSceneGroups() {
    return this.http.get<SceneGroup[]>(`${API_BASE_URL}/scenes/group`).pipe(
      map((scenesGroups: SceneGroup[]) => {
        this.scenesService.setSceneGroups(scenesGroups);
        return scenesGroups;
      }));
  }

  getSceneGroup(sceneGroupId: string) {
    const params = new HttpParams().set('id', sceneGroupId);
    return this.http.get<SceneGroup>(`${API_BASE_URL}/scenes/group/byId`, { params });
  }

  addSceneGroup(sceneGroup: SceneGroup) {
    const body: SceneGroup = sceneGroup;
    return this.http.post(`${API_BASE_URL}/scenes/group`, body);
  }

  deleteSceneGroup(sceneGroupId: string) {
    const params = new HttpParams().set('id', sceneGroupId);
    return this.http.delete<Scene>(`${API_BASE_URL}/scenes/group`, { params });
  }

  updateSceneGroup(sceneGroup: SceneGroup) {
    const body = sceneGroup;
    return this.http.put<Scene>(`${API_BASE_URL}/scenes/group`, body);
  }

  updateScenesPosition(position: any) {
    const body = position;
    return this.http.post<any>(`${API_BASE_URL}/scenes/positions`, body);
  }


}
