import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Project } from 'src/app/core/models/project/project.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { FastSceneAndAutoCreate } from '../../services/fastSceneAndAutoCreate.service';

@Component({
  selector: 'app-device-category',
  templateUrl: './device-category.component.html',
  styleUrls: ['./device-category.component.scss']
})
export class DeviceCategoryComponent implements OnInit {

  showBlinds: boolean;
  showLights: boolean;
  showHvac: boolean;
  showSecurity: boolean;
  showSafety: boolean;
  showGeneral: boolean;
  project: Project = this.projectService.getProject();

  constructor(private projectService: ProjectService,
              private fastSceneAndAutoCreate: FastSceneAndAutoCreate,
              private modalController: ModalController) { }

  ngOnInit(): void {
    this.fastSceneAndAutoCreate.getShowBlinds().subscribe( value => {
      this.showBlinds = value
    });

    this.fastSceneAndAutoCreate.getShowHvac().subscribe( value => {
      this.showHvac = value
    });

    this.fastSceneAndAutoCreate.getShowLights().subscribe( value => {
      this.showLights = value
    });

    this.fastSceneAndAutoCreate.getShowGeneral().subscribe( value => {
      this.showGeneral = value
    });

    this.fastSceneAndAutoCreate.getShowSafety().subscribe( value => {
      this.showSafety = value
    });

    this.fastSceneAndAutoCreate.getShowSecurity().subscribe( value => {
      this.showSecurity = value
    });
  }

  changeBlinds() {
    this.fastSceneAndAutoCreate.changeBlinds(!this.showBlinds)
  }

  changeLights() {
    this.fastSceneAndAutoCreate.changeLights(!this.showLights)
  }

  changeHvac() {
    this.fastSceneAndAutoCreate.changeHvac(!this.showHvac)
  }

  changeSecurity() {
    this.fastSceneAndAutoCreate.changeSecurity(!this.showSecurity)
  }

  changeSafety() {
    this.fastSceneAndAutoCreate.changeSafety(!this.showSafety) 
  }

  changeGeneral() {
    this.fastSceneAndAutoCreate.changeGeneral(!this.showGeneral)
  }

  devicesExist(devices: string) {
    return this.project[devices].length > 0;
  }

  confirmed() {
    this.modalController.dismiss();
  }

}
