  <div *ngIf="index !== undefined" class="modal-header">
    <h3>{{ content.name }}</h3>
  </div>

  <div *ngIf="type === 'listOfLights'" class="modal-content">
    <div><strong>Name: </strong> <input [(ngModel)]="name" type="text" /></div>
    <div><strong>ID: </strong> {{ content.id }}</div>
    <div class="flex-row">
      <strong>List Of Location IDs: </strong>
      <div *ngIf="content.listOfLocationIds.length === 0">No location Ids</div>
      <div
        class="margin-right5 clickable"
        *ngFor="let id of content.listOfLocationIds; let i = index"
        (click)="removeLocationId(id)"
      >
        <u>{{ id }}</u
        >{{ addComma(i, content.listOfLocationIds.length) }}
      </div>
    </div>
    <div class="flex-row">
      <b>Add location Id to Light: </b>
      <div
        *ngIf="
          content.listOfLocationIds.length === this.project?.listOfRooms.length
        "
      >
        No location Ids to add.
      </div>
      <div
        *ngFor="let room of this.project?.listOfRooms; let i = index"
        class="clickable element-add"
        (click)="addToListOfApartmentIds(room)"
      >
        <div
          *ngIf="!content.listOfLocationIds.includes(room.id)"
          class="margin-right10"
        >
          <u>{{ room.id }}</u>
        </div>
      </div>
    </div>
    <div>
      <strong>List Of Light Groups: </strong>
      <div
        class="margin-left2"
        *ngFor="let groupId of content.listOfLightGroups; let i = index"
      >
        {{ groupId }}{{ addComma(i, content.listOfLightGroups.length) }}
      </div>
    </div>
    <div><strong>Visible to user: </strong> {{ content.visibleToUser }}</div>
    <input
      class="option-input-sm"
      type="checkbox"
      [(ngModel)]="content.isCondition"
    />
    <b>Is condition</b>
    <br />
    <input
      class="option-input-sm"
      type="checkbox"
      [(ngModel)]="content.isActuator"
    />
    <b>Is actuator</b>
    <div class="margin-top10 margin-bottom10 center">
      <h3>Equipment Properties</h3>
      <hr />
    </div>
    <div *ngFor="let prop of content.equipmentProperties">
      <b>Name</b>: <input type="text" [(ngModel)]="prop.name" />
      <button class="show-more" (click)="toggleShowProp(prop.id)">
        Show more
      </button>
      <div *ngIf="showThisProp(prop.id)">
        <b>ID</b>: {{ prop.id }} <br /><b>Available In User Scene</b>:
        {{ prop.availableInUserScene }}<br />
        <b>Type</b>: {{ prop.type }}<br />
        <b>Value</b>: {{ prop.value }}<br />
        <b>InComm</b>: <u>Address</u> - {{ prop?.inComm?.address }},
        <u>Communication type</u> -
        {{ prop?.inComm?.communicationType }}
        <br />
        <b>OutComm</b>: <u>Adress</u> - {{ prop?.outComm?.address }},
        <u>Communication type</u> -
        {{ prop?.outComm?.communicationType }}
        <br />
        <input
          class="option-input-sm"
          type="checkbox"
          [(ngModel)]="prop.isConditionProperty"
        />
        <b>Is condition property</b>
        <br />
        <input
          class="option-input-sm"
          type="checkbox"
          [(ngModel)]="prop.isActuatorProperty"
        />
        <b>Is actuator property</b>
        <br />
        <div class="margin-top5"><b>Security</b>:</div>
        <input
          class="option-input-sm"
          type="checkbox"
          [(ngModel)]="prop.security.userCanActivate"
        />
        <b>User can activate</b>
        <br />
        <input
          class="option-input-sm"
          type="checkbox"
          [(ngModel)]="prop.security.userCanActivateOverInternet"
        />
        <b>User can activate over internet</b>
        <br />
        <input
          class="option-input-sm"
          type="checkbox"
          [(ngModel)]="prop.security.requirePinToActivate"
        />
        <b>Require pin to activate</b>
        <br />
        <input
          class="option-input-sm"
          type="checkbox"
          [(ngModel)]="prop.security.requirePinToActivateOnlyOverInternet"
        />
        <b>Require pin to activate only over internet</b>
      </div>
    </div>
  </div>

  <div *ngIf="type === 'listOfBlinds'" class="modal-content">
    <div><strong>Name: </strong> <input [(ngModel)]="name" type="text" /></div>
    <div><strong>ID: </strong> {{ content.id }}</div>
  <!--   <div class="flex-row">
      <strong>List Of Location IDs: </strong>
      <div
        class="margin-left2"
        *ngFor="let id of content.listOfLocationIds; let i = index"
      >
        {{ id }}{{ addComma(i, content.listOfLocationIds.length) }}
      </div>
    </div> -->

    <div class="flex-row">
      <strong>List Of Location IDs: </strong>
      <div *ngIf="content.listOfLocationIds.length === 0">No location Ids</div>
      <div
        class="margin-right5 clickable"
        *ngFor="let id of content.listOfLocationIds; let i = index"
        (click)="removeLocationId(id)"
      >
        <u>{{ id }}</u
        >{{ addComma(i, content.listOfLocationIds.length) }}
      </div>
    </div>
    <div class="flex-row">
      <b>Add location Id to Blind: </b>
      <div
        *ngIf="
          content.listOfLocationIds.length === this.project?.listOfRooms.length
        "
      >
        No location Ids to add.
      </div>
      <div
        *ngFor="let room of this.project?.listOfRooms; let i = index"
        class="clickable element-add"
        (click)="addToListOfApartmentIds(room)"
      >
        <div
          *ngIf="!content.listOfLocationIds.includes(room.id)"
          class="margin-right10"
        >
          <u>{{ room.id }}</u>
        </div>
      </div>
    </div>
    <div><strong>Visible to user: </strong> {{ content.visibleToUser }}</div>
    <input
      class="option-input-sm"
      type="checkbox"
      [(ngModel)]="content.isCondition"
    />
    <b>Is condition</b>
    <br />
    <input
      class="option-input-sm"
      type="checkbox"
      [(ngModel)]="content.isActuator"
    />
    <b>Is actuator</b>
    <div class="margin-top10 margin-bottom10 center">
      <h3>Equipment Properties</h3>
      <hr />
    </div>
    <div *ngFor="let prop of content.equipmentProperties; let i = index">
      <b>Name</b>: <input type="text" [(ngModel)]="prop.name" />
      <button class="show-more" (click)="toggleShowProp(prop.id)">
        Show more
      </button>
      <div *ngIf="showThisProp(prop.id)">
        <b>ID</b>: {{ prop.id }}, <b>Available In User Scene</b>:
        {{ prop.availableInUserScene }}, <b>Security</b>: {{ prop.security }},
        <b>Type</b>: {{ prop.type }}, <b>Value</b>: {{ prop.value }},
        <b>InComm</b>: <u>Adress</u> - {{ prop?.inComm?.address }},
        <u>Communication type</u> -
        {{ prop?.inComm?.communicationType }}
        <b>OutComm</b>: <u>Adress</u> - {{ prop?.outComm?.address }},
        <u>Communication type</u> -
        {{ prop?.outComm?.communicationType }}
        <br />
        <input
          class="option-input-sm"
          type="checkbox"
          [(ngModel)]="prop.isConditionProperty"
        />
        <b>Is condition property</b>
        <br />
        <input
          class="option-input-sm"
          type="checkbox"
          [(ngModel)]="prop.isActuatorProperty"
        />
        <b>Is actuator property</b>
        <br />
        <input
          class="option-input-sm"
          type="checkbox"
          [(ngModel)]="prop.security.userCanActivate"
        />
        <b>User can activate</b>
        <br />
        <input
          class="option-input-sm"
          type="checkbox"
          [(ngModel)]="prop.security.userCanActivateOverInternet"
        />
        <b>User can activate over internet</b>
        <br />
        <input
          class="option-input-sm"
          type="checkbox"
          [(ngModel)]="prop.security.requirePinToActivate"
        />
        <b>Require pin to activate</b>
        <br />
        <input
          class="option-input-sm"
          type="checkbox"
          [(ngModel)]="prop.security.requirePinToActivateOnlyOverInternet"
        />
        <b>Require pin to activate only over internet</b>
      </div>
    </div>
  </div>

  <div *ngIf="type === 'listOfGroups'" class="modal-content">
    <div>
      <strong>Name: </strong>
      <input [(ngModel)]="name" type="text" />
      <div class="flex-row">
        <strong>List Of Location IDs: </strong>
        <div *ngIf="content.listOfLocationIds.length === 0">
          No location Ids
        </div>
        <div
          class="margin-right5 clickable"
          *ngFor="let id of content.listOfLocationIds; let i = index"
          (click)="removeLocationId(id)"
        >
          <u>{{ id }}</u
          >{{ addComma(i, content.listOfLocationIds.length) }}
        </div>
      </div>
      <div class="flex-row">
        <b>Add location Id to group: </b>
        <div
          *ngIf="
            content.listOfLocationIds.length ===
            this.project?.listOfRooms.length
          "
        >
          No location Ids to add.
        </div>
        <div
          *ngFor="let room of this.project?.listOfRooms; let i = index"
          class="clickable element-add"
          (click)="addToListOfApartmentIds(room)"
        >
          <div
            *ngIf="!content.listOfLocationIds.includes(room.id)"
            class="margin-right10"
          >
            <u>{{ room.id }}</u>
          </div>
        </div>
      </div>
      <input
        class="option-input-sm"
        type="checkbox"
        [(ngModel)]="content.isRelay"
      />
      <b>Is relay</b>
    </div>
  </div>

  <div *ngIf="type === 'listOfRgbs'" class="modal-content">
    <div>
      <strong>Name: </strong>
      <input [(ngModel)]="name" type="text" />
      <div class="flex-row">
        <strong>List Of Location IDs: </strong>
        <div *ngIf="content.listOfLocationIds.length === 0">
          No location Ids
        </div>
        <div
          class="margin-right5 clickable"
          *ngFor="let id of content.listOfLocationIds; let i = index"
          (click)="removeLocationId(id)"
        >
          <u>{{ id }}</u
          >{{ addComma(i, content.listOfLocationIds.length) }}
        </div>
      </div>
      <div class="flex-row">
        <b>Add location Id to RGB: </b>
        <div
          *ngIf="
            content.listOfLocationIds.length ===
            this.project?.listOfRooms.length
          "
        >
          No location Ids to add.
        </div>
        <div
          *ngFor="let room of this.project?.listOfRooms; let i = index"
          class="clickable element-add"
          (click)="addToListOfApartmentIds(room)"
        >
          <div
            *ngIf="!content.listOfLocationIds.includes(room.id)"
            class="margin-right10"
          >
            <u>{{ room.id }}</u>
          </div>
        </div>
      </div>

      <input
        class="option-input-sm"
        type="checkbox"
        [(ngModel)]="content.whiteConnected"
      />
      <b>White connected</b>
    </div>
  </div>

  <div *ngIf="type === 'listOfScenes'" class="modal-content">
    <div>
      <strong>Name: </strong>
      <input [(ngModel)]="name" type="text" />
      <div class="flex-row margin-top5">
        <strong>Apartment Id: </strong>
        <select class="select margin-left5" [(ngModel)]="content.apartmentId">
          <option [ngValue]="null">null</option>
          <option
            [ngValue]="apartment.id"
            *ngFor="let apartment of project?.listOfApartments"
          >
            {{ apartment.id }}
          </option>
        </select>
      </div>
      <input
        class="option-input-sm"
        type="checkbox"
        [(ngModel)]="content.isDimmable"
      />
      <b>Is dimmable</b>
      <br />
      <input
        class="option-input-sm"
        type="checkbox"
        [(ngModel)]="content.isTwoState"
      />
      <b>Is two state</b>
      <br />
      <div class="margin-top10 margin-bottom10 center">
        <h3>Elements</h3>
        <hr />
      </div>
      <div *ngFor="let element of content.elements">
        <b>Id</b>: {{ element.id }}
        <button class="show-more" (click)="toggleShowElement(element)">
          Show more
        </button>
        <div *ngIf="showThisProp(element.id)">
          <div><b>Element type:</b> {{ element.elementType }}</div>
          <span
            #ignored1
            [(cpToggle)]="picker1Opened"
            [cpAlphaChannel]="'disabled'"
            [colorPicker]="selectedColorRgb1"
            [cpIgnoredElements]="[ignored1]"
            (colorPickerChange)="updatePicker1(element, $event)"
            [cpWidth]="'300px'"
            [cpOutputFormat]="'rgba'"
            cpPosition='auto'
            ><div class="flex-row">
              <div>
                <b>Color:</b> RGB({{ element.color.red }},{{
                  element.color.green
                }},{{ element.color.blue }},)
              </div>
              <div
                class="rgb-box"
                (click)="toggleColorPicker1($event)"
                [ngStyle]="{ backgroundColor: selectedColorRgb1 }"
              ></div>
              W:{{ element.color.white }}
            </div></span
          >
          <div class="flex-row flex-center">
            <div class="margin-right10">Color W:</div>
            <ion-range
              #rangeValue
              min="{{ sliderOptions.floor }}"
              max="{{ sliderOptions.ceil }}"
              pin="true"
              [(ngModel)]="element.color.white"
              color="primary"
            >
            </ion-range>
          </div>
          <span
            #ignored2
            [(cpToggle)]="picker2Opened"
            [cpAlphaChannel]="'disabled'"
            [colorPicker]="selectedColorRgb2"
            [cpIgnoredElements]="[ignored2]"
            (colorPickerChange)="updatePicker2(element, $event)"
            [cpWidth]="'300px'"
            [cpOutputFormat]="'rgba'"
            cpPosition='auto'
            ><div class="flex-row">
              <div>
                <b>Color2:</b> RGB({{ element.color2.red }},{{
                  element.color2.green
                }},{{ element.color2.blue }})
              </div>
              <div
                class="rgb-box"
                (click)="toggleColorPicker2($event)"
                [ngStyle]="{ backgroundColor: selectedColorRgb2 }"
              ></div>
              W:{{ element.color2.white }}
            </div></span
          >
          <div class="flex-row flex-center">
            <div class="margin-right10">Color2 W:</div>
            <ion-range
              #rangeValue
              min="{{ sliderOptions.floor }}"
              max="{{ sliderOptions.ceil }}"
              pin="true"
              [(ngModel)]="element.color2.white"
              color="primary"
            >
            </ion-range>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="index !== undefined" class="modal-button-box">
    <!-- <button (click)="decisionClick(false)">Cancel</button> -->
    <button
      (click)="decisionClick(true)"
      [ngClass]="[submitted ? 'button-disabled no-click' : '']"
    >
      Save
    </button>
  </div>
