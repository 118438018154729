import { Pipe, PipeTransform } from '@angular/core';
import { Automation } from 'src/app/core/models/automation/automation.model';

@Pipe({
  name: 'automationAptFilter'
})
export class AutomationAptFilterPipe implements PipeTransform {

  transform(automations: Automation[], apartmentId: string): Automation[] {
    if (!automations) {
      return [];
      }
    if (!apartmentId) {
      return automations;
      }
    return automations.filter((automation: Automation) => {
      return automation.apartmentId === apartmentId;
    } );
  }
}

