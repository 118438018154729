import { Inject, Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { Project } from 'src/app/core/models/project/project.model';
import { RoomsGroup } from 'src/app/core/models/project/groups/rooms-group';
import { ApartmentsGroup } from 'src/app/core/models/project/groups/apartments-group';
import { BuildingsGroup } from 'src/app/core/models/project/groups/buildings-group';
import { GeneralEquipmentsGroup } from 'src/app/core/models/project/groups/general-equipments-group';
import { BlindsGroup } from 'src/app/core/models/project/groups/blinds-group';
import { HvacsGroup } from 'src/app/core/models/project/groups/hvacs-group';
import { LightsGroup } from 'src/app/core/models/project/groups/lights-group';
import { User } from 'src/app/core/models/user/user.model';
import { UserService } from 'src/app/core/services/user.service';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { Property } from 'src/app/core/models/project/property.model';
import { Light } from 'src/app/core/models/project/devices/light.model';
import { Blind } from 'src/app/core/models/project/devices/blind.model';
import { Hvac } from 'src/app/core/models/project/devices/hvac.model';
import { Room } from 'src/app/core/models/project/room.model';
import { Apartment } from 'src/app/core/models/project/apartment.model';
import { Building } from 'src/app/core/models/project/building.model';
import { Floor } from 'src/app/core/models/project/floor.model';
import { ApartmentService } from 'src/app/shared/services/apartment.service';
import { take } from 'rxjs/internal/operators/take';
import { DaliProjectService } from './dali-project.service';

@Injectable({
    providedIn: 'root'
})
export class ProjectService {

    private project: Project;
    projectChanged = new Subject<Project>();
    componentChangeCheck = new Subject<{found: Property}>();
    mutexComponentPrimaryPropChange = new Subject<boolean>();

    buildingId = '0';
    aptId: string;

    constructor(
      private apartmentService: ApartmentService,
      private daliProjectService: DaliProjectService,
      private injector: Injector) { }

    setBuildingId(id: string) {
        this.buildingId = id;
    }

    setProject(project: Project) {
        this.project = project;
        this.projectChanged.next(this.project);
    }


    getProject() {
      const userService = this.injector.get(UserService)
      const signedInUser = userService.getUser();
      this.apartmentService.getApartmentId().pipe(take(1)).subscribe(apartmentId => {
        this.aptId = apartmentId;
      });
      if ((signedInUser?.roleLevel === 2 || signedInUser?.roleLevel === 3) && this.project) {
        this.filterProject(signedInUser);
      }
      return this.project;
      }


  filterProject(user: User) {
    this.project.listOfApartments = this.project.listOfApartments.filter((apt: Apartment) => {
      return user.apartmentIds.includes(apt.id);
    });

    this.project.listOfBuildings = this.project.listOfBuildings.filter((building: Building) => {
      return this.project.listOfApartments.some((apt: Apartment) => {
        return building.id === apt.buildingId;
      });
    });

    this.project.listOfRooms = this.project.listOfRooms.filter((room: Room) => {
      return user.apartmentIds.includes(room.apartmentId);
    });

    this.project.listOfFloors = this.project.listOfFloors.filter(( floor: Floor) => {
      return floor.apartmentId === this.aptId;
    });

    this.project.listOfBlinds = this.project.listOfBlinds.filter((blind: Blind) => {
      return blind.listOfLocationIds.some((locId) => {
        const blindsAptId = `${locId.split('.')[0]}.${locId.split('.')[1]}`;
        return user.apartmentIds.includes(blindsAptId);
      });
    });

    this.project.listOfGeneralEquipment = this.project.listOfGeneralEquipment.filter((genEquip: Device) => {
      return genEquip.listOfLocationIds.some((locId) => {
        const genEquipAptId = `${locId.split('.')[0]}.${locId.split('.')[1]}`;
        return user.apartmentIds.includes(genEquipAptId);
      });
    });

    this.project.listOfHvacs = this.project.listOfHvacs.filter((hvac: Hvac) => {
      return hvac.listOfLocationIds.some((locId) => {
        const hvacAptId = `${locId.split('.')[0]}.${locId.split('.')[1]}`;
        return user.apartmentIds.includes(hvacAptId);
      });
    });

    this.project.listOfLights = this.project.listOfLights.filter((light: Light) => {
      return light.listOfLocationIds.some((locId) => {
        const lightAptId = `${locId.split('.')[0]}.${locId.split('.')[1]}`;
        return user.apartmentIds.includes(lightAptId);
      });
    });

    this.project.listOfLights = this.project.listOfLights.filter((light: Light) => {
      return light.listOfLocationIds.some((locId) => {
        const lightAptId = `${locId.split('.')[0]}.${locId.split('.')[1]}`;
        return user.apartmentIds.includes(lightAptId);
      });
    });

    this.project.listOfSafetyEquipment = this.project.listOfSafetyEquipment.filter((safetyEquip: Device) => {
      return safetyEquip.listOfLocationIds.some((locId) => {
        const safetyEquipAptId = `${locId.split('.')[0]}.${locId.split('.')[1]}`;
        return user.apartmentIds.includes(safetyEquipAptId);
      });
    });

    this.project.listOfSecurityEquipment = this.project.listOfSecurityEquipment.filter((securityEquip: Device) => {
      return securityEquip.listOfLocationIds.some((locId) => {
        const securityEquipAptId = `${locId.split('.')[0]}.${locId.split('.')[1]}`;
        return user.apartmentIds.includes(securityEquipAptId);
      });
    });

  }

    updateProjectByMqtt(arrayName: string, found: Property, id: string) {
        this.project[arrayName].some( element => {
            if ( element.id === id) {
                const index = element.equipmentProperties.findIndex( prop => prop.id === found.id);
                element.equipmentProperties[index] = found;
                return true;
            }
        });
        this.projectChanged.next(this.project);
    }

    updateGeneratedComponentByMqtt(found: Property) {
      this.componentChangeCheck.next({found});
    }


    setRoomsGroup(group: RoomsGroup) {
        this.project.listOfRoomGroups.push(group);
        this.projectChanged.next(this.project);
    }

    deleteRoomsGroup(id: string) {
        this.project.listOfRoomGroups = this.project.listOfRoomGroups.filter(element => element.id !== id);
        this.projectChanged.next(this.project);
    }

    setApartmentsGroup(group: ApartmentsGroup) {
        this.project.listOfApartmentGroups.push(group);
        this.projectChanged.next(this.project);
    }

    deleteApartmentsGroup(id: string) {
        this.project.listOfApartmentGroups = this.project.listOfApartmentGroups.filter(element => element.id !== id);
        this.projectChanged.next(this.project);
    }

    setBuildingsGroup(group: BuildingsGroup) {
        this.project.listOfBuildingGroups.push(group);
        this.projectChanged.next(this.project);
    }

    deleteBuildingsGroup(id: string) {
        this.project.listOfBuildingGroups = this.project.listOfBuildingGroups.filter(element => element.id !== id);
        this.projectChanged.next(this.project);
    }

    setBlindsGroup(group: BlindsGroup) {
        this.project.listOfBlindsGroups.push(group);
        this.projectChanged.next(this.project);
    }

    deleteBlindsGroup(id: string) {
        this.project.listOfBlindsGroups = this.project.listOfBlindsGroups.filter(element => element.id !== id);
        this.projectChanged.next(this.project);
    }

    setGeneralEquipmentsGroup(group: GeneralEquipmentsGroup) {
        this.project.listOfGeneralEquipmentGroup.push(group);
        this.projectChanged.next(this.project);
    }

    deleteGeneralEquipmentsGroup(id: string) {
        this.project.listOfGeneralEquipmentGroup = this.project.listOfGeneralEquipmentGroup.filter(element => element.id !== id);
        this.projectChanged.next(this.project);
    }

    setHvacsGroup(group: HvacsGroup) {
        this.project.listOfHvacGroups.push(group);
        this.projectChanged.next(this.project);
    }

    deleteHvacsGroup(id: string) {
        this.project.listOfHvacGroups = this.project.listOfHvacGroups.filter(element => element.id !== id);
        this.projectChanged.next(this.project);
    }

    setLightsGroup(group: LightsGroup) {
        this.project.listOfLightGroups.push(group);
        this.projectChanged.next(this.project);
    }

    deleteLightsGroup(id: string) {
        this.project.listOfLightGroups = this.project.listOfLightGroups.filter(element => element.id !== id);
        this.projectChanged.next(this.project);
    }


    findDeviceById(deviceId: string) {
      const {listOfHvacs, listOfGeneralEquipment, listOfBlinds,
        listOfLights, listOfSafetyEquipment, listOfSecurityEquipment} = this.project;

      let foundDevice: Device;
      foundDevice = listOfBlinds.find(device => device.id.toLowerCase() === deviceId.toLowerCase());
      if (foundDevice) {
        return foundDevice;
      }
      foundDevice = listOfGeneralEquipment.find(device => device.id.toLowerCase() === deviceId.toLowerCase());
      if (foundDevice) {
        return foundDevice;
      }
      foundDevice = listOfHvacs.find(device => device.id.toLowerCase() === deviceId.toLowerCase());
      if (foundDevice) {
        return foundDevice;
      }
      foundDevice = listOfLights.find(device => device.id.toLowerCase() === deviceId.toLowerCase());
      if (foundDevice) {
        return foundDevice;
      }
      foundDevice = listOfSafetyEquipment.find(device => device.id.toLowerCase() === deviceId.toLowerCase());
      if (foundDevice) {
        return foundDevice;
      }
      foundDevice = listOfSecurityEquipment.find(device => device.id.toLowerCase() === deviceId.toLowerCase());
      if (foundDevice) {
        return foundDevice;
      }
    }



    getLightsForRoom(roomId: string): Light[] {
      const lights: Light[] = this.findDevicesInRoom(this.project.listOfLights, roomId);
      return lights;
    }

    getBlindsForRoom(roomId: string): Blind[] {
      const blinds: Blind[] = this.findDevicesInRoom(this.project.listOfBlinds, roomId);
      return blinds;
    }

    getHvacForRoom(roomId: string): Hvac[] {
      const hvacs: Hvac[] = this.findDevicesInRoom(this.project.listOfHvacs, roomId);
      return hvacs;
    }

    getSecurityEquipmentForRoom(roomId: string): Device[] {
      const securityEquipment: Device[] = this.findDevicesInRoom(this.project.listOfSecurityEquipment, roomId);
      return securityEquipment;
    }

    getSafetyEquipmentForRoom(roomId: string): Device[] {
      const safetyEquipment: Device[] = this.findDevicesInRoom(this.project.listOfSafetyEquipment, roomId);
      return safetyEquipment;
    }

    getGeneralEquipmentForRoom(roomId: string): Device[] {
      const generalEquipment: Device[] = this.findDevicesInRoom(this.project.listOfGeneralEquipment, roomId);
      return generalEquipment;
    }

    findDevicesInRoom(listOfDevices: any[], roomId: string) {
      const devicesInRoom = listOfDevices.filter(device => {
        return  (device.listOfLocationIds.some((locationId: string) => {
          return this.roomIsInLocation(roomId, locationId);
        }));
      });
      return devicesInRoom;
    }

    roomIsInLocation(roomId: string, locationId: string) {
      const roomBuildingId = roomId?.split('.')[0];
      const roomAptId = roomId?.split('.')[1];
      const roomRoomId = roomId?.split('.')[2];
      const locationBuildingId = locationId?.split('.')[0];
      const locationAptId = locationId?.split('.')[1];
      const locationRoomId = locationId?.split('.')[2];
      return (roomBuildingId === locationBuildingId) && (roomAptId === locationAptId) && (roomRoomId === locationRoomId);
      // return locationId.includes(roomId);
    }


    getIconForDevice(deviceId: string): string {
      const {listOfHvacs, listOfGeneralEquipment, listOfBlinds,
         listOfLights, listOfSafetyEquipment, listOfSecurityEquipment} = this.project;

      if (this.deviceIsInList(listOfHvacs, deviceId)) {
        return 'i-hvac';
      } else if (this.deviceIsInList(listOfGeneralEquipment, deviceId)) {
        return 'i-general-equipment';
      } else if (this.deviceIsInList(listOfBlinds, deviceId)) {
        return 'i-blinds';
      } else if (this.deviceIsInList(listOfLights, deviceId)) {
        return 'i-lightbulb-black';
      } else if (this.deviceIsInList(listOfSafetyEquipment, deviceId)) {
        return 'i-safety-equipment-bl';
      } else if (this.deviceIsInList(listOfSecurityEquipment, deviceId)) {
        return 'i-security-equipment-bl';
      } else if ( this.daliProjectService.getDaliLight(deviceId)) {
        return 'i-lightbulb-black';
      } else if ( this.daliProjectService.getDaliRgbLight(deviceId)) {
        return 'i-light-rgbw-automation';
      } else if ( this.daliProjectService.getDaliLightsGroup(deviceId)) {
        return 'i-lightgroup-black';
      } else if ( this.daliProjectService.getDaliBlinds(deviceId)) {
        return 'i-blinds';
      } else {
        return '';
      }
    }

    getRoom(roomId: string) {
      return this.project?.listOfRooms?.find((room: Room) => {
        return room.id === roomId;
      });
    }

    getFloor(floorId: string): Floor {
      return this.project?.listOfFloors?.find((floor: Floor) => {
        return floor.id === floorId;
      });
    }

    private deviceIsInList(listOfDevices: Device[], deviceId: string): boolean {
      return listOfDevices.some((device: Device) => {
        return device.id.toLowerCase() === deviceId.toLowerCase(); });
    }

    changePropertyValueForDemo(deviceID: string, propertyType: number, value: number) {
      const device = this.findDeviceById(deviceID);
      const prop = device.equipmentProperties.find( element => {
        if (element.type === propertyType) {
          return true;
        }
      });
      prop.value = value;
  }


}
