import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { GenComTranslatePipe } from 'src/app/shared/pipes/gen-com-translate.pipe';


@Injectable({
    providedIn: 'root'
})
export class ToastService {


    constructor(private toastController: ToastController,
                private pipe: GenComTranslatePipe) { }


    ngOnInit() {}

    async showSuccessfulRequestMsg(value: any) {
            if (+value !== 0 || value === true) {
                const toast = await this.toastController.create({
                    message: this.pipe.transform('Device turned on'),
                    duration: 2000,
                });
                toast.present();
            } 
            if (+value === 0 || value === false) {
                const toast = await this.toastController.create({
                    message: this.pipe.transform('Device turned off'),
                    duration: 2000,
                });
                toast.present();
            }  
    }

    async showSceneTurnedOn() {
                const toast = await this.toastController.create({
                    message: this.pipe.transform('Scene') + ' ' + this.pipe.transform('turned on'),
                    color: 'primary',
                    duration: 1500,
                });
                toast.present();
    }

    async showSceneTurnedOff() {
            const toast = await this.toastController.create({
                message: this.pipe.transform('Scene') + ' ' + this.pipe.transform('turned off'),
                color: 'primary',
                duration: 1500,
            });
            toast.present();
        }
}
