<div class="flex-row">
  <div *ngIf="(networkStatus | async) === 'none'; else connectionType">
    <a class="i-no-connection-sm-bl"></a>
  </div>
  <ng-template #connectionType>
    <div *ngIf="usingNetworkSwitcher$ | async">
      <a
        [ngClass]="{
          'i-local-sm-bl': (networkType$ | async) === 'local',
          'i-internet-sm-bl': (networkType$ | async) === 'public',
          'i-wifi-sm-bl icon-blink': (networkType$ | async) === 'switching'
        }"
      ></a>
    </div>
  </ng-template>

  <div *ngIf="apartmentArmed"><a class="i-armed-sm-bl margin-right5"></a></div>
  <div *ngIf="heatingActive"><a class="i-heating-sm-white"></a></div>
  <div *ngIf="coolingActive"><a class="i-cooling-sm-white"></a></div>
</div>
