import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/app/core/models/user/user.model';

@Pipe({
    name: 'userfilter'
})
export class UsersFilterPipe implements PipeTransform {
    transform(users: User[], searchText: string): any[] {
        if (!users) {
            return [];
        }
        if (!searchText) {
            return users;
        }
        searchText = searchText.toLowerCase();
        return users.filter(user => {
            const searchPattern = user.firstName?.toLowerCase() + ' ' + user.lastName?.toLowerCase();
            return searchPattern.includes(searchText);
        });
    }
}
