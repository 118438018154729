<div>
  <!-- <div class="sidebar-header clickable" [routerLink]="['/home']" (click)="onClickLink()">
    <p class="sidebar-header-title">Abas</p>
  </div> -->
  <div class="sidebar-links-container">
    <a  [ngClass]="[true ? 'sidebar-link' : 'sidebar-link-disabled']"
    class="flex-row" [routerLink]="['/home']" routerLinkActive="sidebar-active-link" [routerLinkActiveOptions]="{exact:true}" (click)="onClickLink()">
    <i [ngClass]="[true ? 'i-sidebar-logo' : 'i-user-gr']"></i>
    <p>ABAS Home</p>
  </a>
    <a *ngIf="!demo" [ngClass]="[true ? 'sidebar-link' : 'sidebar-link-disabled']"
    class="flex-row" routerLink="/users/{{user._id}}" routerLinkActive="sidebar-active-link" [routerLinkActiveOptions]="{exact:true}" (click)="onClickLink()">
    <i [ngClass]="[true ? 'i-user-wh shadow' : 'i-user-gr']"></i>
    <p>{{ 'view_profile' | translate }}</p>
  </a>

    <a *ngIf="userIsAdmin(user) || userIsOwner(user) && !demo"
      [ngClass]="[true ? 'sidebar-link' : 'sidebar-link-disabled']"
      class="flex-row"
      routerLink="/users"
      routerLinkActive="sidebar-active-link"
      [routerLinkActiveOptions]="{exact:true}"
      (click)="onClickLink()">
      <i [ngClass]="[true ? 'i-users-sm-w shadow' : 'i-users-sm-gr']"></i>
      <p>{{ 'users' | translate }}</p>
    </a>
    <a [ngClass]="[true ? 'sidebar-link' : 'sidebar-link-disabled']"
    class="flex-row" routerLink="/rooms" routerLinkActive="sidebar-active-link" (click)="onRouteToRooms()">
    <i [ngClass]="[true ? 'i-rooms-sm-w shadow' : 'i-rooms-sm-gr']"></i>
    <p>{{ 'rooms_live' | translate }}</p>
  </a>

  <a  *ngIf="(userIsAdmin(user) || userIsOwner(user)) && !demo && project?.listOfAccessControls?.length > 0"
    [ngClass]="[true ? 'sidebar-link' : 'sidebar-link-disabled']"
    class="flex-row"
    routerLink="/doors"
    routerLinkActive="sidebar-active-link"
    (click)="onClickLink()">
    <i [ngClass]="[true ? 'i-access-sm-blue shadow' : 'i-access-sm-blue']"></i>
    <p>{{ 'doors' | translate }}</p>
  </a>

  <a [ngClass]="[true ? 'sidebar-link' : 'sidebar-link-disabled']"
  class="flex-row" routerLink="/automation" routerLinkActive="sidebar-active-link" (click)="onClickLink()">
  <i [ngClass]="[true ? 'i-automation-sm-blue shadow' : 'i-automation-sm-gr']"></i>
  <p>{{ 'Automations' | translate }}</p>
</a>

    <a *ngIf="userIsAdmin(user) && !demo"
      [ngClass]="[true ? 'sidebar-link' : 'sidebar-link-disabled']"
      class="flex-row"
      routerLink="/project"
      routerLinkActive="sidebar-active-link"
      (click)="onClickLink()">
      <i [ngClass]="[true ? 'i-project-sm-w shadow' : 'i-project-sm-gr']"></i>
      <p>{{ 'project' | translate }}</p>
    </a>
    <a [ngClass]="[true ? 'sidebar-link' : 'sidebar-link-disabled']"
      class="flex-row" routerLink="/scenes" routerLinkActive="sidebar-active-link"
      (click)="onClickLink()">
      <i
        [ngClass]="[true ? 'i-scenes-sm-blue shadow' : 'i-scenes-sm-gr']"></i>
      <p>{{ 'scenes' | translate }}</p>
    </a>

    <a [ngClass]="[true ? 'sidebar-link' : 'sidebar-link-disabled']"
    class="flex-row" routerLink="/modes" routerLinkActive="sidebar-active-link"
    (click)="onClickLink()">
    <i
      [ngClass]="[true ? 'i-modes-sm-blue shadow' : 'i-modes-sm-gr']"></i>
    <p>{{ 'modes' | translate }}</p>
  </a>
  <a *ngIf="(userIsAdmin(user) || userIsOwner(user) || demo)"
    [ngClass]="[true ? 'sidebar-link' : 'sidebar-link-disabled']"
    class="flex-row" routerLink="/analytics/temperatures" routerLinkActive="sidebar-active-link"
    (click)="onClickLink()">
    <i
      [ngClass]="[true ? 'i-analytics-sm-w shadow' : 'i-modes-sm-gr']"></i>
    <p>{{ 'analytics' | translate }}</p>
  </a>
    <a *ngIf="userIsAdmin(user) && !demo"
      [ngClass]="[true ? 'sidebar-link' : 'sidebar-link-disabled']"
      class="flex-row"
      routerLink="/logs/audit"
      routerLinkActive="sidebar-active-link"
      (click)="onClickLink()">
      <i
        [ngClass]="[true ? 'i-audit-log-sm-w shadow' : 'i-audit-log-sm-gr']"></i>
      <p>{{ 'audit_logs' | translate }}</p>
    </a>
    <a *ngIf="userIsAdmin(user) && !demo"
      [ngClass]="[true ? 'sidebar-link' : 'sidebar-link-disabled']"
      class="flex-row"
      routerLink="/logs/alarms"
      routerLinkActive="sidebar-active-link"
      (click)="onClickLink()">
      <i
        [ngClass]="[true ? 'i-alarm-log-sm-w shadow' : 'i-alarm-log-sm-gr']"></i>
      <p>{{ 'alarms_log' | translate }}</p>
    </a>
    <a *ngIf="(userIsAdmin(user) || userIsOwner(user)) && !demo && project?.listOfAccessControls?.length > 0"
    [ngClass]="[true ? 'sidebar-link' : 'sidebar-link-disabled']"
    class="flex-row"
    routerLink="/logs/access"
    routerLinkActive="sidebar-active-link"
    (click)="onClickLink()">
    <i
      [ngClass]="[true ? 'i-access-log-sm-wh shadow' : 'i-alarm-log-sm-gr']"></i>
    <p>{{ "access_logs" | translate }}</p>
  </a>
    <a *ngIf="!demo"
      [ngClass]="[true ? 'sidebar-link' : 'sidebar-link-disabled']"
      class="flex-row"
      routerLink="/settings"
      routerLinkActive="sidebar-active-link"
      (click)="onClickLink()">
      <i
        [ngClass]="[true ? 'i-settings-sm-blue shadow' : 'i-settings-sm-gr']"></i>
      <p>{{ 'settings' | translate }}</p>
    </a>

    <a [ngClass]="[true ? 'sidebar-link' : 'sidebar-link-disabled']"
    class="flex-row" (click)="logoutPrompt()">
    <i class="i-logout-wh shadow"></i>
    <p *ngIf="!demo">{{ 'logout' | translate }}</p>
    <p *ngIf="demo">Exit demo mode</p>
  </a>
  </div>
</div>
